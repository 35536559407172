import { BottomSheetSize } from 'livemap-ui';
import { delay, put, select, takeLatest } from 'redux-saga/effects';

import { isMobile } from '@core/device';

import { callMapbox } from '@core/map/bindings';

import { AppState } from '../app-state';
import { SnackbarNotice } from '../ui-state';

import {
  PushSnackbarMessageAction,
  removeSnackbarNotice,
  SetBottomSheetSizeAction,
  setCameraOffset,
  UIActionType
} from '../actions';

function* handleSetBottomSheetSize(
  action: SetBottomSheetSizeAction
): Generator {
  const { height, size } = action.payload;

  const isMobileDevice = yield select((s: AppState) => isMobile(s.ui.device));

  if (isMobileDevice) {
    if (size === BottomSheetSize.CLOSED) {
      yield put(setCameraOffset(0, 0));
    } else {
      yield put(setCameraOffset(0, -(height - 100)));
    }
  }
}

function* handleReloadApp() {
  window.location.reload();
}

function* handlePushSnackbarMessage({ payload }: PushSnackbarMessageAction) {
  yield delay(payload.duration);

  const snackbar: SnackbarNotice[] = yield select(
    (s: AppState) => s.ui.snackbar
  );
  const notice = snackbar.find((n) => n.id === payload.id);

  if (notice) {
    yield put(removeSnackbarNotice(notice.id));
  }
}

function* handleSetFullscreen() {
  callMapbox((mb) => mb.resize());
}

export function* uiSaga() {
  yield takeLatest(
    UIActionType.SET_BOTTOM_SHEET_SIZE,
    handleSetBottomSheetSize
  );
  yield takeLatest(UIActionType.RELOAD_APP, handleReloadApp);
  yield takeLatest(
    UIActionType.PUSH_SNACKBAR_NOTICE,
    handlePushSnackbarMessage
  );
  yield takeLatest(UIActionType.SET_IS_FULLSCREEN, handleSetFullscreen);
}
