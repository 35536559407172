import React from 'react';

import styled from 'livemap-ui';

import { ComponentsConfig } from '@core/app-config';

import { MapControls } from '../MapControls/MapControls';
import MenuButton from './MenuButton/MenuButtonContainer';
import Omnibox from './Omnibox/OmniboxContainer';
import RouteFilters from './RouteFilters/RouteFiltersContainer';

export interface TopControlsProps {
  config: ComponentsConfig;
  hasDesktopLayout: boolean;
  isAppFullscreen: boolean;
  isVehicleStatsVisible: boolean;
}

export function TopControls({
  config,
  hasDesktopLayout,
  isAppFullscreen,
  isVehicleStatsVisible
}: TopControlsProps) {
  const Menu = config.search.enabled ? Omnibox : MenuButton;

  const menuWrapperStyle = hasDesktopLayout ? undefined : { width: '100%' };

  return (
    <Wrapper>
      {config.menu.enabled ? (
        <MenuWrapper style={menuWrapperStyle}>
          <Menu />
          <RouteFilters />
        </MenuWrapper>
      ) : null}

      {!isVehicleStatsVisible ? (
        <MapControlsWrapper>
          <MapControls
            config={config}
            showFullscreenButton={!isAppFullscreen}
            showZoomButtons={hasDesktopLayout}
          />
        </MapControlsWrapper>
      ) : null}
    </Wrapper>
  );
}

const Wrapper = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 1 1 100%;
  width: 100%;
  z-index: 1;
  /** Click through wrapper */
  pointer-events: none;

  * {
    /** Allow click on children */
    pointer-events: auto;
  }
`;

const MenuWrapper = styled.div`
  position: relative;
  width: auto;
  display: flex;
  flex-direction: column;
  width: 360px;
  max-width: 100%;
  margin: 12px 12px 0 12px;
  pointer-events: none;

  * {
    pointer-events: auto;
  }
`;

const MapControlsWrapper = styled.div`
  margin: 12px;
  margin-left: auto;
  z-index: 1;
`;
