import { BottomSheetSize } from 'livemap-ui';

import { DeviceType, getDeviceType } from '@core/device';

import { AnyAppAction } from '@state/actions';

export type MenuType = 'main';

export interface BottomSheetState {
  /** The current height of the bottom sheet. */
  height: number;
  /** The current size of the bottom sheet, e.g. 'EXPANDED' */
  size: BottomSheetSize;
}

export interface SnackbarNotice {
  /**
   * An arbitrary string to identify this notice,
   * so it can (for instance) be removed.
   */
  id: string;
  /** The text which is displayed on the notice */
  text: string;
  /** For how many milliseconds this notice should be displayed. */
  duration: number;
  /** The text to display as an action on the notice. E.g: 'OK' or 'Dismiss' */
  actionText?: string;
  /** What action to perform when pressing the action text. */
  action?: AnyAppAction;
}

export interface UIState {
  /** Which menu is currently open. `null`, if no menu is open. */
  currentMenu: MenuType | null;
  /** The current height of the bottom sheet */
  bottomSheet: BottomSheetState;
  /** The messages currently in the snackbar */
  snackbar: SnackbarNotice[];
  /** Describes the type of device which is running the app. */
  device: DeviceType;
  /**
   * Determines whether or not to use a desktop layout for components.
   * This is generally true for tablets or desktop devices.
   */
  hasDesktopLayout: boolean;
  /** Whether or not the app occupies the entire inner window area. */
  isStandalone: boolean;
  /** When the app is not in stand-alone mode, the app may be put into full screen */
  isFullscreen: boolean;
  /** Whether or not the vehicle stats panel is visible */
  isVehicleStatsVisible: boolean;

  isNoticeVisible: boolean;
}

export const initialState: UIState = {
  bottomSheet: { size: BottomSheetSize.CLOSED, height: 0 },
  currentMenu: null,
  hasDesktopLayout: false,
  device: getDeviceType(),
  isStandalone: false,
  isFullscreen: false,
  isVehicleStatsVisible: false,
  snackbar: [],
  isNoticeVisible: false
};
