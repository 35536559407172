import { connect } from 'react-redux';

import { toggleRouteLayerStyle } from '@state/actions';
import { AppState } from '@state/app-state';

import {
  LayerSelector,
  LayerSelectorDispatch,
  LayerSelectorProps
} from './LayerSelector';

const mapState = (state: AppState): LayerSelectorProps => ({
  layerStyle: state.map.routeLayerStyle
});

const mapDispatch: LayerSelectorDispatch = {
  onClick: toggleRouteLayerStyle
};

/** Container component for Sidebar */
export default connect(
  mapState,
  mapDispatch
)(LayerSelector);
