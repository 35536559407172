import { AnyAppAction } from '../actions/app-actions';
import { UIActionType } from '../actions/ui-actions';
import { initialState, SnackbarNotice, UIState } from '../ui-state';

function pushUniqueSnackbarNotice(
  snackbar: SnackbarNotice[],
  notice: SnackbarNotice
): SnackbarNotice[] {
  const currentNotice = snackbar.find(n => n.id === notice.id);

  if (!currentNotice) {
    return [...snackbar, notice];
  }

  return snackbar;
}

export function uiReducer(
  state: UIState = initialState,
  action: AnyAppAction
): UIState {
  switch (action.type) {
    case UIActionType.SET_IS_STANDALONE:
      return { ...state, isStandalone: action.payload };
    case UIActionType.SET_IS_FULLSCREEN:
      return { ...state, isFullscreen: action.payload };
    case UIActionType.SET_BOTTOM_SHEET_SIZE:
      return { ...state, bottomSheet: action.payload };
    case UIActionType.CLOSE_MENU:
      return { ...state, currentMenu: null };
    case UIActionType.OPEN_MENU:
      return { ...state, currentMenu: action.payload };
    case UIActionType.SET_DEVICE:
      return { ...state, device: action.payload };
    case UIActionType.SET_HAS_DESKTOP_LAYOUT:
      return { ...state, hasDesktopLayout: action.payload };
    case UIActionType.SHOW_VEHICLE_STATS:
      return { ...state, isVehicleStatsVisible: true };
    case UIActionType.HIDE_VEHICLE_STATS:
      return { ...state, isVehicleStatsVisible: false };
    case UIActionType.TOGGLE_VEHICLE_STATS:
      return { ...state, isVehicleStatsVisible: !state.isVehicleStatsVisible };
    case UIActionType.PUSH_SNACKBAR_NOTICE:
      return {
        ...state,
        snackbar: pushUniqueSnackbarNotice(state.snackbar, action.payload)
      };
    case UIActionType.REMOVE_SNACKBAR_NOTICE:
      return {
        ...state,
        snackbar: state.snackbar.filter(m => m.id !== action.payload)
      };
    case UIActionType.OPEN_NOTICE:
      return { ...state, isNoticeVisible: true };
    case UIActionType.CLOSE_NOTICE:
      return { ...state, isNoticeVisible: false };
    default:
      return state;
  }
}
