import { BottomSheetSize } from 'livemap-ui';

import { DeviceType } from '@core/device';

import { BottomSheetState, MenuType, SnackbarNotice } from '../ui-state';
import {
  createEmpty,
  createPayloaded,
  EmptyAction,
  PayloadedAction
} from './app-actions';

export const enum UIActionType {
  SET_IS_STANDALONE = 'UI/SET_IS_STANDALONE',
  SET_IS_FULLSCREEN = 'UI/SET_IS_FULLSCREEN',
  SET_BOTTOM_SHEET_SIZE = 'UI/SET_BOTTOM_SHEET_SIZE',
  OPEN_MENU = 'UI/OPEN_MENU',
  CLOSE_MENU = 'UI/CLOSE_MENU',
  PUSH_SNACKBAR_NOTICE = 'UI/PUSH_SNACKBAR_NOTICE',
  REMOVE_SNACKBAR_NOTICE = 'UI/REMOVE_SNACKBAR_NOTICE',
  SET_DEVICE = 'UI/SET_DEVICE',
  RELOAD_APP = 'UI/RELOAD_APP',
  SHOW_VEHICLE_STATS = 'UI/SHOW_VEHICLE_STATS',
  HIDE_VEHICLE_STATS = 'UI/HIDE_VEHICLE_STATS',
  TOGGLE_VEHICLE_STATS = 'UI/TOGGLE_VEHICLE_STATS',
  OPEN_NOTICE = 'UI/OPEN_NOTICE',
  CLOSE_NOTICE = 'UI/CLOSE_NOTICE',
  SET_HAS_DESKTOP_LAYOUT = 'UI/SET_HAS_DESKTOP_LAYOUT'
}

export type SetBottomSheetSizeAction = PayloadedAction<
  UIActionType.SET_BOTTOM_SHEET_SIZE,
  BottomSheetState
>;

export type OpenMenuAction = PayloadedAction<UIActionType.OPEN_MENU, MenuType>;

export type CloseMenuAction = EmptyAction<UIActionType.CLOSE_MENU>;

export type ReloadAppAction = EmptyAction<UIActionType.RELOAD_APP>;

export type RemoveSnackbarNoticeAction = PayloadedAction<
  UIActionType.REMOVE_SNACKBAR_NOTICE,
  string
>;

export type PushSnackbarMessageAction = PayloadedAction<
  UIActionType.PUSH_SNACKBAR_NOTICE,
  SnackbarNotice
>;

export type SetDeviceTypeAction = PayloadedAction<
  UIActionType.SET_DEVICE,
  DeviceType
>;

export type SetIsStandaloneAction = PayloadedAction<
  UIActionType.SET_IS_STANDALONE,
  boolean
>;

export type SetIsFullscreenAction = PayloadedAction<
  UIActionType.SET_IS_FULLSCREEN,
  boolean
>;

export type ShowVehicleStatsActon = EmptyAction<
  UIActionType.SHOW_VEHICLE_STATS
>;

export type HideVehicleStatsAction = EmptyAction<
  UIActionType.HIDE_VEHICLE_STATS
>;

export type ToggleVehicleStatsAction = EmptyAction<
  UIActionType.TOGGLE_VEHICLE_STATS
>;

export type OpenNoticeAction = EmptyAction<UIActionType.OPEN_NOTICE>;

export type CloseNoticeAction = EmptyAction<UIActionType.CLOSE_NOTICE>;

export type SetHasDesktopLayoutAction = PayloadedAction<
  UIActionType.SET_HAS_DESKTOP_LAYOUT,
  boolean
>;

export type AnyUIAction =
  | SetBottomSheetSizeAction
  | OpenMenuAction
  | CloseMenuAction
  | SetDeviceTypeAction
  | PushSnackbarMessageAction
  | RemoveSnackbarNoticeAction
  | ReloadAppAction
  | SetIsStandaloneAction
  | SetIsFullscreenAction
  | ShowVehicleStatsActon
  | HideVehicleStatsAction
  | ToggleVehicleStatsAction
  | OpenNoticeAction
  | CloseNoticeAction
  | SetHasDesktopLayoutAction;

/**
 * Make the height of the bottom sheet known to the rest of the app.
 * @number The current height of the bottom sheet.
 * @number The current size of the bottom sheet.
 */
export const setBottomSheetSize = (
  height: number,
  size: BottomSheetSize
): SetBottomSheetSizeAction =>
  createPayloaded(UIActionType.SET_BOTTOM_SHEET_SIZE, { height, size });

/** Open a side menu of the given type. */
export const openMenu = (menuType: MenuType): OpenMenuAction =>
  createPayloaded(UIActionType.OPEN_MENU, menuType);

/** Close any open side menu */
export const closeMenu = (): CloseMenuAction =>
  createEmpty(UIActionType.CLOSE_MENU);

/**
 * Set information about which device is running the app.
 * @param device A known device type.
 */
export const setDeviceType = (device: DeviceType): SetDeviceTypeAction =>
  createPayloaded(UIActionType.SET_DEVICE, device);

/**
 * Push a new notice to the snackbar
 * @param notice The information to push to the snackbar
 */
export const pushSnackbarNotice = (
  notice: SnackbarNotice
): PushSnackbarMessageAction =>
  createPayloaded(UIActionType.PUSH_SNACKBAR_NOTICE, notice);

/**
 * Remove a snackbar message with a given ID
 * @param id The identifier of the snackbar notice to remove
 */
export const removeSnackbarNotice = (id: string): RemoveSnackbarNoticeAction =>
  createPayloaded(UIActionType.REMOVE_SNACKBAR_NOTICE, id);

/** Reload the page */
export const reloadApp = (): ReloadAppAction =>
  createEmpty(UIActionType.RELOAD_APP);

/**
 * Set whether the app is mounted in a "stand-alone" fashion.
 * In essence, whether the app occupies the entire page.
 *
 * @param isStandalone Whether or not the app is in stand-alone mode.
 */
export const setIsStandalone = (isStandalone: boolean): SetIsStandaloneAction =>
  createPayloaded(UIActionType.SET_IS_STANDALONE, isStandalone);

/**
 * When the app is not mounted as a stand-alone app,
 * you pay call this action with `isFullscreen: true`
 * to make the root element of the application occupy the entire window width/height..
 *
 * @param isFullscreen Whether or not the app is in fullscreen.
 */
export const setIsFullscreen = (isFullscreen: boolean): SetIsFullscreenAction =>
  createPayloaded(UIActionType.SET_IS_FULLSCREEN, isFullscreen);

export const showVehicleStats = (): ShowVehicleStatsActon =>
  createEmpty(UIActionType.SHOW_VEHICLE_STATS);

export const hideVehicleStats = (): HideVehicleStatsAction =>
  createEmpty(UIActionType.HIDE_VEHICLE_STATS);

export const toggleVehicleStats = (): ToggleVehicleStatsAction =>
  createEmpty(UIActionType.TOGGLE_VEHICLE_STATS);

export const openNotice = (): OpenNoticeAction =>
  createEmpty(UIActionType.OPEN_NOTICE);

export const closeNotice = (): CloseNoticeAction =>
  createEmpty(UIActionType.CLOSE_NOTICE);

export const setHasDesktopLayout = (
  hasDesktopLayout: boolean
): SetHasDesktopLayoutAction =>
  createPayloaded(UIActionType.SET_HAS_DESKTOP_LAYOUT, hasDesktopLayout);
