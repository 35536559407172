import { CameraOptions, LngLat } from 'mapbox-gl';

import { getBearing, getCenter, getPitch, getZoom } from '@core/map/camera';
import { Following } from '@core/map/position';
import { TripIdParams } from '@core/map/traffic';

import { AnyAppAction, flyTo, follow, selectVehicle } from '@state/actions';
import { AppState } from '@state/app-state';

export function createCameraParams(
  center: LngLat = getCenter(),
  zoom: number = getZoom(),
  pitch: number = getPitch(),
  bearing: number = getBearing()
) {
  const positionString = `@${[
    center.lat.toFixed(6),
    center.lng.toFixed(6),
    zoom.toFixed(2)
  ].join(',')}`;
  const pitchString = pitch !== 0 ? 'p' + pitch.toFixed() : null;
  const bearingString = bearing !== 0 ? 'b' + bearing.toFixed(1) : null;

  return [positionString, pitchString, bearingString]
    .filter(r => r !== null)
    .join(',');
}

/**
 * Attempts to parse camera options from a given string
 * ending with camera parameters, e.g `"@13.2382,123.1228,2"`
 */
export function parseCameraParams(
  parameterString: string | null | undefined,
  delimiter: string = '@'
): CameraOptions | null {
  const delimiterIdx = parameterString
    ? parameterString.indexOf(delimiter)
    : -1;

  if (delimiterIdx === -1) {
    return null;
  }

  const parameters = parameterString!.substr(delimiterIdx + 1);
  const [latStr, lngStr, zoomStr, ...rotational] = parameters.split(',');
  const [lat, lng, zoom] = [latStr, lngStr, zoomStr].map(Number);

  if (isNaN(lat) || isNaN(lng) || isNaN(zoom)) {
    return null;
  }

  let pitch = 0;
  let bearing = 0;

  for (const part of rotational) {
    if (part[0] === 'p') {
      pitch = Number(part.substr(1)) || 0;
    } else if (part[0] === 'b') {
      bearing = Number(part.substr(1)) || 0;
    }
  }

  return {
    center: [lng, lat],
    zoom,
    pitch,
    bearing
  };
}

export function createPathFromState({ map }: AppState) {
  let path = '';

  if (map.trip) {
    path += `/trip/${map.trip.id}/${map.trip.start}`;
  } else if (map.selectedStop) {
    path += `/stop`;
  }

  if (map.following === Following.NOTHING) {
    path += '/' + createCameraParams();
  }

  return path;
}

export function getTripIdsFromPath(path: string): TripIdParams | null {
  if (path.slice(1, 5) === 'trip') {
    const tripIdentifiers = path.slice(6, path.length).split('/');

    const id = Number(tripIdentifiers[0]);
    const startsAt = Number(tripIdentifiers[1]);

    if (!isNaN(id) && !isNaN(startsAt)) {
      return { id, start: startsAt };
    }
  }

  return null;
}

export function dispatchFromPath(path: string): AnyAppAction[] {
  const cameraParamIndex = path.indexOf('@');
  const cameraParams =
    cameraParamIndex > -1 ? path.substr(path.indexOf('@')) : '';

  const trip = getTripIdsFromPath(path);
  const toDispatch: AnyAppAction[] = [];

  if (trip) {
    if (!cameraParams) {
      toDispatch.push(follow(Following.SELECTED_VEHICLE));
    }

    toDispatch.push(selectVehicle(trip.id, trip.start));
  } else if (cameraParams) {
    const camera = parseCameraParams(cameraParams);

    if (camera) {
      toDispatch.push(flyTo({ ...camera, duration: 0 }));
    }
  }

  return toDispatch;
}
