import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { getSelectedTheme } from '@core/app-config';
import { Following } from '@core/map/position';

import { follow } from '@state/actions';
import { AppState } from '@state/app-state';

import { TripInfo, TripInfoDispatch, TripInfoProps } from './TripInfo';

const mapState = (state: AppState): TripInfoProps => ({
  time: state.map.time,
  trip: state.map.trip!,
  vehicle: state.map.selectedVehicle,
  mapReady: state.map.ready,
  theme: getSelectedTheme(state.config),
  useLineColor: state.config.components.tripInfo.useRouteColor
});

const mapDispatch = (dispatch: Dispatch): TripInfoDispatch => ({
  onClickHeader() {
    dispatch(follow(Following.SELECTED_VEHICLE));
  }
});

export default connect(
  mapState,
  mapDispatch
)(TripInfo);
