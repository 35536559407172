import React from 'react';

import { IconButton } from '@shared/form';
import { maximizeIcon, minimizeIcon } from '@shared/icons';

export interface FullscreenButtonProps {
  isFullscreen: boolean;
}

export interface FullscreenButtonDispatch {
  setFullscreen(isFullscreen: boolean): void;
}

type Props = FullscreenButtonDispatch & FullscreenButtonProps;

export function FullscreenButton({ isFullscreen, setFullscreen }: Props) {
  return (
    <IconButton onClick={() => setFullscreen(!isFullscreen)}>
      {isFullscreen ? minimizeIcon : maximizeIcon}
    </IconButton>
  );
}
