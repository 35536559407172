import React from 'react';

import { Trip, TripStop } from 'livemap-gl';
import styled, { millis, TripStopDeparture } from 'livemap-ui';

interface TripStopListProps {
  trip: Trip;
  time: number;
  lineColor: string | undefined;
  upcomingRef: (elm: HTMLDivElement) => void;
}

export function TripStopList({
  trip,
  time,
  lineColor,
  upcomingRef
}: TripStopListProps) {
  const timeOffset = millis(trip.utcOffset, 0, 0);
  const isPassed = (stop: TripStop) =>
    time > stop.departure + stop.departureDelay;

  const getStopPosition = (index: number) =>
    index === 0 ? 'first' : index === trip.stops.length - 1 ? 'last' : 'middle';

  const passed = trip.stops.filter(isPassed);
  const upcoming = trip.stops.slice(passed.length);

  return (
    <>
      <StopSection>
        {passed.map((s, i) => (
          <TripStopDeparture
            name={s.location.name}
            lineColor={lineColor}
            delay={s.departureDelay}
            departsAt={s.departure + timeOffset}
            isPassed={true}
            position={getStopPosition(i)}
            key={i}
          />
        ))}
      </StopSection>
      <StopSection ref={upcomingRef}>
        {upcoming.map((s, i) => (
          <TripStopDeparture
            name={s.location.name}
            lineColor={lineColor}
            delay={s.departureDelay}
            departsAt={s.departure + timeOffset}
            isPassed={false}
            position={getStopPosition(passed.length + i)}
            key={i}
          />
        ))}
      </StopSection>
    </>
  );
}

const StopSection = styled.div``;
