import React from 'react';
import { Translate, TranslateFunction } from 'react-localize-redux';

import styled, { FloatingPane } from 'livemap-ui';

import { ComponentsConfig } from '@core/app-config';
import { getRouteLayerId } from '@core/map/layers';

import { removeIcon } from '@shared/icons';

export interface RouteFiltersProps {
  components: ComponentsConfig;
  routeFilterTerms: string[];
}

export interface RouteFiltersDispatch {
  removeRouteFilter(layerId: string): void;
  vehicleRouteFilter(text: string[]): void;
}

export type Props = RouteFiltersProps & RouteFiltersDispatch;

export class RouteFilters extends React.PureComponent<Props> {
  public render() {
    if (!this.props.routeFilterTerms.length) {
      return null;
    }

    return (
      <RouteFiltersWrapper>
        {this.props.routeFilterTerms.length > 1 ? (
          <Translate>
            {({ translate }: { translate: TranslateFunction }) => (
              <div
                title={translate('Filter.helpText.removeAll').toString()}
                onClick={this.removeAllRouteFilters}
              >
                {removeIcon}
              </div>
            )}
          </Translate>
        ) : null}

        {this.props.routeFilterTerms.map((filterString, key) => (
          <RouteFilter key={key}>
            <Translate>
              {({ translate }: { translate: TranslateFunction }) => (
                <RouteIcon
                  title={translate('Filter.helpText.remove').toString()}
                  onClick={() => this.removeFilter(key)}
                >
                  {this.renderRemoveIcon()}
                </RouteIcon>
              )}
            </Translate>
            <RouteName>{filterString}</RouteName>
          </RouteFilter>
        ))}
      </RouteFiltersWrapper>
    );
  }

  private removeFilter(i: number) {
    const routeFilters = new Array(...this.props.routeFilterTerms);

    if (routeFilters[i]) {
      const currentRoute = routeFilters.splice(i, 1);
      this.props.vehicleRouteFilter(routeFilters);

      this.deselectRoute(currentRoute[0]);
    }
  }

  private removeAllRouteFilters() {
    const filters = new Array();
    this.props.vehicleRouteFilter(filters);

    this.props.routeFilterTerms.forEach((routeId) => {
      this.deselectRoute(routeId);
    });
  }

  private renderRemoveIcon = () => (
    <svg viewBox="0 0 24 24">
      <line x1="6" x2="18" y1="6" y2="18" />
      <line x1="6" x2="18" y1="18" y2="6" />
    </svg>
  );

  private deselectRoute(routeId: string) {
    this.props.removeRouteFilter(getRouteLayerId(routeId, 'DAY'));
    this.props.removeRouteFilter(getRouteLayerId(routeId, 'NIGHT'));
  }
}

const RouteFiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 6px 3px;
  position: absolute;
  top: 56px;
`;

const RouteFilter = styled(FloatingPane)`
  margin: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px;
  flex: 0 1 auto;
  border-radius: 200px; /* Creates a pill-like shape */
`;

const RouteName = styled.div`
  font-family: ${(p) => p.theme.headingFontFamily};
  font-weight: bold;
  padding: 0 6px 0 6px;
`;

const RouteIcon = styled.div`
  width: 24px;
  height: 24px;

  > svg {
    stroke: ${(p) => p.theme.contentColor};
    stroke-width: 2px;
  }
`;
