import { NO_SUGGESTIONS } from '@core/here';

import { AnyAppAction } from '../actions/app-actions';
import { SearchActionType } from '../actions/search-actions';
import { SearchState } from '../search-state';

export const initialState: SearchState = {
  isSearching: false,
  result: NO_SUGGESTIONS,
  query: ''
};

export function searchReducer(
  state: SearchState = initialState,
  action: AnyAppAction
): SearchState {
  switch (action.type) {
    case SearchActionType.CLEAR_QUERY:
      return { ...state, result: NO_SUGGESTIONS };
    case SearchActionType.SEND_AUTOSUGGEST:
      return { ...state, isSearching: true };
    case SearchActionType.AUTOSUGGEST_ERROR:
      return { ...state, isSearching: false };
    case SearchActionType.AUTOSUGGEST_SUCCESS:
      return { ...state, isSearching: false, result: action.payload };
    case SearchActionType.SET_QUERY:
      return { ...state, query: action.payload };
    default:
      return state;
  }
}
