import styled from 'livemap-ui';

export const Spinner = styled.div`
  @keyframes spinner-rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  border: 2px solid transparent;
  border-top-color: ${p => p.theme.detailColor};
  border-radius: 50%;
  animation: spinner-rotate 750ms linear infinite;
`;
