import { all, fork } from 'redux-saga/effects';

import { feedbackSaga } from './feedback-saga';
import { mapSaga } from './map-saga';
import { searchSaga } from './search-saga';
import { uiSaga } from './ui-saga';

export function* appSaga() {
  yield all([
    fork(feedbackSaga),
    fork(mapSaga),
    fork(searchSaga),
    fork(uiSaga)
  ]);
}
