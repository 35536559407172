import React, { useEffect, useState } from 'react';

import { VehicleTypeStats } from 'livemap-gl';
import styled, { DECELERATE_EASING, FloatingPane } from 'livemap-ui';

import { ColorTheme } from '@core/app-config';
import { callLivemap } from '@core/map/bindings';

import { Translate } from 'react-localize-redux';
import { GraphPoint, ViewportStats } from './ViewportStats';

export interface VehicleStatsProps {
  theme: ColorTheme;
  isNightMode: boolean;
  isVisible: boolean;
  hasMenu: boolean;
}

const getDelayDataPoint = (stats: VehicleTypeStats[]): GraphPoint[] =>
  stats.map(e => ({ value: e.delayMean, count: e.delayCount }));

const getSpeedDataPoint = (stats: VehicleTypeStats[]) =>
  stats.map(e => ({ value: e.speedMean, count: e.speedCount }));

const getVehicleCount = (stats: VehicleTypeStats[]) =>
  stats.map(e => ({ value: e.vehicleCount, count: e.vehicleCount }));

const formatThousands = (value: number) =>
  String(Math.round(value)).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

function formatTime(millis: number): string {
  const pad = (value: number) => {
    if (value > 9) {
      return String(value);
    }

    return '0' + value;
  };

  if (isNaN(millis)) {
    return '';
  }

  const ms = millis % 1000;
  millis = (millis - ms) / 1000;
  const secs = millis % 60;
  millis = (millis - secs) / 60;
  const mins = millis % 60;
  const hrs = (millis - mins) / 60;

  if (hrs > 0) {
    return pad(hrs) + ':' + pad(mins) + ':' + pad(secs) + 's';
  } else if (mins > 0) {
    return pad(mins) + ':' + pad(secs) + 's';
  }

  return secs + 's';
}

/** Contains stats for vehicles visible in the viewport */
export function VehicleStats(props: VehicleStatsProps) {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    window.setTimeout(() => setIsReady(true), 500);

    callLivemap(lm => (lm.modules.viewportStats.enabled = true));

    return () => {
      callLivemap(lm => (lm.modules.viewportStats.enabled = false));
    };
  }, []);

  if (!isReady || !props.isVisible) {
    return null;
  }

  const axesColor = '#aaa';
  const contentColor = '#fff';

  return (
    <StatsPane corners={[false, false, false, true]}>
      <ViewportStats
        title={<Translate id="VehicleStats.visibleVehicles" />}
        contentColor={contentColor}
        axesColor={axesColor}
        colorTheme={props.theme}
        formatOverlay={count => (!count ? 'N/A' : formatThousands(count))}
        formatAxis={value => formatThousands(value)}
        formatTitle={() => ''}
        getData={getVehicleCount}
      />
      <ViewportStats
        title={<Translate id="VehicleStats.averageDelay" />}
        contentColor={contentColor}
        axesColor={axesColor}
        colorTheme={props.theme}
        formatOverlay={(_, avg) => (!avg ? 'N/A' : formatTime(avg))}
        formatAxis={value => formatTime(value)}
        formatTitle={() => ''}
        getData={getDelayDataPoint}
      />
      <ViewportStats
        title={<Translate id="VehicleStats.averageSpeed" />}
        contentColor={contentColor}
        axesColor={axesColor}
        colorTheme={props.theme}
        formatOverlay={(_, avg) =>
          isNaN(avg) ? '' : String(Math.round(avg) + ' km/h')
        }
        formatAxis={value => String(Math.round(value))}
        formatTitle={() => ''}
        getData={getSpeedDataPoint}
      />
    </StatsPane>
  );
}

const StatsPane = styled(FloatingPane)`
  @keyframes slide-in {
    0% {
      transform: translateX(110%);
    }
    15% {
      transform: translateX(110%);
    }
    100% {
      transform: translateX(0);
    }
  }

  animation: slide-in 1200ms ${DECELERATE_EASING};
  position: absolute;
  pointer-events: none;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.85);
  color: rgba(255, 255, 255, 0.85);
  padding: 24px 12px 0px 12px;

  > h3 {
    font-size: 14px;
    text-transform: uppercase;
    font-family: ${p => p.theme.headingFontFamily};
    font-weight: 500;
    margin-bottom: 6px;
    margin-left: 40px;
  }

  &::before {
    content: ' ';
    opacity: 0.3;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(
        circle at top right,
        ${p => p.theme.detailColor} 4%,
        transparent 35%
      ),
      radial-gradient(circle at top right, #6d18dc 4%, transparent 55%);
  }
`;
