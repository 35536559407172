/* Livemap GL JS Viewport stats. Copyright Veridict AB. */

import { CSSColor, formatFontString, HexColor } from 'livemap-gl';

export interface GraphPoint {
  time: number;
  value: number;
}

export interface LineGraphOptions {
  axesColor: string;
  axesFont: string;
  axesHeaderFont: string;
  axesThickness: number;
  background: CSSColor | CanvasGradient | CanvasPattern;
  vehicleColors: HexColor[];
  graphBackground: CSSColor | CanvasGradient | CanvasPattern;
  graphBottomMargin: number;
  graphLeftMargin: number;
  graphRightMargin: number;
  graphTopMargin: number;
  height: number;
  leftTimeAxisPrefix: string;
  backgroundLines: HexColor;
  lineThickness: number;
  overlayColor: HexColor;
  overlayFont: HexColor;
  overlayText: string;
  rightTimeAxisPrefix: string;
  timeAxisHeaderText: string;
  titleColor: string;
  titleFont: string;
  titleText: string;
  valueAxisHeaderOffset: number;
  valueAxisHeaderText: string;
  width: number;
  xOffset: number;
  yOffset: number;
  formatValue(value: number): string;
}

const defaultAxesFont = formatFontString({
  family: '"Barlow Semi Condensed", "Roboto", Arial, sans-serif',
  weight: 500,
  size: 13
});

const defaultAxesHeaderFont = formatFontString({
  family: '"Barlow Semi Condensed", "Roboto", Arial, sans-serif',
  weight: 800,
  size: 13
});

const defaultTitleFont = formatFontString({
  family: '"Barlow Semi Condensed", "Roboto", Arial, sans-serif',
  weight: 800,
  size: 20
});

const defaultOverlayFont = formatFontString({
  family: '"Barlow Semi Condensed", "Roboto", Arial, sans-serif',
  weight: 800,
  size: 38
});

export const defaultLineGraphOptions: LineGraphOptions = {
  axesColor: '#000000',
  axesFont: defaultAxesFont,
  axesHeaderFont: defaultAxesHeaderFont,
  axesThickness: 1,
  background: 'rgba(255, 255, 255, 0.0)',
  graphBackground: 'rgba(255, 255, 255, 0.0)',
  graphBottomMargin: 30,
  graphLeftMargin: 40,
  graphRightMargin: 10,
  graphTopMargin: 0,
  height: 200,
  leftTimeAxisPrefix: 'T-',
  vehicleColors: [],
  backgroundLines: '#00000099',
  lineThickness: 3,
  overlayColor: 'rgba(255,0,0,0.25)',
  overlayFont: defaultOverlayFont,
  rightTimeAxisPrefix: '',
  overlayText: '',
  timeAxisHeaderText: '',
  titleColor: '#000000',
  titleFont: defaultTitleFont,
  titleText: '',
  valueAxisHeaderOffset: 50,
  valueAxisHeaderText: '',
  width: 452,
  xOffset: 0,
  yOffset: 0,
  formatValue: (value: number) => value.toString()
};

export class LineGraph {
  public duration: number = 50000;
  public startTime: number = 0;
  public valueMax: number = 30000;
  public valueMin: number = -30000;

  /**
   * Function that formats the horizontal time axis text. Override to provide your own format.
   * @param time The time in milliseconds
   * @returns string The text to draw.
   */
  public formatTime = this.formatTimeInternal;

  /**
   * The overlay text.
   */
  public overlayText?: string;
  /**
   * The points to draw.
   */
  public points: GraphPoint[][] = [];

  /**
   * True if the graph line should be visible.
   */
  public graphLineVisible = true;

  public options: LineGraphOptions;

  private graphHeight: number = 0;

  private graphWidth: number = 0;

  constructor(options?: Partial<LineGraphOptions>) {
    this.options = {
      ...defaultLineGraphOptions,
      ...options
    };

    if (this.options.valueAxisHeaderText) {
      this.options.graphLeftMargin = 60;
    }

    if (this.options.titleText) {
      this.options.graphTopMargin = 25;
    }
  }

  public resize(width: number, height: number): void {
    this.options.width = width;
    this.options.height = height;
  }

  public adjustAxis(step: number) {
    let valueMax = -9007199254740991;
    let valueMin = 9007199254740991;

    let timeMax = -9007199254740991;
    let timeMin = 9007199254740991;

    for (const j of this.points) {
      for (const i of j) {
        const v = i.value;
        if (v > valueMax) {
          valueMax = v;
        }

        if (v < valueMin) {
          valueMin = v;
        }

        const t = i.time;
        if (t > timeMax) {
          timeMax = t;
        }

        if (t < timeMin) {
          timeMin = t;
        }
      }
    }

    const valuePadding = Math.ceil((valueMax - valueMin) / step / 3) * step;

    this.valueMax = Math.ceil(valueMax + valuePadding);
    this.valueMin = Math.floor(valueMin - valuePadding);
    this.startTime = timeMin;
    this.duration = timeMax - timeMin;
  }

  public draw(ctx: CanvasRenderingContext2D): void {
    const {
      width,
      height,
      graphLeftMargin,
      graphBottomMargin,
      graphRightMargin,
      graphTopMargin,
      xOffset,
      yOffset,
      background,
      titleText,
      titleColor,
      titleFont,
      graphBackground,
      overlayFont
    } = this.options;

    this.graphHeight = height - graphTopMargin - graphBottomMargin;
    this.graphWidth = width - graphLeftMargin - graphRightMargin;

    ctx.save();
    ctx.setTransform(1, 0, 0, 1, xOffset, yOffset);

    ctx.clearRect(0, 0, width, height);

    // Draw background
    ctx.fillStyle = background;
    ctx.fillRect(0, 0, width, height);

    // Draw graph title
    if (titleText) {
      ctx.font = titleFont;
      ctx.textAlign = 'left';
      ctx.textBaseline = 'top';
      ctx.fillStyle = titleColor;
      ctx.fillText(titleText, graphLeftMargin, -4);
    }

    this.drawAxis(ctx);

    // Translate to graph
    ctx.setTransform(
      1,
      0,
      0,
      1,
      graphLeftMargin + xOffset,
      graphTopMargin + yOffset
    );

    const topPos = 25;

    const line1 = 0 + topPos;
    const line2 = 25 + topPos;
    const line3 = 50 + topPos;
    const line4 = 75 + topPos;

    const lineStartPos = 0;
    const lineEndPos = 0;
    ctx.lineWidth = 0.5;

    ctx.strokeStyle = this.options.backgroundLines;

    const graphWidth: number = this.graphWidth;
    // tslint:disable-next-line:no-any
    function drawLine(line: string | number): void {
      const mt = ctx.moveTo.bind(ctx); // fillRect instead of moveTo for demo
      const lt = ctx.lineTo.bind(ctx); // fillRect instead of moveTo for demo

      mt(lineStartPos, line);
      lt(graphWidth - lineEndPos, line);
    }

    drawLine(line1);
    drawLine(line2);
    drawLine(line3);
    drawLine(line4);

    ctx.stroke();

    // Set graph clipping
    ctx.rect(1, -1, this.graphWidth - 2, this.graphHeight);
    ctx.clip();

    // Clear graph area
    ctx.fillStyle = graphBackground;
    ctx.fillRect(0, 0, this.graphWidth, this.graphHeight);

    if (this.graphLineVisible) {
      this.drawGraph(ctx);
    }

    ctx.fillStyle = titleColor;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.font = overlayFont;
    if (this.overlayText) {
      ctx.fillText(this.overlayText, width / 2 - 25, height / 2 - 25);
    }
    ctx.restore();
  }

  public drawGraph(ctx: CanvasRenderingContext2D) {
    const { vehicleColors, lineThickness } = this.options;

    const xf = this.graphWidth / this.duration;
    const yf = this.graphHeight / (this.valueMax - this.valueMin);

    const getPoint = (type: number, index: number) => {
      const p = this.points[index][type];
      const x = (p.time - this.startTime) * xf;
      const y = (this.valueMax - p.value) * yf;
      return { x, y };
    };

    if (this.points.length > 2) {
      for (let i = 0; i < this.points[0].length; i++) {
        ctx.strokeStyle = vehicleColors[i];
        ctx.lineWidth = lineThickness;

        const point = getPoint(i, 0);
        ctx.moveTo(point.x, point.y);
        ctx.beginPath();

        let drawnPoints = 0;

        for (let j = 0; j < this.points.length; j++) {
          const shouldDraw = !!this.points[j][i].value;

          if (shouldDraw) {
            const { x, y } = getPoint(i, j);
            ctx.lineTo(x, y);

            drawnPoints++;
          }
        }

        if (drawnPoints > 3) {
          ctx.stroke();
        }
      }
    }
  }

  private drawAxis(ctx: CanvasRenderingContext2D) {
    const {
      axesColor,
      axesThickness,
      formatValue,
      graphLeftMargin,
      graphTopMargin,
      height,
      leftTimeAxisPrefix,
      rightTimeAxisPrefix,
      timeAxisHeaderText,
      valueAxisHeaderOffset,
      valueAxisHeaderText,
      xOffset,
      yOffset
    } = this.options;

    // Draw axes lines
    ctx.strokeStyle = axesColor;
    ctx.lineWidth = axesThickness;
    ctx.beginPath();
    ctx.moveTo(graphLeftMargin, graphTopMargin);
    ctx.lineTo(graphLeftMargin, graphTopMargin + this.graphHeight);
    ctx.lineTo(
      graphLeftMargin + this.graphWidth,
      graphTopMargin + this.graphHeight
    );
    ctx.stroke();

    // Draw value axis markers and text
    const yf = this.graphHeight / (this.valueMax - this.valueMin);

    ctx.lineWidth = 1;
    ctx.textBaseline = 'middle';
    ctx.textAlign = 'right';
    ctx.fillStyle = axesColor;
    ctx.font = defaultAxesFont;
    ctx.strokeStyle = axesColor;

    ctx.beginPath();

    const step = (this.valueMax - this.valueMin) / 5;

    for (let i = this.valueMin; i < this.valueMax - step / 2; i += step) {
      const y = graphTopMargin + (this.valueMax - i) * yf;
      const x = graphLeftMargin;
      ctx.fillText(formatValue(i), x - 6, y);
      ctx.moveTo(x - 5, y);
      ctx.lineTo(x, y);
    }
    ctx.stroke();

    // Draw time axis markers and text
    ctx.lineWidth = 1;
    ctx.moveTo(graphLeftMargin, graphTopMargin + this.graphHeight);
    ctx.lineTo(graphLeftMargin, graphTopMargin + this.graphHeight + 5);
    ctx.moveTo(
      graphLeftMargin + this.graphWidth,
      graphTopMargin + this.graphHeight
    );
    ctx.lineTo(
      graphLeftMargin + this.graphWidth,
      graphTopMargin + this.graphHeight + 5
    );
    ctx.stroke();
    ctx.textAlign = 'left';
    ctx.textBaseline = 'top';
    ctx.font = defaultAxesFont;
    ctx.fillText(
      leftTimeAxisPrefix + this.formatTime(this.duration),
      graphLeftMargin - 10,
      graphTopMargin + this.graphHeight + 10
    );
    ctx.textAlign = 'right';
    ctx.fillText(
      rightTimeAxisPrefix + this.formatTime(this.startTime + this.duration),
      graphLeftMargin + 10 + this.graphWidth,
      graphTopMargin + this.graphHeight + 10
    );

    // Draw value axis header
    if (valueAxisHeaderText) {
      ctx.textBaseline = 'middle';
      ctx.textAlign = 'center';
      ctx.font = defaultAxesHeaderFont;
      ctx.rotate(-Math.PI / 2);
      ctx.translate(-valueAxisHeaderOffset - graphTopMargin + 6, 10);
      ctx.fillText(valueAxisHeaderText, 0, 0);
      ctx.setTransform(1, 0, 0, 1, xOffset, yOffset);
    }
    // Draw time axis header
    ctx.textAlign = 'center';
    ctx.textBaseline = 'bottom';
    ctx.font = defaultAxesHeaderFont;
    ctx.fillText(
      timeAxisHeaderText,
      graphLeftMargin + this.graphWidth / 2,
      height - 10
    );
  }

  private formatTimeInternal(time: number): string {
    const sec = (time / 1000) % 86400;
    const hours = Math.floor(sec / 3600);
    const minutes = Math.floor((sec - hours * 3600) / 60);
    const seconds = Math.floor(sec - hours * 3600 - minutes * 60);
    return (
      (hours < 10 ? `0${hours}` : `${hours}`) +
      ':' +
      (minutes < 10 ? `0${minutes}` : `${minutes}`) +
      ':' +
      (seconds < 10 ? `0${seconds}` : `${seconds}`)
    );
  }
}
