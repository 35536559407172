import React from 'react';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { VehicleType } from 'livemap-gl';
import styled, { ScrollPane, ThemeMode } from 'livemap-ui';

import {
  AppConfig,
  CustomLink,
  getBrandLogo,
  getSelectedTheme,
  getVehicleType
} from '@core/app-config';

import {
  dayModeIcon,
  icon2D,
  icon3D,
  nightModeIcon,
  trazeIcon
} from '@shared/icons';

import Sidebar from '../../components/Sidebar/SidebarContainer';
import { Settings } from './components/Settings/Settings';
import { VehicleOptions } from './components/VehicleOptions/VehicleOptions';

import appPkg from '../../../package.json';

export interface MainMenuProps {
  isVisible: boolean;
  config: AppConfig;
  visibleVehicleTypes: VehicleType[];
  isPerspectiveViewEnabled: boolean;
}

export interface MainMenuDispatch {
  openFeedback(): void;
  setThemeMode(theme: ThemeMode): void;
  togglePerspective(): void;
  toggleDisplayVehicle(type: VehicleType): void;
  openNotice(): void;
}

type Props = MainMenuDispatch & MainMenuProps;

/**
 * Menu
 */
export function MainMenu({
  config,
  isVisible,
  visibleVehicleTypes,
  isPerspectiveViewEnabled,
  openFeedback,
  setThemeMode,
  togglePerspective,
  toggleDisplayVehicle,
  openNotice
}: Props) {
  if (!isVisible) {
    return null;
  }

  const { menu } = config.components;

  const toggleTheme = () =>
    setThemeMode(config.themeMode === 'night' ? 'day' : 'night');

  const getAvailableVehicleTypes = () =>
    config.vehicles.available
      .map(getVehicleType)
      .filter((vt) => vt !== undefined);

  const renderLogoOrTitle = () => {
    const logo = getBrandLogo(config);

    if (logo) {
      return (
        <AppLogo>
          <img src={logo.url} alt={logo.alt || config.brand.name || ''} />
        </AppLogo>
      );
    }

    return <AppTitle>{config.brand.name || 'Traze'}</AppTitle>;
  };

  return (
    <Sidebar>
      <MainMenuWrapper>
        {renderLogoOrTitle()}
        {menu.versionNumber ? (
          <Version>
            {trazeIcon} {appPkg.version}
          </Version>
        ) : null}

        <Translate>
          {({ translate }: { translate: TranslateFunction }) => (
            <Settings
              settings={[
                menu.perspectiveView
                  ? {
                      label: translate(
                        'SideMenu.settings.normalView'
                      ).toString(),
                      isEnabled: isPerspectiveViewEnabled,
                      toggle: togglePerspective,
                      enabledIcon: icon2D,
                      disabledIcon: icon3D,
                      disabledLabel: translate(
                        'SideMenu.settings.perspectiveView'
                      ).toString()
                    }
                  : null,
                menu.nightMode
                  ? {
                      label: translate('SideMenu.settings.dayMode').toString(),
                      isEnabled: config.themeMode === 'night',
                      toggle: toggleTheme,
                      enabledIcon: nightModeIcon,
                      disabledIcon: dayModeIcon,
                      disabledLabel: translate(
                        'SideMenu.settings.nightMode'
                      ).toString()
                    }
                  : null
              ]}
            />
          )}
        </Translate>

        {menu.vehicleFilters ? (
          <>
            <SectionDivider>
              <Translate id="SideMenu.vehicleTypes" />
            </SectionDivider>
            <VehicleOptions
              key="vehicleFilters"
              colorTheme={getSelectedTheme(config)}
              toggleDisplay={toggleDisplayVehicle}
              availableVehicleTypes={getAvailableVehicleTypes()}
              visibleVehicleTypes={visibleVehicleTypes}
              collapsedCount={config.vehicles.sideMenuVisibleCollapsed}
            />
          </>
        ) : null}

        <BottomMenu>
          {menu.feedback ? (
            <MenuLink onClick={openFeedback}>
              <Translate id="SideMenu.bottomMenu.feedback" />
            </MenuLink>
          ) : null}
          {menu.customLinks
            ? menu.customLinks.map((link, index) =>
                renderCustomLink(link, index.toString())
              )
            : null}
          <MenuLink onClick={openNotice}>
            <Translate id="SideMenu.bottomMenu.notice" />
          </MenuLink>
        </BottomMenu>
      </MainMenuWrapper>
    </Sidebar>
  );
}

const renderCustomLink = (link: CustomLink, key: string) => (
  <MenuLink key={key} href={link.url} target="_blank">
    {link.translationId ? (
      <Translate id={link.translationId} />
    ) : link.text ? (
      link.text
    ) : null}
  </MenuLink>
);

const MainMenuWrapper = styled(ScrollPane)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  user-select: none;
`;

const BottomMenu = styled.div`
  margin-top: auto;
  width: 100%;
  margin-bottom: 12px;
`;

const MenuLink = styled.a`
  display: block;
  text-decoration: none;
  font-family: ${(p) => p.theme.headingFontFamily};
  font-weight: 500;
  padding: 12px 24px;

  &:active {
    color: ${(p) => p.theme.detailColor};
    background-color: ${(p) => p.theme.altBackgroundColor};
  }
`;

const Version = styled.div`
  width: 100%;
  font-size: 12px;
  padding: 0 0 12px 14px;
  font-weight: 500;
  opacity: ${(p) => p.theme.dimOpacity};

  > svg {
    margin-right: 3px;
    margin-bottom: -1px;
    width: 11px;
    height: 11px;
  }
`;

const AppTitle = styled.h1`
  font-family: ${(p) => p.theme.headingFontFamily};
  font-size: 32px;
  font-weight: bold;
  padding: 8px 12px 0 12px;
  line-height: 1.1;
`;

const AppLogo = styled.figure`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  margin: 24px 12px 12px 12px;
  max-height: 80px;
  max-width: 60%;

  > img {
    flex-shrink: 0;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
`;

const SectionDivider = styled.h4`
  font-size: 14px;
  font-family: ${(p) => p.theme.headingFontFamily};
  text-transform: uppercase;
  margin-top: 12px;
  padding: 6px 24px;
  opacity: ${(p) => p.theme.disabledOpacity};
`;
