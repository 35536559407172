import styled from 'livemap-ui';

/** Provides a standardized padding for sheet contents */
export const SheetContent = styled.div`
  position: relative;
  padding: 6px 12px;
`;

/** Provides a standardized padding for sheet contents, with some extra padding on top. */
export const PaddedSheetContent = styled.div`
  position: relative;
  padding: 16px 12px 6px 12px;
`;

export const SheetHeader = styled.header`
  z-index: 1;
  position: relative;
  background-color: ${p => p.theme.backgroundColor};
  box-shadow: 0 0 26px 26px ${p => p.theme.backgroundColor};
`;
