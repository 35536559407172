import { TripStop } from 'livemap-gl';
import { minutes } from 'livemap-ui';

export const enum DelayStatus {
  UNKNOWN,
  ON_TIME,
  LATE,
  EARLY
}

export const THRESHOLD_EARLY: number = -minutes(1);
export const THRESHOLD_LATE: number = minutes(1);

export function getDelayStatus(delay: number) {
  if (isNaN(delay)) {
    return DelayStatus.UNKNOWN;
  }

  if (delay >= THRESHOLD_LATE) {
    return DelayStatus.LATE;
  } else if (delay <= THRESHOLD_EARLY) {
    return DelayStatus.EARLY;
  }

  return DelayStatus.ON_TIME;
}

/**
 * Returns the estimated arrival delay to the next stop.
 * @param stops A list of stops.
 * @param timestamp A UNIX timestamp used for determining what is "the next stop", usually the current time.
 */
export function getNextArrivalDelay(stops: TripStop[], timestamp: number) {
  if (!stops || !stops.length) {
    return NaN;
  }

  // NOTE:
  //   `s.passed` would work, but it gets "falsified"
  //    on each toxel update, which means it will
  //    toggle between the first stop, and the next stop
  //    which looks very glitchy.
  const nextStop = stops.find(s => s.arrival + s.arrivalDelay > timestamp);

  if (nextStop) {
    return nextStop.arrivalDelay;
  }

  return NaN;
}
