import React, { useEffect, useState } from 'react';

import { getBearing, onCameraUpdated } from '@core/map/camera';

import styled, { DECELERATE_EASING, SquareButton } from 'livemap-ui';

interface Props {
  onClick(): void;
}

export function Compass({ onClick }: Props) {
  const [rotation, setRotation] = useState(getBearing());

  useEffect(() => onCameraUpdated(camera => setRotation(camera.bearing || 0)), [
    setRotation
  ]);

  if (!rotation) {
    return null;
  }

  const style = { transform: `rotateZ(${-rotation}deg)` };

  return (
    <CompassButton onClick={onClick}>
      <svg style={style} viewBox="0 0 24 24">
        <path d="M 9 12 l 3 -12 3 12z" className="north" />
        <path d="M 9 12 l 3 12 3 -12z" className="south" />
      </svg>
    </CompassButton>
  );
}

const CompassButton = styled(SquareButton)`
  border-radius: 50%;

  svg {
    width: 24px;
    height: 24px;

    .south {
      fill: ${p => p.theme.contentColor};
    }

    .north {
      fill: #d32f2f !important;
    }
  }

  &:hover svg {
    fill: ${p => p.theme.detailColor} !important;
    transition: transform 160ms ${DECELERATE_EASING};
    transform: rotateZ(0deg) !important;
  }
`;
