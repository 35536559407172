import React, { useEffect, useState } from 'react';

import { Trip } from 'livemap-gl';

import { findScrollParent } from '@core/element';

import { FeedInfo } from './FeedInfo';
import { TripStopList } from './TripStopList';

interface TripContentProps {
  trip: Trip;
  time: number;
  lineColor?: string;
}

type Props = TripContentProps;

export function TripContent({ trip, time, lineColor }: Props) {
  const [upcomingSection, setUpcomingSection] = useState<HTMLElement | null>(
    null
  );

  useEffect(
    () => {
      if (upcomingSection) {
        const container = findScrollParent(upcomingSection);
        const scrollMargin = -36;

        if (container) {
          container.scrollTop = upcomingSection.offsetTop + scrollMargin;
        }
      }
    },
    [upcomingSection]
  );

  return (
    <>
      <TripStopList
        lineColor={lineColor}
        trip={trip}
        time={time}
        upcomingRef={setUpcomingSection}
      />
      <FeedInfo
        agencyName={trip.agency.name}
        agencyURL={trip.agency.site}
        publisherName={trip.feed.publisherName}
        publisherURL={trip.feed.publisherURL}
      />
    </>
  );
}
