import styled, { ButtonGroup, SquareButton } from 'livemap-ui';
import React from 'react';

import { minusIcon, plusIcon } from '@shared/icons';

export interface ZoomButtonsDispatch {
  zoomIn(): void;
  zoomOut(): void;
}

export function ZoomButtons({ zoomIn, zoomOut }: ZoomButtonsDispatch) {
  return (
    <ButtonGroup>
      <ZoomButton onClick={zoomIn}>{plusIcon}</ZoomButton>
      <ZoomButton onClick={zoomOut}>{minusIcon}</ZoomButton>
    </ButtonGroup>
  );
}

const ZoomButton = styled(SquareButton)`
  svg {
    width: 14px;
    height: 14px;
  }
`;
