import { Action } from 'redux';
import { AnyConfigAction } from './config-actions';
import { AnyFeedbackAction } from './feedback-actions';
import { AnyMapAction } from './map-actions';
import { AnySearchAction } from './search-actions';
import { AnyUIAction } from './ui-actions';

export type AnyAppAction =
  | AnyFeedbackAction
  | AnyMapAction
  | AnySearchAction
  | AnyUIAction
  | AnyConfigAction;

/** Describes an action which does not carry a payload. */
export interface EmptyAction<T> extends Action<T> {
  readonly type: T;
}

/** Describes an action which carries with it a payload. */
export interface PayloadedAction<T, P> extends Action<T> {
  readonly payload: P;
}

export const createEmpty = <T>(type: T): EmptyAction<T> => ({ type });

export const createPayloaded = <T, P>(
  type: T,
  payload: P
): PayloadedAction<T, P> => ({
  type,
  payload
});
