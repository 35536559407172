import { Mesh3D, VehicleModelSelector } from 'livemap-gl';

import { CustomLivemapModel } from './app-config';
import { assertOK, getErrorDescription } from './error';

export interface MeshInfo {
  id: number;
  mesh: Mesh3D;
}

export async function loadModelSelector(
  url: string
): Promise<VehicleModelSelector> {
  const res = await fetch(url);

  assertOK(res);

  const code = await res.text();

  // tslint:disable-next-line: function-constructor
  const createModelSelector = Function(code);
  const modelSelector = createModelSelector();

  return modelSelector as VehicleModelSelector;
}

export async function loadModels(
  modelConfigs: CustomLivemapModel[]
): Promise<MeshInfo[]> {
  const models = [];

  for (const config of modelConfigs) {
    const res = await fetch(config.path);

    if (!res.ok) {
      console.warn(
        `Failed to load model '${config.modelId}' with the URL '${config.path}'`
      );

      continue;
    }

    try {
      const mesh = (await res.json()) as Mesh3D;
      models.push({ id: config.modelId, mesh });
    } catch (error) {
      console.warn(
        `Failed to parse model ${config.modelId}.\n  ` +
          getErrorDescription(error)
      );
    }
  }

  return models;
}
