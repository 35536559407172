import { AnyAppAction } from '../actions/app-actions';
import { FeedbackActionType } from '../actions/feedback-actions';
import { FeedbackState } from '../feedback-state';

export const initialState: FeedbackState = {
  isSubmitted: false,
  isSubmitting: false,
  error: null
};

export function feedbackReducer(
  state: FeedbackState = initialState,
  action: AnyAppAction
): FeedbackState {
  switch (action.type) {
    case FeedbackActionType.SUBMIT:
      return { ...state, isSubmitting: true };
    case FeedbackActionType.SUBMIT_SUCCESS:
      return { ...state, isSubmitting: false, isSubmitted: true };
    case FeedbackActionType.SUBMIT_ERROR:
      return {
        ...state,
        isSubmitting: false,
        error: action.payload
      };
    case FeedbackActionType.OPEN:
    case FeedbackActionType.CLOSE:
      return {
        ...state,
        isSubmitted: false,
        isSubmitting: false
      };
    default:
      return state;
  }
}
