import { connect } from 'react-redux';

import { zoomIn, zoomOut } from '@state/actions';

import { ZoomButtons, ZoomButtonsDispatch } from './ZoomButtons';

const mapDispatch: ZoomButtonsDispatch = { zoomIn, zoomOut };

export default connect(
  null,
  mapDispatch
)(ZoomButtons);
