import { AnyLayer } from 'mapbox-gl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { getSelectedTheme } from '@core/app-config';
import { AutosuggestItem } from '@core/here';

import {
  clearSearchQuery,
  clearSelectedStop,
  clearSelectedVehicle,
  removeMapLayer,
  selectAutosuggestItem,
  sendAutosuggest,
  setMapLayer,
  setSearchQuery
} from '@state/actions';

import { AppState } from '@state/app-state';

import { Omnibox, OmniboxDispatch, OmniboxProps } from './Omnibox';

const mapState = (state: AppState): OmniboxProps => ({
  query: state.search.query,
  result: state.search.result,
  components: state.config.components,
  theme: getSelectedTheme(state.config),
  isSearching: state.search.isSearching
});

const mapDispatch = (dispatch: Dispatch): OmniboxDispatch => ({
  clearResult: () => dispatch(clearSearchQuery()),
  fetchAutocomplete: (query: string) => dispatch(sendAutosuggest(query)),
  selectItem: (item: AutosuggestItem) => dispatch(selectAutosuggestItem(item)),
  clearStop: () => dispatch(clearSelectedStop()),
  clearTrip: () => dispatch(clearSelectedVehicle()),
  setRouteFilter: (layer: AnyLayer) => dispatch(setMapLayer(layer)),
  removeRouteFilter: (layerId: string) => dispatch(removeMapLayer(layerId)),
  setQuery: (query: string) => dispatch(setSearchQuery(query))
});

export default connect(mapState, mapDispatch)(Omnibox);
