import { connect } from 'react-redux';

import { closeFeedback, submitFeedback } from '@state/actions';
import { AppState } from '@state/app-state';

import { Feedback, FeedbackDispatch, FeedbackProps } from './Feedback';

const mapDispatch: FeedbackDispatch = { submitFeedback, closeFeedback };

const mapProps = ({ feedback }: AppState): FeedbackProps => ({
  ...feedback
});

export default connect(
  mapProps,
  mapDispatch
)(Feedback);
