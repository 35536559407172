import {
  GeolocationUpdateEvent,
  SelectedTripDataEvent,
  StopSelectedEvent,
  StopsFoundEvent,
  TimeChangedEvent,
  TripSelectedEvent,
  TypedEventCallback,
  Vehicle
} from 'livemap-gl';
import { EventData, MapEventType } from 'mapbox-gl';

import { callLivemap, callMapbox } from './bindings';

type GeoUpdateCallback = TypedEventCallback<GeolocationUpdateEvent>;

export const onGeoLocationUpdate = (callback: GeoUpdateCallback) =>
  callLivemap(lm => lm.sensors.onGeoLocationUpdate(callback));

/** Binds a function to an event */
export const on = <T extends keyof MapEventType>(
  type: T,
  listener: (ev: MapEventType[T] & EventData) => void
) => callMapbox(mb => mb.on(type, listener));

/** Binds a function to an event and unbinds it once it has happened */
export const once = <T extends keyof MapEventType>(
  type: T,
  listener: (ev: MapEventType[T] & EventData) => void
) => callMapbox(mb => mb.once(type, listener));

/**
 * Subscribe to stop deselect events
 * @param callback Deselect stop event handler
 */
export const onStopDeselected = (callback: () => void) =>
  callLivemap(lm => lm.onStopDeselected(callback));

/**
 * Subscribe to stop selected events
 * @param callback Select stop event handler
 */
export const onStopSelected = (
  callback: (stopArgs: StopSelectedEvent) => void
) => callLivemap(lm => lm.onStopSelected(callback));

/**
 * Subscribe to time changed events
 * @param callback Time change event handler
 */
export const onTimeChanged = (callback: (stopArgs: TimeChangedEvent) => void) =>
  callLivemap(lm => lm.onTimeChanged(callback));

/**
 * Subscribe to trip deselect events
 * @param callback Deselect trip event handler
 */
export const onVehicleDeselected = (callback: () => void) =>
  callLivemap(lm => lm.onTripDeselected(callback));

/**
 * Subscribe to trip selected events
 * @param callback Select trip event handler
 */
export const onVehicleSelected = (
  callback: (event: TripSelectedEvent) => void
) => callLivemap(lm => lm.onTripSelected(callback));

/**
 * Subscribe to trip selected data events
 * @param callback Select trip event data handler
 */
export const onTripSelectedData = (
  callback: (event: SelectedTripDataEvent) => void
) => callLivemap(lm => lm.onTripSelectedData(callback));

/**
 * Subscribe to updates to currently selected
 * @param callback called on vehicle update
 */
export const onSelectedVehicleUpdated = (callback: (v: Vehicle) => void) =>
  callLivemap(lm => lm.onSelectedVehicleUpdated(callback));

export const onStopsFound = (callback: (event: StopsFoundEvent) => void) =>
  callLivemap(lm => lm.onStopsFound(callback));
