import { Action, Dispatch } from 'redux';

export interface ModifierKeys {
  /**
   * Describes whether or not the CTRL-key should be
   * held down in conjunction with another key.
   */
  CTRL?: boolean;
  /**
   * Describes whether or not the ALT-key should be
   * held down in conjunction with another key.
   */
  ALT?: boolean;
}

export interface Keybinding {
  /** The key code of the bound key. */
  keyCode: number;
  /** The actions to dispatch when the key is pressed. */
  actions: Action[];
  /** A string describing the action */
  description: string;
  /** Which modifier keys are used in conjunction with the bound key. */
  modifiers?: ModifierKeys;
}

/**
 * When used in a key binding,
 * denotes that no modifier keys (`CTRL`, `ALT`) can be held down
 */
export const NO_MODS: ModifierKeys = { CTRL: false, ALT: false };

/**
 * When used in a key binding,
 * denotes that the CTRL-modifier key must be held down exclusively
 */
export const CTRL_MOD: ModifierKeys = { CTRL: true, ALT: false };

/**
 * When used in a key binding,
 * denotes that the ALT-modifier key must be held down exclusively
 */
export const ALT_MOD: ModifierKeys = { ALT: true, CTRL: false };

/**
 * When used in a key binding,
 * denotes that both the CTRL and ALT modifier keys must be held down together
 */
export const CTRL_ALT_MODS: ModifierKeys = { CTRL: true, ALT: true };

export const hasModifiers = (
  event: KeyboardEvent,
  mod: ModifierKeys = NO_MODS
) => event.ctrlKey === Boolean(mod.CTRL) && event.altKey === Boolean(mod.ALT);

export type UnbindKeysCallback = () => void;

/**
 * Binds the provided keys to dispatch their configured action(s).
 *
 * If a keybinding has multiple actions,
 * they are dispatched in the order they were provided.
 *
 * @param dispatch The action dispatching function
 * @param bindings The configured key-bindings
 * @returns a callback; which when called, unbinds the keys.
 */
export function bindActions(
  dispatch: Dispatch,
  bindings: Keybinding[]
): UnbindKeysCallback {
  function findBinding(event: KeyboardEvent): Keybinding | undefined {
    return bindings.find(
      binding =>
        hasModifiers(event, binding.modifiers) &&
        binding.keyCode === event.keyCode
    );
  }

  function keyListener(event: KeyboardEvent) {
    const binding = findBinding(event);

    if (binding) {
      event.preventDefault();

      for (const action of binding.actions) {
        dispatch(action);
      }

      return false;
    }

    return true;
  }

  window.addEventListener('keyup', keyListener);

  return () => window.removeEventListener('keyup', keyListener);
}
