import React from 'react';

import styled from 'livemap-ui';

interface Setting {
  label: string;
  isEnabled: boolean;
  enabledIcon: JSX.Element;
  disabledIcon: JSX.Element;
  disabledLabel: string;
  toggle(): void;
}

interface Props {
  settings: Array<Setting | null>;
}

export function Settings(props: Props) {
  const renderIcon = (setting: Setting) => {
    if (setting.enabledIcon) {
      if (setting.isEnabled) {
        return setting.enabledIcon;
      } else if (setting.disabledIcon) {
        return setting.disabledIcon;
      } else {
        return setting.enabledIcon;
      }
    }
    return null;
  };

  const renderLabel = (setting: Setting) =>
    setting.isEnabled ? setting.label : setting.disabledLabel || setting.label;

  const renderSetting = (setting: Setting | null) =>
    setting != null ? (
      <Setting key={setting.label} onClick={setting.toggle}>
        <SettingIcon>{renderIcon(setting)}</SettingIcon>
        {renderLabel(setting)}
      </Setting>
    ) : null;

  return <SettingsWrapper>{props.settings.map(renderSetting)}</SettingsWrapper>;
}

const SettingIcon = styled.figure`
  display: flex;
  margin-right: 12px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const Setting = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 12px 24px 12px 24px;
  font-family: ${p => p.theme.headingFontFamily};

  &:active {
    background-color: ${p => p.theme.altBackgroundColor};
    color: ${p => p.theme.detailColor};
  }
`;
