import React from 'react';

import styled, {
  BOTTOM_CORNERS,
  DECELERATE_EASING,
  FloatingPane,
  OverHeading,
  px
} from 'livemap-ui';
import { Translate } from 'react-localize-redux';

import { AutosuggestItem, AutosuggestResult } from '@core/here';
import { getRouteLayerId, queryLayers } from '@core/map/layers';
import { trimRouteFilterText as normalizeFilter } from '@core/route-filter';

import {
  cityIcon,
  countryIcon,
  filterIcon,
  genericPlaceIcon,
  hereLogo
} from '@shared/icons';

export interface OmniboxSuggestionsProps {
  query: string;
  isSearching: boolean;
  placesResult: AutosuggestResult;
  routeFilters: string[];
  routeFilterEnabled: boolean;
}

export interface OmniboxSuggestionsDispatch {
  updateQuery(term: string): void;
  selectRouteFilter(text: string[]): void;
  selectPlace(item: AutosuggestItem): void;
}

export interface OmniboxSuggestionsOwnProps {
  isSearching: boolean;
}

type Props = OmniboxSuggestionsProps &
  OmniboxSuggestionsDispatch &
  OmniboxSuggestionsOwnProps;

export function OmniboxSuggestions({
  query,
  placesResult,
  routeFilters,
  routeFilterEnabled,
  updateQuery,
  selectRouteFilter,
  selectPlace,
  isSearching
}: Props) {
  const items = placesResult?.items?.filter(
    (item) => item.mapView || item.position
  );
  const hasPlacesResult = items?.length;

  if (isSearching || !query) {
    return null;
  }

  const selectSuggestion = (item: AutosuggestItem, index: number) => {
    selectPlace(item);
    updateQuery('');
  };

  const addRouteFilter = (routeFilter: string) => {
    const newFilters = [...routeFilters];
    const hasRouteFilter =
      newFilters.indexOf(normalizeFilter(routeFilter)) > -1;

    if (!hasRouteFilter) {
      newFilters.push(routeFilter);

      selectRouteFilter(newFilters);
      updateQuery('');
    }
  };

  const renderPlacesSuggestion = () => (
    <>
      <OverHeading>
        <Translate id="Search.divider" />
      </OverHeading>

      {hasPlacesResult ? items.map(renderSuggestion) : renderNoSuggestions()}
    </>
  );

  const renderNoSuggestions = () =>
    isSearching ? null : (
      <NoResults>
        <Translate id="Search.couldNotFindAnyResults" />
      </NoResults>
    );

  const renderRouteFilterSuggestions = () => {
    if (!routeFilterEnabled) {
      return null;
    }

    return (
      <>
        <OverHeading>
          <Translate id="Filter.divider" />
        </OverHeading>

        {queryLayers([
          getRouteLayerId(normalizeFilter(query), 'DAY'),
          getRouteLayerId(normalizeFilter(query), 'NIGHT')
        ]).length > 0
          ? renderRouteFilterSuggestion()
          : renderRouteNotVisibleOnMap()}
      </>
    );
  };

  const renderRouteFilterSuggestion = () => {
    return routeFilters.indexOf(normalizeFilter(query)) === -1 ? (
      <Suggestion onClick={() => addRouteFilter(query)}>
        <SuggestionIcon>{filterIcon}</SuggestionIcon>
        <SuggestionName>
          <Translate id="Filter.route" /> {normalizeFilter(query)}
        </SuggestionName>
      </Suggestion>
    ) : null;
  };

  const renderRouteNotVisibleOnMap = () => {
    return routeFilters.indexOf(normalizeFilter(query)) === -1 ? (
      <Suggestion onClick={() => addRouteFilter(query)}>
        <SuggestionIcon>{filterIcon}</SuggestionIcon>

        <SuggestionDescription>
          <Translate id="Filter.route" /> "{query}"{' '}
          <Translate id="Filter.info.pre" /> <Translate id="Filter.info.post" />
        </SuggestionDescription>
      </Suggestion>
    ) : null;
  };

  const renderSuggestion = (item: AutosuggestItem, i: number) => {
    return (
      <Suggestion key={item.id} onClick={() => selectSuggestion(item, i)}>
        <SuggestionIcon>{renderFeatureIcon(item)}</SuggestionIcon>
        <SuggestionContent>
          <SuggestionName>{getPlaceHeader(item)}</SuggestionName>
          <SuggestionDescription>
            {getPlaceDescription(item)}
          </SuggestionDescription>
        </SuggestionContent>
      </Suggestion>
    );
  };

  return (
    <SuggestionsPane corners={BOTTOM_CORNERS}>
      {routeFilterEnabled && !isSearching
        ? renderRouteFilterSuggestions()
        : null}

      {!isSearching ? renderPlacesSuggestion() : null}

      <ContentDisclaimer>
        <Translate id="Search.placesVia" /> {hereLogo}
      </ContentDisclaimer>
    </SuggestionsPane>
  );
}

function getPlaceHeader(item: AutosuggestItem): string | undefined {
  const selectTopLevelName = () => {
    switch (item.resultType) {
      case 'locality':
        switch (item.localityType) {
          case 'city':
            return item.address?.city;
          case 'district':
          case 'subdistrict':
            return item.address?.district;
          case 'postalCode':
            return item.address?.postalCode;
          default:
            return item.address?.label;
        }
      case 'administrativeArea':
        switch (item.administrativeAreaType) {
          case 'country':
            return item.address?.countryName;
          case 'county':
            return item.address?.county;
          case 'state':
            return item.address?.state || item.address?.countryName;
          default:
            return item.address?.label;
        }
      case 'street':
        return item.address?.street;
      case 'houseNumber':
        if (item.address?.street && item.address?.houseNumber) {
          return `${item.address.street} ${item.address.houseNumber}`;
        } else {
          return item.address?.label;
        }
      default:
        return item.address?.label;
    }
  };

  return selectTopLevelName() || item.title;
}

function getPlaceDescription(item: AutosuggestItem): string {
  const header = getPlaceHeader(item);

  if (header && item.address?.label) {
    // Ugly, but super effective
    return item.address?.label.replace(header + ', ', '');
  } else if (item.address?.label) {
    return item.address.label;
  }

  return item.title;
}

function renderFeatureIcon(item: AutosuggestItem): JSX.Element {
  if (item.resultType === 'locality') {
    return cityIcon;
  } else if (item.resultType === 'administrativeArea') {
    if (item.administrativeAreaType === 'county') {
      return cityIcon;
    } else {
      return countryIcon;
    }
  }

  return genericPlaceIcon;
}

const ContentDisclaimer = styled.div`
  @keyframes late-fade-in {
    0% {
      transform: scaleY(0.2);
      opacity: 0;
    }
    12% {
      transform: scaleY(1);
    }
    100% {
      transform: scaleY(1);
      opacity: 0.8;
    }
  }

  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-transform: uppercase;
  opacity: ${(p) => p.theme.disabledOpacity};
  background-image: linear-gradient(
    145deg,
    transparent,
    70%,
    ${(p) => p.theme.altBackgroundColor}
  );
  font-family: ${(p) => p.theme.headingFontFamily};
  font-weight: 700;
  font-size: ${px(10)};
  padding: ${px(6, 6, 4, 0)};
  opacity: 0;
  animation: late-fade-in 3s 0.2s ease-out forwards;
  margin-top: -4px;

  svg {
    width: ${px(24)};
    margin-left: ${px(8)};
  }
`;

const SuggestionsPane = styled(FloatingPane)`
  @keyframes suggestions-enter {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }

  ${OverHeading} {
    padding-left: ${px(12)};
  }

  position: absolute;
  margin: 0 6px;
  padding-top: ${px(12)};
  z-index: 2;
  width: calc(100% - 12px);
  transform-origin: top;
  animation: 240ms suggestions-enter ${DECELERATE_EASING};
`;

const Suggestion = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;

  &:active {
    background-color: ${(p) => p.theme.altBackgroundColor};
    color: ${(p) => p.theme.detailColor};
    fill: ${(p) => p.theme.detailColor};

    svg {
      opacity: 1;
    }
  }

  svg {
    opacity: ${(p) => p.theme.dimOpacity};
  }
`;

const SuggestionContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const SuggestionName = styled.div`
  font-family: ${(p) => p.theme.headingFontFamily};
  font-weight: bold;
  font-size: ${px(18)};
`;

const SuggestionDescription = styled.div`
  font-size: ${px(14)};
  opacity: ${(p) => p.theme.disabledOpacity};
  color: ${(p) => p.theme.contentColor};
`;

const SuggestionIcon = styled.div`
  width: ${px(24)};
  height: ${px(24)};
  margin: 0 24px 0 12px;
`;

const NoResults = styled.div`
  text-align: center;
  font-size: ${px(16)};
  padding: ${px(24, 12)};
`;
