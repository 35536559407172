import { connect } from 'react-redux';

import { flyTo } from '@state/actions';

import { Compass } from './Compass';

const mapDispatchToProps = {
  onClick: () => flyTo({ bearing: 0 })
};

export default connect(
  null,
  mapDispatchToProps
)(Compass);
