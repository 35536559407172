import { connect } from 'react-redux';

import { closeMenu } from '@state/actions/ui-actions';
import { AppState } from '@state/app-state';

import { Sidebar, SidebarDispatch, SidebarProps } from 'livemap-ui';

const mapState = (_: AppState, ownProps: SidebarProps): SidebarProps =>
  ownProps;

const mapDispatch: SidebarDispatch = { onClosed: closeMenu };

export default connect(
  mapState,
  mapDispatch
)(Sidebar);
