export interface NativeNotification {
  /**
   * A type (category) describing the notification e.g. 'REPORTING'
   */
  type: string;

  /**
   * The name of the notification, e.g. 'start'
   */
  name: string;
}

// tslint:disable-next-line:no-any
export const isNativeApp = (): boolean => !!(window as any).isInWebView;

export const notifyNativeApp = <T extends NativeNotification>(data: T) =>
  window.postMessage(JSON.stringify(data), '*');
