import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { AppState } from '@state/app-state';

import { Snackbar, SnackbarDispatch, SnackbarProps } from './Snackbar';

const mapState = ({ ui }: AppState): SnackbarProps => ({
  messages: ui.snackbar
});

const mapDispatch = (dispatch: Dispatch): SnackbarDispatch => ({
  dispatch
});

export default connect(
  mapState,
  mapDispatch
)(Snackbar);
