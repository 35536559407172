import { ThemeMode } from 'livemap-ui';

import { AppConfig } from '@core/app-config';

import {
  createEmpty,
  createPayloaded,
  EmptyAction,
  PayloadedAction
} from './app-actions';

export const enum ConfigActionType {
  SET_THEME_MODE = 'CONFIG/SET_THEME',
  APPLY = 'CONFIG/APPLY',
  TOGGLE_NIGHT_MODE = 'CONFIG/TOGGLE_NIGHT_MODE'
}

export type SetThemeModeAction = PayloadedAction<
  ConfigActionType.SET_THEME_MODE,
  ThemeMode
>;

export type ApplyConfigAction = PayloadedAction<
  ConfigActionType.APPLY,
  Partial<AppConfig>
>;

export type ToggleNightModeAction = EmptyAction<
  ConfigActionType.TOGGLE_NIGHT_MODE
>;

export type AnyConfigAction =
  | SetThemeModeAction
  | ApplyConfigAction
  | ToggleNightModeAction;

/**
 * Change the theme mode of the app to the given mode.
 * @param mode `'day'` for day mode `'night'` for night mode.
 */
export const setThemeMode = (mode: ThemeMode): SetThemeModeAction =>
  createPayloaded(ConfigActionType.SET_THEME_MODE, mode);

/**
 * Toggles the current theme mode of the application
 * If the app is in day mode, the app will be put in night mode,
 * and vice-versa.
 */
export const toggleNightMode = (): ToggleNightModeAction =>
  createEmpty(ConfigActionType.TOGGLE_NIGHT_MODE);

/**
 * Apply a configuration patch on the current configuration.
 * @param configPatch A partial configuration object with overrides
 */
export const applyConfig = (
  configPatch: Partial<AppConfig>
): ApplyConfigAction => createPayloaded(ConfigActionType.APPLY, configPatch);
