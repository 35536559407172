import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { localizeReducer } from 'react-localize-redux';
import { combineReducers, Reducer } from 'redux';

import { AnyAppAction } from '../actions/app-actions';
import { AppState } from '../app-state';

import { configReducer } from './config-reducer';
import { feedbackReducer } from './feedback-reducer';
import { mapReducer } from './map-reducer';
import { searchReducer } from './search-reducer';
import { uiReducer } from './ui-reducer';

export function createAppReducer(
  history: History
): Reducer<AppState, AnyAppAction> {
  const routerReducer = connectRouter(history);

  return combineReducers<AppState>({
    config: configReducer,
    feedback: feedbackReducer,
    localize: localizeReducer,
    map: mapReducer,
    search: searchReducer,
    ui: uiReducer,
    router: routerReducer
  });
}
