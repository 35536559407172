import React from 'react';

import { AnyAppAction } from '@state/actions';
import { SnackbarNotice } from '@state/ui-state';

import styled, {
  ACCELERATE_EASING,
  ALL_CORNERS,
  FloatingPane
} from 'livemap-ui';

const FADE_DURATION = 200;

interface SnackCardProps extends SnackbarNotice {
  dispatch(action: AnyAppAction): void;
}

export function SnackCard({
  duration,
  dispatch,
  text,
  action,
  actionText = 'OK'
}: SnackCardProps) {
  const style = {
    animationDelay: `${duration - FADE_DURATION}ms`
  };

  return (
    <Card style={style} corners={ALL_CORNERS}>
      <CardText>{text}</CardText>
      {action ? (
        <CardAction onClick={() => dispatch(action)}>{actionText}</CardAction>
      ) : null}
    </Card>
  );
}

const Card = styled(FloatingPane)`
  @keyframes fade-out {
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(0);
    }
  }

  align-items: center;
  flex: 0 1 auto;
  background-color: ${p => p.theme.contentColor};
  color: ${p => p.theme.backgroundColor};
  margin-left: auto;
  padding: 18px 24px;
  transform-origin: bottom right;
  animation: fade-out ${FADE_DURATION}ms ${ACCELERATE_EASING};
  animation-fill-mode: forwards;
  display: flex;
  user-select: none;
`;

const CardText = styled.span``;

const CardAction = styled.a`
  cursor: pointer;
  color: ${p => p.theme.detailColor};
  font-family: ${p => p.theme.headingFontFamily};
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 18px;
`;
