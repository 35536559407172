import { FontDefinition, VehicleSelectionStyle } from 'livemap-gl';
import { DEFAULT_DAY_THEME, DEFAULT_NIGHT_THEME } from 'livemap-ui';

import { AppConfig, GeolocationAction, mergeConfig } from '@core/app-config';

import { AnyAppAction } from '../actions/app-actions';
import { ConfigActionType } from '../actions/config-actions';

export function configReducer(
  state: AppConfig = defaultConfig,
  action: AnyAppAction
): AppConfig {
  switch (action.type) {
    case ConfigActionType.APPLY:
      return mergeConfig(defaultConfig, action.payload);
    case ConfigActionType.TOGGLE_NIGHT_MODE:
      return {
        ...state,
        themeMode: state.themeMode === 'day' ? 'night' : 'day'
      };
    case ConfigActionType.SET_THEME_MODE:
      return { ...state, themeMode: action.payload };
    default:
      return state;
  }
}

const badgeFontFamily = '"Barlow Semi Condensed", "Roboto", Arial, sans-serif';

const badgeFont: FontDefinition = {
  family: badgeFontFamily,
  weight: 500,
  size: 14
};

const defaultConfig: AppConfig = {
  host: '/v3',
  apiKey: null,
  hereApiKey: null,
  enableRouteFilter: false,
  availability: {},
  language: { code: 'en' },
  brand: {},
  vehicles: {
    positionEasing: 0.05,
    headingEasing: 0.1,
    sideMenuVisibleCollapsed: 5,
    followVehicle: true,
    available: [
      'bus',
      'subway',
      'rail',
      'tram',
      'ferry',
      'cableCar',
      'gondola',
      'funicular',
      'aircraft',
      'autonomous',
      'taxi',
      'animal',
      'miscellaneous'
    ]
  },
  components: {
    attribution: { enabled: true },
    lineGraphs: { enabled: false },
    layerSelector: { enabled: false },
    search: { enabled: true },
    vehicleStats: { enabled: false },
    notice: {
      url: './NOTICE.txt'
    },
    menu: {
      enabled: true,
      feedback: true,
      perspectiveView: true,
      nightMode: true,
      privacy: true,
      versionNumber: true,
      vehicleFilters: true,
      customLinks: []
    },
    geolocation: {
      enabled: true,
      action: GeolocationAction.GEOLOCATE
    },
    mapControls: {
      zoom: true,
      compass: true,
      fullscreen: true
    },
    map: {
      panning: true,
      start: null,
      fallbackCamera: null,
      oneClickFilter: {
        enabled: false
      }
    },
    tripInfo: {
      enabled: true,
      useRouteColor: true
    },
    stopInfo: {
      showTimeZoneBanner: true
    }
  },
  themeMode: 'day',
  themes: {
    day: {
      ui: { ...DEFAULT_DAY_THEME, scale: 1 },
      tiles: {
        tileURL: 'https://tile.livemap24.com/styles/style-light-vector.json',
        tileLinesURL:
          'https://tile.livemap24.com/styles/style-light-lines-vector.json',
        backgroundColor: '#C4DFF6',
        linesURL: 'https://tile.livemap24.com/shapes/{z}/{x}/{y}.pbf',
        attribution: '© Veridict © OpenStreetMap contributors'
      },
      vehicleColors: {
        tram: '#DCD318',
        subway: '#187ADC',
        rail: '#18DC6A',
        bus: '#DC1846',
        ferry: '#96AEB0',
        cableCar: '#DC189B',
        gondola: '#DC189B',
        funicular: '#DC189B',
        aircraft: '#6D18DC',
        autonomous: '#105192',
        taxi: '#DCD318',
        animal: '#A25900',
        miscellaneous: '#105192'
      },
      livemap: {
        watermark: {
          visible: true,
          color: '#424242'
        },
        stopBadge: {
          stopBadgeStyle: {
            font: badgeFont,
            departureFont: badgeFont,
            fillColor: '#ffffff'
          }
        },
        vehicleBadge: {
          delayBadgeStyle: {
            onTimeBias: 60000,
            font: badgeFont
          },
          vehicleBadgeStyle: {
            nameFont: badgeFont,
            destFont: badgeFont,
            metaFont: badgeFont,
            fillColor: '#ffffff'
          },
          levels: {
            small: 13.5,
            medium: 14.5,
            large: 16.0
          }
        },
        route: {
          color: '#2196F3',
          colorPast: '#757575',
          glow: 0.1,
          segmentLength: 3200,
          useTripColor: true,
          lineType: 0,
          lineWidth: 8
        },
        vehicles: {
          useTripBgColor: false,
          vehicleSelectionStyle: VehicleSelectionStyle.CIRCLE,
          selectionGlowColor: '#2196F3',
          selectionUseTripColor: false
        }
      }
    },
    night: {
      ui: { ...DEFAULT_NIGHT_THEME, scale: 1 },
      tiles: {
        tileURL: 'https://tile.livemap24.com/styles/style-dark-vector.json',
        tileLinesURL:
          'https://tile.livemap24.com/styles/style-dark-lines-vector.json',
        backgroundColor: '#191919',
        linesURL: 'https://tile.livemap24.com/shapes/{z}/{x}/{y}.pbf',
        attribution: '© Veridict © OpenStreetMap contributors'
      },
      vehicleColors: {
        tram: '#DCD318',
        subway: '#187ADC',
        rail: '#18DC6A',
        bus: '#DC1846',
        ferry: '#96AEB0',
        cableCar: '#DC189B',
        gondola: '#DC189B',
        funicular: '#DC189B',
        aircraft: '#6D18DC',
        autonomous: '#105192',
        taxi: '#DCD318',
        animal: '#A25900',
        miscellaneous: '#105192'
      },
      livemap: {
        watermark: {
          visible: true,
          color: '#eee'
        },
        route: {
          color: '#2196F3',
          colorPast: '#757575',
          glow: 0.1,
          segmentLength: 3200,
          useTripColor: true,
          lineType: 0,
          lineWidth: 8
        },
        stopBadge: {
          stopBadgeStyle: {
            font: badgeFont,
            departureFont: badgeFont,
            fillColor: '#212121',
            borderColor: '#191919',
            textColor: '#ffffff'
          }
        },
        vehicleBadge: {
          delayBadgeStyle: {
            onTimeBias: 60000,
            font: badgeFont,
            earlyFillColor: '#1976D2',
            earlyBorderColor: '#1565C0',
            lateFillColor: '#D32F2F',
            lateBorderColor: '#C62828',
            onTimeFillColor: '#212121',
            onTimeBorderColor: '#191919',
            onTimeTextColor: '#dddddd'
          },
          vehicleBadgeStyle: {
            nameFont: badgeFont,
            destFont: badgeFont,
            metaFont: badgeFont,
            textColor: '#ffffff',
            fillColor: '#212121',
            borderColor: '#191919',
            metaFillColor: '#212121',
            metaBorderColor: '#191919',
            metaTextColor: '#ddd'
          },
          levels: {
            small: 13.5,
            medium: 14.5,
            large: 16.0
          }
        },
        vehicles: {
          useTripBgColor: false,
          vehicleSelectionStyle: VehicleSelectionStyle.CIRCLE,
          selectionGlowColor: '#2196F3',
          selectionUseTripColor: false
        }
      }
    }
  },
  userPositionTracking: {
    enabled: false,
    endpoint: undefined
  },
  toxel: {
    depth: 11,
    stopMinutes: 10
  },
  keybindings: null
};
