import { closeNotice } from '@state/actions';
import { AppState } from '@state/app-state';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Notice, NoticeDispatch, NoticeProps } from './Notice';

const mapStateToProps = (state: AppState): NoticeProps => ({
  url: state.config.components.notice.url,
  isVisible: state.ui.isNoticeVisible
});

const mapDispatchToProps = (dispatch: Dispatch): NoticeDispatch => ({
  onClose: () => dispatch(closeNotice())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notice);
