import { NUMBER_OF_VEHICLE_TYPES, VehicleType } from 'livemap-gl';

import { RouteLayerStyle } from '@core/map/layers';
import { Following } from '@core/map/position';
import { generateShortId } from '@core/short-id';

import { AnyAppAction } from '../actions/app-actions';
import { MapActionType } from '../actions/map-actions';
import { MapState } from '../map-state';

const initialLayerStyle = RouteLayerStyle.NONE;

export const getAllVehicleTypes = (): VehicleType[] => {
  const types = [];

  for (let i = 0; i < NUMBER_OF_VEHICLE_TYPES; i++) {
    types.push(i);
  }

  return types;
};

export const initialMapState: MapState = {
  sessionId: generateShortId(),
  time: Date.now(),
  ready: false,
  trip: null,
  selectedVehicle: null,
  selectedStop: null,
  timescale: 1,
  visibleVehiclesTypes: getAllVehicleTypes(),
  following: Following.NOTHING,
  isPerspectiveViewAllowed: false,
  userLocation: null,
  isGeolocationEnabled: false,
  routeLayerStyle: initialLayerStyle,
  fitOnTripData: false,
  routeFilters: [],
  foundStops: [],
  cameraOffset: [0, 0]
};

export function mapReducer(
  state: MapState = initialMapState,
  action: AnyAppAction
): MapState {
  switch (action.type) {
    case MapActionType.SET_CAMERA_OFFSET:
      return { ...state, cameraOffset: action.payload };
    case MapActionType.TOGGLE_PERSPECTIVE_VIEW_ALLOWED:
      return {
        ...state,
        isPerspectiveViewAllowed: !state.isPerspectiveViewAllowed
      };
    case MapActionType.FOLLOW:
      return { ...state, following: action.payload };
    case MapActionType.SET_ROUTE_LAYER_STYLE:
      return { ...state, routeLayerStyle: action.payload };
    case MapActionType.TOGGLE_ROUTE_LAYER_STYLE:
      return {
        ...state,
        routeLayerStyle:
          state.routeLayerStyle !== RouteLayerStyle.NONE
            ? RouteLayerStyle.NONE
            : RouteLayerStyle.LINES
      };
    case MapActionType.SET_GEOLOCATION_ENABLED:
      return { ...state, isGeolocationEnabled: action.payload };
    case MapActionType.IS_READY:
      return { ...state, ready: true };
    case MapActionType.SET_MAP_TIMESCALE:
      return { ...state, timescale: action.payload };
    case MapActionType.STOP_SELECTED:
      return { ...state, selectedStop: action.payload };
    case MapActionType.TIME_CHANGED:
      return { ...state, time: action.payload };
    case MapActionType.TRIP_LOADED:
      return { ...state, trip: action.payload };
    case MapActionType.USER_MOVED:
      return { ...state, userLocation: action.payload };
    case MapActionType.STOP_CLEARED:
      return { ...state, selectedStop: null };
    case MapActionType.VEHICLE_SELECTED:
      return { ...state, selectedVehicle: action.payload };
    case MapActionType.VEHICLE_CLEARED:
      return {
        ...state,
        selectedVehicle: null,
        trip: null
      };
    case MapActionType.TOGGLE_DISPLAY_VEHICLE:
      const contains = state.visibleVehiclesTypes.indexOf(action.payload) > -1;

      if (contains) {
        return {
          ...state,
          visibleVehiclesTypes: state.visibleVehiclesTypes.filter(
            type => type !== action.payload
          )
        };
      }

      return {
        ...state,
        visibleVehiclesTypes: state.visibleVehiclesTypes.concat([
          action.payload
        ])
      };
    case MapActionType.SET_ROUTE_FILTERS:
      return { ...state, routeFilters: action.payload };
    case MapActionType.FOUND_STOPS:
      return { ...state, foundStops: action.payload };
    default:
      return state;
  }
}
