import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { setIsFullscreen } from '@state/actions';
import { AppState } from '@state/app-state';

import {
  FullscreenButton,
  FullscreenButtonDispatch,
  FullscreenButtonProps
} from './FullscreenButton';

const mapState = (state: AppState): FullscreenButtonProps => ({
  isFullscreen: state.ui.isFullscreen
});

const mapDispatch = (dispatch: Dispatch): FullscreenButtonDispatch => ({
  setFullscreen(isFullscreen: boolean): void {
    dispatch(setIsFullscreen(isFullscreen));
  }
});

/** Container component for Sidebar */
export default connect(
  mapState,
  mapDispatch
)(FullscreenButton);
