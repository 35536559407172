import React, { ReactNode } from 'react';

import styled from 'livemap-ui';

interface TextInputDispatch {
  onTextChange(text: string): void;
}

interface TextInputProps {
  label: ReactNode;
  value: string;
  placeholder?: string;
  type?: 'text' | 'email' | 'password';
}

type Props = TextInputDispatch & TextInputProps;

export function TextInput({
  onTextChange,
  label,
  placeholder,
  ...inputProps
}: Props) {
  return (
    <Label>
      {label}
      <Input
        {...inputProps}
        placeholder={(placeholder || '').toString()}
        onChange={e => onTextChange(e.target.value)}
      />
    </Label>
  );
}

const Label = styled.label`
  display: block;
  position: relative;
  width: 100%;
  margin-bottom: 18px;
  font-size: 16px;
  text-transform: uppercase;
  font-family: ${p => p.theme.headingFontFamily};
  font-weight: 500;
`;

const Input = styled.input`
  margin-top: 8px;
  line-height: 1;
  display: block;
  padding: 12px 12px 16px;
  width: 100%;
  font-size: 20px;
  border: 1px solid ${p => p.theme.borderColor};
  background-color: ${p => p.theme.altBackgroundColor};
  font-family: Barlow, Roboto, sans-serif;
  font-weight: 400;
  transition: all 0.2s ease-in;
  outline: none;

  &:focus {
    outline: 1px solid ${p => p.theme.detailColor};
  }
`;
