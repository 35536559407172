const characters =
  '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

const randomCharIndex = () => Math.floor(Math.random() * characters.length);
const getRandomChar = () => characters[randomCharIndex()];

export const DEFAULT_SHORT_ID_LENGTH = 8;

/** Generates a random, 8 character long alphanumeric identifier */
export function generateShortId(length: number = DEFAULT_SHORT_ID_LENGTH) {
  let result = '';

  for (let i = 0; i < length; i++) {
    result += getRandomChar();
  }

  return result;
}
