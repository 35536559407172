import 'ctx-polyfill';
import './polyfills';

import React from 'react';

import { ConnectedRouter } from 'connected-react-router';
import { createHashHistory } from 'history';
import { render } from 'react-dom';
import { LocalizeProvider } from 'react-localize-redux';
import { Provider } from 'react-redux';

import { AppConfig, loadClientConfig } from '@core/app-config';
import { isBrowserSupported } from '@core/browser';
import {
  getAvailabilityAction,
  getDeviceType,
  hasDesktopLayout
} from '@core/device';
import {
  clearFullscreenStyle,
  isElementFullscreen,
  setFullscreenStyle
} from '@core/element';

import * as here from '@core/here';
import { bindActions } from '@core/keyboard';
import { isNativeApp } from '@core/native-app';
import { loadAcceptedGeolocation } from '@core/storage';
import { debounce } from '@core/throttling';

import {
  applyConfig,
  setGeolocationEnabled,
  setHasDesktopLayout,
  setIsStandalone
} from '@state/actions';
import { createAppStore } from '@state/app-store';

import appPkg from '../package.json';

import AppContainer from './AppContainer';

const history = createHashHistory();
const store = createAppStore(history);
const deviceType = getDeviceType();

let isStarted = false;

store.dispatch(
  setGeolocationEnabled(loadAcceptedGeolocation() || isNativeApp())
);

function updateLayoutType() {
  const value = hasDesktopLayout(deviceType);

  store.dispatch(setHasDesktopLayout(value));
}

function fullscreenListener(rootElement: HTMLElement) {
  let wasFullscreen: boolean | null = null;

  store.subscribe(() => {
    const { ui } = store.getState();

    if (ui.isFullscreen !== wasFullscreen) {
      if (ui.isFullscreen) {
        setFullscreenStyle(rootElement);
      } else {
        clearFullscreenStyle(rootElement);
      }

      wasFullscreen = ui.isFullscreen;
    }
  });
}

function configureApp(clientConfig: Partial<AppConfig> | null): void {
  if (clientConfig) {
    store.dispatch(applyConfig(clientConfig));
  }

  const config = store.getState().config;

  if (config.keybindings) {
    bindActions(store.dispatch, config.keybindings);
  }

  if (config.hereApiKey) {
    here.authenticate(config.hereApiKey);
  }

  const availabilityAction = getAvailabilityAction(
    deviceType,
    config.availability
  );

  if (availabilityAction) {
    availabilityAction();
  }
}

function loadApp(
  rootId: string = 'traze-root',
  configURL: string = './traze.config.json'
) {
  if (isStarted) {
    throw new Error('Traze has already been started.');
  } else if (!isBrowserSupported()) {
    throw new Error('Traze is not supported by this browser.');
  }

  const rootElement = document.getElementById(rootId);

  if (!rootElement) {
    throw new Error(`An element with the id '${rootId}' does not exist`);
  }

  loadClientConfig(configURL).then((clientConfig) => {
    configureApp(clientConfig);

    if (isElementFullscreen(rootElement)) {
      store.dispatch(setIsStandalone(true));
    } else {
      fullscreenListener(rootElement);
    }

    render(
      <Provider store={store}>
        <LocalizeProvider>
          <ConnectedRouter history={history}>
            <AppContainer />
          </ConnectedRouter>
        </LocalizeProvider>
      </Provider>,
      rootElement
    );

    isStarted = true;
  });
}

updateLayoutType();

window.addEventListener('resize', debounce(updateLayoutType, 500));

declare global {
  interface Window {
    TRAZE_VERSION: string;
    isTrazeSupported(): boolean;
    loadTraze(rootId?: string, configURL?: string): void;
  }
}

window.TRAZE_VERSION = appPkg.version;
window.loadTraze = loadApp;
window.isTrazeSupported = isBrowserSupported;
