import { VehicleType } from 'livemap-gl';

const vehicleTranslateKeys = {
  [VehicleType.TRAM]: 'tram',
  [VehicleType.SUBWAY]: 'subway',
  [VehicleType.RAIL]: 'rail',
  [VehicleType.BUS]: 'bus',
  [VehicleType.FERRY]: 'ferry',
  [VehicleType.TAXI]: 'taxi',
  [VehicleType.CABLE_CAR]: 'cableCar',
  [VehicleType.GONDOLA]: 'gondola',
  [VehicleType.FUNICULAR]: 'funicular',
  [VehicleType.AIRCRAFT]: 'aircraft',
  [VehicleType.AUTONOMOUS]: 'autonomous',
  [VehicleType.ANIMAL]: 'animal',
  [VehicleType.MISCELLANEOUS]: 'miscellaneous'
};

export const getPluralizedVehicleTranslateKey = (type: VehicleType) =>
  'vehicles.' + vehicleTranslateKeys[type] + '.plural';
