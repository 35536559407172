import React from 'react';
import { Translate } from 'react-localize-redux';

import styled, { STANDARD_EASING } from 'livemap-ui';

import { FeedbackCategory } from '@core/feedback';

import { likeIcon, problemIcon, suggestionIcon } from '@shared/icons';

export interface CategorySelectorDispatch {
  onSelect(category: FeedbackCategory): void;
}

type Props = CategorySelectorDispatch;

export function CategorySelector({ onSelect }: Props) {
  return (
    <>
      <Category onClick={() => onSelect('problem')}>
        <CategoryLabel>
          <Translate id="Feedback.reportProblem" />
        </CategoryLabel>
        <CategoryIcon>{problemIcon}</CategoryIcon>
      </Category>

      <Category onClick={() => onSelect('suggestion')}>
        <CategoryLabel>
          <Translate id="Feedback.reportSuggestion" />
        </CategoryLabel>
        <CategoryIcon>{suggestionIcon}</CategoryIcon>
      </Category>

      <Category onClick={() => onSelect('like')}>
        <CategoryLabel>
          <Translate id="Feedback.likeApp" />
        </CategoryLabel>
        <CategoryIcon>{likeIcon}</CategoryIcon>
      </Category>
    </>
  );
}

const Category = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 0;
  margin: 6px 0;
  cursor: pointer;
  border: 1px solid ${p => p.theme.borderColor};
  border-radius: ${p => p.theme.borderRadius}px;
  fill: ${p => p.theme.detailColor};

  &:hover,
  &:active {
    transition: all 120ms ${STANDARD_EASING};
    background-color: ${p => p.theme.detailColor};
    color: ${p => p.theme.backgroundColor};
    fill: ${p => p.theme.backgroundColor};
  }
`;

const CategoryIcon = styled.figure`
  height: 32px;
  width: 32px;
  margin: 0 12px;
  margin-left: auto;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const CategoryLabel = styled.div`
  margin-left: 24px;
  font-size: 18px;
  user-select: none;
`;
