import React from 'react';

import { Trip, Vehicle } from 'livemap-gl';
import {
  BottomSheetSize,
  millis,
  ScrollPane,
  TripHeader,
  UTCOffset,
  VehicleIcon
} from 'livemap-ui';
import { LocalizeContextProps, Translate } from 'react-localize-redux';
import { RouteComponentProps } from 'react-router';

import { ColorTheme, getVehicleTypeKey } from '@core/app-config';
import { DelayStatus, getDelayStatus, getNextArrivalDelay } from '@core/delay';

import {
  PaddedSheetContent,
  SheetContent,
  SheetHeader
} from '@shared/bottom-sheet';

import { TimeZoneBanner } from './components/TimeZoneBanner';
import { TripContent } from './components/TripContent';

import InfoSheet from '../../components/InfoSheet/InfoSheetContainer';

export interface TripInfoProps {
  time: number;
  trip: Trip | null;
  vehicle: Vehicle | null;
  mapReady: boolean;
  theme: ColorTheme;
  useLineColor: boolean;
}

export interface TripInfoDispatch {
  onClickHeader?(): void;
}

type Props = TripInfoDispatch &
  TripInfoProps &
  RouteComponentProps &
  LocalizeContextProps;

const renderLateArrival = (delay: number) => {
  const minutes = Math.round(delay / 60000);
  const translateKey =
    minutes > 1 ? 'TripInfo.minutePlural' : 'TripInfo.minuteSingular';

  return (
    <span className="arrivalDelay late">
      {minutes} <Translate id={translateKey} />{' '}
      <Translate id="TripInfo.lateToNextStop" />
    </span>
  );
};

const renderEarlyArrival = (delay: number) => {
  const minutes = Math.round(delay / -60000);
  const translateKey =
    minutes > 1 ? 'TripInfo.minutePlural' : 'TripInfo.minuteSingular';

  return (
    <span className="arrivalDelay early">
      {minutes} <Translate id={translateKey} />{' '}
      <Translate id="TripInfo.earlyToNextStop" />
    </span>
  );
};

const renderOnTimeArrival = () => (
  <span className="arrivalDelay onTime">
    <Translate id="TripInfo.onTimeToNextStop" />
  </span>
);

const renderNextArrivalDelay = (trip: Trip, timestamp: number) => {
  if (!trip.hasDelayModel) {
    return (
      <span className="arrivalDelay unsure">
        <Translate id="TripInfo.delayDataMissing" />
      </span>
    );
  }

  const delay = getNextArrivalDelay(trip.stops, timestamp);

  switch (getDelayStatus(delay)) {
    case DelayStatus.LATE:
      return renderLateArrival(delay);
    case DelayStatus.EARLY:
      return renderEarlyArrival(delay);
    case DelayStatus.ON_TIME:
      return renderOnTimeArrival();
    case DelayStatus.UNKNOWN:
    default:
      return null;
  }
};

function getTowards(trip: Trip) {
  if (trip.headsign) {
    return trip.headsign;
  } else if (trip.stops && trip.stops.length) {
    const lastStop = trip.stops[trip.stops.length - 1];

    return lastStop.headsign || lastStop.location.name;
  }
  return '';
}

export function TripInfo({ trip, time, useLineColor, onClickHeader }: Props) {
  if (!trip) {
    return null;
  }

  const lineColor =
    useLineColor && trip.bgColor !== 'FFFFFF' ? '#' + trip.bgColor : undefined;

  const isLocalTimeZone = -UTCOffset === millis(trip.utcOffset, 0, 0);

  if (!trip.id) {
    return (
      <InfoSheet size={BottomSheetSize.MINIMIZED}>
        <SheetHeader>Could not fetch data</SheetHeader>
      </InfoSheet>
    );
  }

  return (
    <InfoSheet size={BottomSheetSize.MINIMIZED}>
      <SheetHeader onClick={onClickHeader}>
        <SheetContent>
          <TripHeader
            lineColor={lineColor}
            name={trip.shortName}
            towards={getTowards(trip)}
            vehicleIcon={getVehicleTypeKey(trip.vehicleType) as VehicleIcon}
            subTitle={renderNextArrivalDelay(trip, time)}
          />
        </SheetContent>
        {isLocalTimeZone ? null : (
          <TimeZoneBanner utcTime={time} utcOffset={trip.utcOffset} />
        )}
      </SheetHeader>

      <ScrollPane>
        <PaddedSheetContent>
          <TripContent trip={trip} time={time} lineColor={lineColor} />
        </PaddedSheetContent>
      </ScrollPane>
    </InfoSheet>
  );
}
