import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { AnyAppAction } from './actions';
import { AppState } from './app-state';
import { createAppReducer } from './reducers';
import { appSaga } from './sagas';

export type AppStore = Store<AppState, AnyAppAction>;

export function createAppStore(history: History): AppStore {
  const sagaMiddleware = createSagaMiddleware();

  // tslint:disable
  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
  // tslint:enable

  const enhancers = composeEnhancers(
    applyMiddleware(routerMiddleware(history), sagaMiddleware)
  );

  const store = createStore(createAppReducer(history), enhancers);

  sagaMiddleware.run(appSaga);

  return store;
}
