import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { AutosuggestItem } from '@core/here';

import {
  selectAutosuggestItem,
  setRouteFilters,
  setSearchQuery
} from '@state/actions';
import { AppState } from '@state/app-state';

import {
  OmniboxSuggestions,
  OmniboxSuggestionsDispatch,
  OmniboxSuggestionsOwnProps,
  OmniboxSuggestionsProps
} from './OmniboxSuggestions';

const mapState = (
  state: AppState,
  ownProps: OmniboxSuggestionsOwnProps
): OmniboxSuggestionsProps & OmniboxSuggestionsOwnProps => ({
  query: state.search.query,
  placesResult: state.search.result,
  routeFilters: state.map.routeFilters,
  isSearching: ownProps.isSearching,
  routeFilterEnabled: state.config.enableRouteFilter
});

const mapDispatch = (dispatch: Dispatch): OmniboxSuggestionsDispatch => ({
  selectPlace: (item: AutosuggestItem) => dispatch(selectAutosuggestItem(item)),
  selectRouteFilter: (text: string[]) => dispatch(setRouteFilters(text)),
  updateQuery: (query: string) => dispatch(setSearchQuery(query))
});

export default connect(
  mapState,
  mapDispatch
)(OmniboxSuggestions);
