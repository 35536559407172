import { CameraOptions } from 'mapbox-gl';

export function loadCamera(): CameraOptions {
  const json = localStorage.getItem('camera');

  return json ? JSON.parse(json) : null;
}

export function saveCamera(camera: CameraOptions | null) {
  localStorage.setItem('camera', JSON.stringify(camera));
}

export function loadAcceptedGeolocation(): boolean {
  return !!localStorage.getItem('accepted-geolocation');
}

export function saveAcceptedGeolocation(state: boolean): void {
  localStorage.setItem('accepted-geolocation', state ? 'true' : 'false');
}
