import { GeoCoordinate } from 'livemap-gl';

export const ROUTE_FILTER_PREFIX_DEFAULT = 'line-search-default';
export const ROUTE_FILTER_PREFIX_WHITE = 'line-search-white';

export interface TrackingMessage {
  sessionID: string;
  latitude: number;
  longitude: number;
  timestamp: number;
  accuracy: number;
}

function composeMessage(
  { latitude, longitude }: GeoCoordinate,
  sessionId: string
): TrackingMessage {
  return {
    latitude,
    longitude,
    timestamp: Date.now(),
    sessionID: sessionId,
    accuracy: 1
  };
}

export async function sendCoordinate(
  url: string,
  sessionId: string,
  position: GeoCoordinate
): Promise<void> {
  if (!url) {
    return;
  }

  const body = JSON.stringify(composeMessage(position, sessionId));

  await fetch(url, { method: 'POST', mode: 'no-cors', body });
}
