import styled, { STANDARD_EASING } from 'livemap-ui';

interface Props {
  checked: boolean;
  backgroundColor?: string;
}

export const Checkbox = styled.div<Props>`
  width: 24px;
  height: 24px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${p =>
    p.checked ? p.backgroundColor || p.theme.contentColor : 'transparent'};
  border: 1px solid ${p => (!p.checked ? p.theme.borderColor : 'transparent')};
  transition: all 400ms ${STANDARD_EASING};

  &::after {
    content: '';
    margin-top: -2px;
    transition: all 300ms ${STANDARD_EASING};
    width: 4px;
    height: 8px;
    border: solid ${p => p.theme.backgroundColor};
    border-width: 0 2px 2px 0;
    opacity: ${p => (p.checked ? '1' : '0.2')};
    transform: ${p =>
      p.checked ? 'rotate(45deg) scale(1)' : 'rotate(0) scale(0)'};
  }
`;
