import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { AppState } from '@state/app-state';

import { App, AppProps } from './App';

const mapState = ({ config }: AppState): AppProps => ({ config });

export default withLocalize(withRouter(connect(mapState)(App)));
