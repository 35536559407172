import { AnyLayer } from 'mapbox-gl';

import { areMapAPIsBound, callMapbox, selectFromMapbox } from './bindings';

export const enum RouteLayerStyle {
  NONE = 'none',
  LINES = 'lines',
  TYPES = 'types'
}

let activeLineLayers: string[] = [];

export const setRouteLayer = (layer: AnyLayer, sourceUrl: string) => {
  removeLayer(layer.id);

  if (!areMapAPIsBound()) {
    return;
  }

  callMapbox((mb) => {
    if (activeLineLayers.length <= 0 && mb.getSource('linessearch')) {
      mb.removeSource('linessearch');
    }

    if (layer) {
      if (!mb.getSource('linessearch')) {
        mb.addSource('linessearch', {
          type: 'vector',
          tiles: [sourceUrl],
          maxzoom: 12
        });
      }
      activeLineLayers.push(layer.id);

      mb.addLayer(layer);
    }
  });
};

export const removeRouteLayers = () =>
  activeLineLayers.forEach((l) => removeLayer(l));

export const removeLayer = (id: string) => {
  if (!areMapAPIsBound()) {
    return;
  }

  callMapbox((mb) => {
    if (!mb.getLayer(id)) {
      return;
    }

    activeLineLayers = activeLineLayers.filter((o) => o !== id);
    mb.removeLayer(id);
  });
};

export const getRouteLayers = (routeId: string) => {
  const layer = {
    id: getRouteLayerId(routeId, 'DAY'),
    type: 'line' as 'line',
    source: 'linessearch',
    'source-layer': 'routes',
    filter: [
      'all',
      ['==', routeId, ['get', 'route_short_name']],
      ['!=', '#FFFFFF', ['get', 'route_color']]
    ],
    layout: {
      'line-join': 'round' as 'round',
      'line-cap': 'round' as 'round'
    },
    paint: {
      'line-color': {
        type: 'identity' as 'identity',
        property: 'route_color'
      },
      'line-opacity': 1,
      'line-width': {
        stops: [
          [0, 0.5],
          [8, 1],
          [10, 2],
          [12, 3],
          [14, 5]
        ]
      }
    }
  };
  const layerWhite = {
    id: getRouteLayerId(routeId, 'NIGHT'),
    type: 'line' as 'line',
    source: 'linessearch',
    'source-layer': 'routes',
    filter: [
      'all',
      ['==', routeId, ['get', 'route_short_name']],
      ['==', '#FFFFFF', ['get', 'route_color']]
    ],
    layout: {
      'line-join': 'round' as 'round',
      'line-cap': 'round' as 'round'
    },
    paint: {
      'line-color': '#0091EA',
      'line-opacity': 1,
      'line-width': {
        stops: [
          [0, 0.5],
          [8, 1],
          [10, 2],
          [12, 3],
          [14, 5]
        ]
      }
    }
  };
  return { light: layer, dark: layerWhite };
};

export function queryLayers(layers: string[]) {
  const active = layers.filter((i) => activeLineLayers.indexOf(i) > -1);

  if (!active || !active.length) {
    return [];
  }

  return selectFromMapbox(
    (mb) => mb.queryRenderedFeatures(undefined, { layers: active }),
    []
  );
}

export const getRouteLayerId = (routeId: string, theme: 'DAY' | 'NIGHT') =>
  theme === 'DAY'
    ? ROUTE_FILTER_PREFIX_DAY + routeId
    : ROUTE_FILTER_PREFIX_NIGHT + routeId;

export const ROUTE_FILTER_PREFIX_DAY = 'line-search-default';
export const ROUTE_FILTER_PREFIX_NIGHT = 'line-search-white';
