import { connect } from 'react-redux';

import { getSelectedTheme } from '@core/app-config';

import { AppState } from '@state/app-state';
import { VehicleStats, VehicleStatsProps } from './VehicleStats';

const mapState = ({ config, ui }: AppState): VehicleStatsProps => ({
  isNightMode: config.themeMode === 'night',
  theme: getSelectedTheme(config),
  isVisible: ui.isVehicleStatsVisible,
  hasMenu: config.components.menu.enabled
});

export default connect(mapState)(VehicleStats);
