import { AvailabilityConfig } from './app-config';

/** The devices which are recognized by Traze. */
export type DeviceType =
  | 'desktop'
  | 'mobile-web-android'
  | 'mobile-web-ios'
  | 'mobile-web-unknown'
  | 'mobile-native-android'
  | 'mobile-native-ios';

export const isDesktop = (device: DeviceType) => device === 'desktop';

export const hasDesktopLayout = (device: DeviceType) =>
  isDesktop(device) || isPossiblyTablet(device);

export const isMobile = (device: DeviceType) => device.indexOf('mobile') === 0;

export const isNativeApp = (device: DeviceType) =>
  device.indexOf('native') > -1;

export const isPossiblyTablet = (device: DeviceType) =>
  isMobile(device) && window.innerWidth > 440;

function getMobileDeviceType(): DeviceType | null {
  const isAndroid = /android/gi.test(window.navigator.userAgent);
  const isIOS = /(iphone|ipad)/gi.test(window.navigator.userAgent);
  const isInWebView =
    'ReactNativeWebView' in window || // react-native-webview version > 5.0.0
    'ReactNativeWebview' in window || // react-native-webview version = 5.0.0 (typo)
    'isInWebView' in window; // before react-native-community webview, this is injected at start

  if (isInWebView) {
    if (isAndroid) {
      return 'mobile-native-android';
    } else if (isIOS) {
      return 'mobile-native-ios';
    }
  }

  if (isAndroid) {
    return 'mobile-web-android';
  } else if (isIOS) {
    return 'mobile-web-ios';
  }

  return null;
}

export function getDeviceType(): DeviceType {
  return getMobileDeviceType() || 'desktop';
}

/**
 * Gets the action associated with the device's availability configuration.
 *
 * For instance: If the device should be redirected;
 * returns a function, which when called, redirects the user to the configured URL.
 *
 * If the application is available for the device, or if no special considerations
 * have to be made &mdash; returns `null`
 *
 * @param config The availability configuration
 * @param device The type of device currently using the application
 */
export function getAvailabilityAction(
  device: DeviceType,
  config: AvailabilityConfig | undefined | null
): VoidFunction | null {
  if (!config) {
    return null;
  }

  const availability = config[device];

  if (availability && availability.redirectURL) {
    return () => window.location.replace(availability.redirectURL);
  } else {
    // The absence of an availability configuration,
    // means that the app is availability for the device
    // and that no special considerations have to be made.
    return null;
  }
}
