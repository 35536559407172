/**
 * Traverses the parents of the element, attempting to find a parent which is scrollable.
 * If the element is not found, or if `null` is provided, `null` is returned.
 */
export function findScrollParent(element?: Element | null): Element | null {
  if (!element) {
    return null;
  }

  const parent = element.parentElement;

  if (!parent) {
    return null;
  }

  if (parent.scrollHeight > parent.clientHeight) {
    return parent;
  }

  return findScrollParent(parent);
}

/**
 * Determines whether the provided element occupies
 * the entire inner width and inner height of the window.
 */
export function isElementFullscreen(element: Element): boolean {
  return (
    element.clientWidth >= window.innerWidth &&
    element.clientHeight >= window.innerHeight
  );
}

export function setFullscreenStyle(element: HTMLElement): void {
  element.style.top = '0';
  element.style.left = '0';
  element.style.position = 'fixed';
  element.style.width = '100%';
  element.style.height = '100%';
  element.style.maxHeight = '100%';
  element.style.maxWidth = '100%';
  element.style.zIndex = '100';
}

export function clearFullscreenStyle(element: HTMLElement): void {
  const props = [
    'top',
    'left',
    'position',
    'width',
    'height',
    'maxHeight',
    'maxWidth',
    'zIndex'
  ];

  props.forEach(prop => element.style.removeProperty(prop));
}
