import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { VehicleType } from 'livemap-gl';
import { ThemeMode } from 'livemap-ui';

import { AppState } from '@state/app-state';

import { MainMenu, MainMenuDispatch, MainMenuProps } from './MainMenu';

import {
  closeMenu,
  openFeedback,
  openNotice,
  setThemeMode,
  toggleDisplayVehicle,
  togglePerspective
} from '@state/actions';

const mapStateToProps = (state: AppState): MainMenuProps => ({
  isVisible: state.ui.currentMenu === 'main',
  visibleVehicleTypes: state.map.visibleVehiclesTypes,
  isPerspectiveViewEnabled: state.map.isPerspectiveViewAllowed,
  config: state.config
});

const mapDispatchToProps = (dispatch: Dispatch): MainMenuDispatch => ({
  openFeedback() {
    dispatch(closeMenu());
    dispatch(openFeedback());
  },
  setThemeMode(mode: ThemeMode) {
    dispatch(setThemeMode(mode));
  },
  toggleDisplayVehicle(type: VehicleType) {
    dispatch(toggleDisplayVehicle(type));
  },
  togglePerspective() {
    dispatch(togglePerspective());
  },
  openNotice() {
    dispatch(openNotice());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainMenu);
