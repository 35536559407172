import { FeedbackFormData } from '@core/feedback';

import { EmptyAction, PayloadedAction } from './app-actions';

export const enum FeedbackActionType {
  OPEN = 'FEEDBACK/OPEN',
  CLOSE = 'FEEDBACK/CLOSE',
  SUBMIT = 'FEEDBACK/SUBMIT',
  SUBMIT_ERROR = 'FEEDBACK/SUBMIT_ERROR',
  SUBMIT_SUCCESS = 'FEEDBACK/SUBMIT_SUCCESS'
}

export type SendFeedbackAction = PayloadedAction<
  FeedbackActionType.SUBMIT,
  FeedbackFormData
>;

export type SubmitFeedbackErrorAction = PayloadedAction<
  FeedbackActionType.SUBMIT_ERROR,
  string
>;

export type OpenFeedbackAction = EmptyAction<FeedbackActionType.OPEN>;

export type CloseFeedbackAction = EmptyAction<FeedbackActionType.CLOSE>;

export type SubmitFeedbackSuccessAction = EmptyAction<
  FeedbackActionType.SUBMIT_SUCCESS
>;

export type AnyFeedbackAction =
  | SendFeedbackAction
  | SubmitFeedbackErrorAction
  | SubmitFeedbackSuccessAction
  | OpenFeedbackAction
  | CloseFeedbackAction;

/**
 * Send the provided feedback data.
 * @param feedback The feedback data to send.
 */
export const submitFeedback = (
  feedback: FeedbackFormData
): SendFeedbackAction => ({
  type: FeedbackActionType.SUBMIT,
  payload: feedback
});

/** Notify that an error has occurred when sending feedback  */
export const submitFeedbackError = (
  error: string
): SubmitFeedbackErrorAction => ({
  type: FeedbackActionType.SUBMIT_ERROR,
  payload: error
});

/** Notify that feedback has been sent successfully */
export const submitFeedbackSuccess = (): SubmitFeedbackSuccessAction => ({
  type: FeedbackActionType.SUBMIT_SUCCESS
});

export const openFeedback = (): OpenFeedbackAction => ({
  type: FeedbackActionType.OPEN
});

export const closeFeedback = (): CloseFeedbackAction => ({
  type: FeedbackActionType.CLOSE
});
