import { useEffect, useState } from 'react';

type ClickOutsideHandler = (event: MouseEvent | TouchEvent) => void;

export function useOnClickOutside<T extends Element>(
  element: T | null | undefined,
  handler: ClickOutsideHandler
) {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!element || element.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mouseup', listener);
    document.addEventListener('touchend', listener);

    return () => {
      document.removeEventListener('mouseup', listener);
      document.removeEventListener('touchend', listener);
    };
  }, [element, handler]);
}

export function useDebounce<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
