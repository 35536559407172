import { push, replace } from 'connected-react-router';
import { GeoCoordinate, ToxelFields, Vehicle } from 'livemap-gl';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  CameraMovedAction,
  FitBoundsAction,
  FlyToAction,
  follow,
  FollowAction,
  MapActionType,
  pushSnackbarNotice,
  RemoveMapLayerAction,
  removeSnackbarNotice,
  SelectVehicleAction,
  SetMapLayerAction,
  SetMapTimeAction,
  SetTimescaleAction,
  ToggleDisplayVehicleAction,
  TripLoadedAction,
  UserMovedAction
} from '@state/actions';

import { AppConfig, getSelectedTheme, getVehicleType } from '@core/app-config';

import * as mapCamera from '@core/map/camera';
import * as layers from '@core/map/layers';
import * as traffic from '@core/map/traffic';

import { Following, geolocate } from '@core/map/position';

import { saveCamera } from '@core/storage';
import { sendCoordinate } from '@core/tracking';

import { getErrorDescription } from '@core/error';
import { AppState } from '@state/app-state';
import { seconds } from 'livemap-ui';
import { createPathFromState, dispatchFromPath } from '../../url';

function* handleFitBounds({ payload }: FitBoundsAction) {
  yield call(mapCamera.fitBounds, payload.bounds, payload.options);
}

function* handleFlyTo(action: FlyToAction) {
  try {
    yield call(mapCamera.flyTo, action.payload);
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e);
  }
}

function* handleFollow(action: FollowAction) {
  if (action.payload === Following.USER) {
    let coords: GeoCoordinate = yield select(
      (state: AppState) => state.map.userLocation
    );

    if (!coords) {
      try {
        coords = yield call(geolocate);
      } catch (err) {
        console.warn(getErrorDescription(err));
      }
    }

    if (!coords) {
      yield put(
        pushSnackbarNotice({
          id: 'enable-geolocation',
          text: 'Please enable device location',
          action: removeSnackbarNotice('enable-geolocation'),
          actionText: 'OK',
          duration: seconds(4)
        })
      );
      yield put(follow(Following.NOTHING));
    } else {
      yield call(mapCamera.jumpTo, {
        center: [coords.longitude, coords.latitude]
      });
    }
  }
}

export function* handleCameraMoved(action: CameraMovedAction): Generator {
  const state = (yield select((s: AppState) => s)) as AppState;

  const path: string = state.router.location
    ? state.router.location.pathname
    : '/';

  const shouldUpdateURL = path !== '/feedback';

  // Save camera properties in local storage
  yield call(saveCamera, action.payload);

  if (shouldUpdateURL) {
    const newPath = createPathFromState(state);

    yield put(replace(newPath));
  }
}

export function* handleUpdateVehicleFilter() {
  yield call(updateVehicleFilter);
}

export function* handleTripLoaded({ payload }: TripLoadedAction): Generator {
  if (!payload) {
    yield put(
      pushSnackbarNotice({
        id: 'trip-load-failed',
        text: 'The trip could not be loaded',
        duration: seconds(2)
      })
    );

    return;
  }

  const state = (yield select((s: AppState) => s)) as AppState;
  const path: string = state.router.location
    ? state.router.location.pathname
    : '/';

  if (path === '/' || path.startsWith('/trip') || path[1] === '@') {
    yield put(push(createPathFromState(state)));
  }
}

export function* handleSetFoundStops() {
  const { config, map }: AppState = yield select((state: AppState) => state);
  const theme = getSelectedTheme(config);

  yield call(updateVehicleFilter);

  layers.removeRouteLayers();

  map.routeFilters.forEach((v) => {
    const routeLayers = layers.getRouteLayers(v);
    const url = theme.tiles.linesURL;

    if (!url) {
      return;
    }

    layers.setRouteLayer(routeLayers.light, url);
    layers.setRouteLayer(routeLayers.dark, url);
  });
}

export function* handleSetReady() {
  const getPath = (state: AppState) =>
    state.router.location ? state.router.location.pathname : '/';

  const path: string = yield select(getPath);
  const actions = dispatchFromPath(path);

  for (const action of actions) {
    yield put(action);
  }
}

function* handleSetMapTime({ payload: timestamp }: SetMapTimeAction) {
  yield call(traffic.setSimulationTime, timestamp);
}

function* handleResetMapTime() {
  yield call(traffic.setRealtime);
}

function* handleSetTimescale(action: SetTimescaleAction) {
  yield call(traffic.setSimulationTimescale, action.payload);
}

function* handleStopSelected() {
  const state: AppState = yield select((s: AppState) => s);

  yield put(push(createPathFromState(state)));
}

function* handleVehicleSelected() {
  yield put(follow(Following.SELECTED_VEHICLE));
}

function* handleSetUserLocation(action: UserMovedAction) {
  if (!action.payload) {
    return;
  }

  const { map, config }: AppState = yield select((state: AppState) => state);
  const { endpoint, enabled } = config.userPositionTracking;

  if (endpoint && enabled) {
    sendCoordinate(endpoint, map.sessionId, action.payload);
  }

  if (map.following !== Following.USER) {
    return;
  }

  const zoom = mapCamera.getZoom();

  yield call(mapCamera.flyTo, {
    center: [action.payload.longitude, action.payload.latitude],
    offset: map.cameraOffset,
    zoom
  });
}

function* handleStopCleared() {
  yield put(push('/'));
}

function* handleTripCleared() {
  yield put(push('/'));
  yield call(traffic.clearTrip);
}

function* handleToggleDisplayVehicle(action: ToggleDisplayVehicleAction) {
  yield call(updateVehicleFilter);
}

function* updateVehicleFilter() {
  const state: AppState = yield select((s) => s);
  const available = state.config.vehicles.available.map(getVehicleType);
  const visibleTypes = state.map.visibleVehiclesTypes.filter(
    (type) => available.indexOf(type) > -1
  );

  const filter = (vehicle: Vehicle): boolean =>
    visibleTypes.indexOf(vehicle.type) > -1;

  const toxelFields = state.config.enableRouteFilter
    ? ToxelFields.VEHICLE_NAME
    : ToxelFields.NONE;

  if (
    state.config.enableRouteFilter &&
    state.map.routeFilters &&
    state.map.routeFilters.length > 0
  ) {
    const filterText = (vehicle: Vehicle) =>
      state.map.routeFilters.indexOf(vehicle.name || '') !== -1 &&
      filter(vehicle);

    yield call(traffic.setVehicleFilter, filterText, toxelFields);
  } else {
    yield call(traffic.setVehicleFilter, filter, toxelFields);
  }
}

function* toggleEnablePerspectiveView() {
  yield call(mapCamera.togglePerspectiveView);
}

function* setLineLayer(action: SetMapLayerAction): Generator {
  const s = (yield select((state: AppState) => state.config)) as AppConfig;
  const theme = getSelectedTheme(s);

  if (theme.tiles.linesURL) {
    yield call(layers.setRouteLayer, action.payload, theme.tiles.linesURL);
  }
}

function* removeLineLayer(action: RemoveMapLayerAction) {
  yield call(layers.removeLayer, action.payload);
}

function* zoomIn() {
  yield call(mapCamera.zoomIn);
}

function* zoomOut() {
  yield call(mapCamera.zoomOut);
}

function* handleSelectVehicle({ payload }: SelectVehicleAction) {
  traffic.selectTrip(payload.id, payload.start);
}

function* handleClearSelectedVehicle() {
  traffic.clearTrip();
}

function* handleClearSelectedStop() {
  traffic.clearStop();
}

export function* mapSaga() {
  yield takeLatest(MapActionType.IS_READY, handleSetReady);
  yield takeLatest(MapActionType.FLY_TO, handleFlyTo);
  yield takeLatest(MapActionType.FIT_BOUNDS, handleFitBounds);

  yield takeLatest(MapActionType.VEHICLE_SELECTED, handleVehicleSelected);
  yield takeLatest(MapActionType.TRIP_LOADED, handleTripLoaded);
  yield takeLatest(MapActionType.VEHICLE_CLEARED, handleTripCleared);
  yield takeLatest(MapActionType.STOP_SELECTED, handleStopSelected);
  yield takeLatest(MapActionType.STOP_CLEARED, handleStopCleared);
  yield takeLatest(MapActionType.CAMERA_MOVED, handleCameraMoved);
  yield takeLatest(MapActionType.USER_MOVED, handleSetUserLocation);

  yield takeLatest(MapActionType.SELECT_VEHICLE, handleSelectVehicle);
  yield takeLatest(
    MapActionType.CLEAR_SELECTED_VEHICLE,
    handleClearSelectedVehicle
  );
  yield takeLatest(MapActionType.CLEAR_SELECTED_STOP, handleClearSelectedStop);
  yield takeLatest(MapActionType.FOLLOW, handleFollow);

  yield takeLatest(
    MapActionType.TOGGLE_DISPLAY_VEHICLE,
    handleToggleDisplayVehicle
  );
  yield takeLatest(
    MapActionType.TOGGLE_PERSPECTIVE_VIEW_ALLOWED,
    toggleEnablePerspectiveView
  );

  yield takeLatest(MapActionType.SET_MAP_TIMESCALE, handleSetTimescale);
  yield takeLatest(MapActionType.SET_MAP_TIME, handleSetMapTime);
  yield takeLatest(MapActionType.RESET_MAP_TIME, handleResetMapTime);

  yield takeLatest(MapActionType.SET_MAP_LAYER, setLineLayer);
  yield takeLatest(MapActionType.REMOVE_MAP_LAYER, removeLineLayer);
  yield takeLatest(MapActionType.ZOOM_IN, zoomIn);
  yield takeLatest(MapActionType.ZOOM_OUT, zoomOut);
  yield takeLatest(MapActionType.SET_ROUTE_FILTERS, handleUpdateVehicleFilter);
  yield takeLatest(MapActionType.FOUND_STOPS, handleSetFoundStops);
}
