import styled, {
  ALL_CORNERS,
  Button,
  FloatingPane,
  Huge,
  ScrollPane,
  STANDARD_EASING
} from 'livemap-ui';
import React, { useEffect, useState } from 'react';

import { closeMenu } from '@state/actions';
import { useDispatch } from 'react-redux';

export interface NoticeProps {
  url: string;
  isVisible: boolean;
}

export interface NoticeDispatch {
  onClose(): void;
}

type Props = NoticeDispatch & NoticeProps;

export function Notice({ isVisible, url, onClose }: Props) {
  const [text, setText] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (isVisible) {
      dispatch(closeMenu());
    }
  });

  getNoticeText(url).then(t => (t ? setText(t) : null));

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <NoticeOverlay onClick={onClose} />
      <NoticeWrapper corners={ALL_CORNERS}>
        <NoticeHeading>
          <Huge>NOTICE</Huge>
        </NoticeHeading>
        <NoticeContent>
          <NoticeText>{text}</NoticeText>
        </NoticeContent>
        <CloseButton onClick={onClose}>Close</CloseButton>
      </NoticeWrapper>
    </>
  );
}

const getNoticeText = (url: string) =>
  fetch(url)
    .then(r => r.text())
    .catch(() => null);

const NoticeWrapper = styled(FloatingPane)`
  @keyframes notice-fly-in {
    from {
      transform: translateY(-200%) translateX(-50%);
    }
    to {
      transform: translateY(0) translateX(-50%);
    }
  }

  animation: notice-fly-in 1000ms ${STANDARD_EASING};
  animation-fill-mode: forwards;

  background-color: ${t => t.theme.backgroundColor};
  color: ${t => t.theme.contentColor};
  width: calc(100% - 48px);
  height: calc(100% - 48px);
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  padding: 24px;
  z-index: 200;
  max-width: 480px;
  max-height: 80%;
`;

const NoticeText = styled.div`
  white-space: pre-wrap;
  user-select: none;
  opacity: ${t => t.theme.disabledOpacity};
  margin-top: 24px;
  margin-bottom: 300px;
`;

const NoticeContent = styled(ScrollPane)`
  height: 100%;
`;

const CloseButton = styled(Button)`
  top: 12px;
  right: 24px;
  position: absolute;
  background-color: ${p => p.theme.backgroundColor};
`;

const NoticeHeading = styled.h1`
  padding-bottom: 24px;
`;

const NoticeOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 199;
  background-color: black;
  opacity: 0.5;
`;
