import classNames from 'classnames';
import React, { useState } from 'react';

import styled from 'livemap-ui';

import { boldInfoIcon } from '@shared/icons';

export interface AttributionProps {
  text: string;
  hasDesktopLayout: boolean;
}

export function Attribution({ text, hasDesktopLayout }: AttributionProps) {
  const [isExpanded, setIsExpanded] = useState(hasDesktopLayout);
  const toggleIsExpanded = () => setIsExpanded(!isExpanded);

  const classes = classNames({ isExpanded });

  return (
    <AttributionContent className={classes} onClick={toggleIsExpanded}>
      <AttributionText>{text}</AttributionText>
      {boldInfoIcon}
    </AttributionContent>
  );
}

const AttributionText = styled.div`
  background: transparent;
  padding: 2px 2px 2px 6px;
  vertical-align: middle;
  font-family: ${p => p.theme.headingFontFamily};
  font-weight: 500;
  display: none; /* Hidden by default */
`;

const AttributionContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  background-color: ${p => p.theme.contentColor};
  color: ${p => p.theme.backgroundColor};
  bottom: 0;
  left: 0;
  margin: 6px;
  height: 22px;
  font-size: 12px;
  border-radius: 50%;
  opacity: ${p => p.theme.disabledOpacity};
  user-select: none;

  &.isExpanded {
    margin: 0;
    border-radius: 0 100px 100px 0;
  }

  &.isExpanded ${AttributionText} {
    display: inline;
  }

  > svg {
    width: 14px;
    height: 14px;
    margin: 4px;
    fill: currentColor;
  }
`;
