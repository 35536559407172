import { assertOK } from './error';

export interface ItemAddress {
  readonly label: string;
  readonly district?: string;
  readonly city?: string;
  readonly countryCode?: string;
  readonly countryName?: string;
  readonly county?: string;
  readonly houseNumber?: string;
  readonly postalCode?: string;
  readonly state?: string;
  readonly street?: string;
}

export interface Position {
  readonly lat: number;
  readonly lng: number;
}

export interface MapView {
  readonly east: number;
  readonly west: number;
  readonly south: number;
  readonly north: number;
}

export interface AutosuggestParams {
  readonly query: string;
  readonly at?: Position;
  readonly limit?: number;
  readonly resultTypes?: ResultType[];
  readonly lang?: string;
}

type AutosuggestParam = keyof AutosuggestParams;

export interface AutosuggestItem {
  readonly id: string;
  readonly title: string;
  readonly resultType: ResultType;
  readonly administrativeAreaType?: AdministrativeAreaType;
  readonly localityType?: LocalityType;
  readonly address?: ItemAddress;
  readonly position: Position;
  readonly distance: number;
  readonly highlights?: Highlighted;
  readonly mapView?: MapView;
  readonly access?: Position[];
}

export interface AutosuggestResult {
  items: AutosuggestItem[];
}

export type Highlighted = Map<
  keyof AutosuggestItem,
  HighlightRange | HighlightedAddress
>;

export type HighlightedAddress = Map<keyof ItemAddress, HighlightRange>;

export type ResultType =
  | 'locality'
  | 'place'
  | 'administrativeArea'
  | 'addressBlock'
  | 'street'
  | 'houseNumber';

type AdministrativeAreaType = 'country' | 'county' | 'state';

type LocalityType = 'city' | 'district' | 'subdistrict' | 'postalCode';

export interface HighlightRange {
  start: number;
  end: number;
}

let API_KEY: string | null = null;

export function authenticate(apiKey: string) {
  API_KEY = apiKey;
}

function autosuggestQuery(options: AutosuggestParams): string {
  if (!API_KEY) {
    throw new Error(
      'No API key has been set. Set one with "authenticate(apiKey)".'
    );
  }

  const queryEntry = (param: AutosuggestParam): [string, string] => {
    switch (param) {
      case 'query':
        return ['q', options[param]];
      case 'resultTypes':
        return [param, options[param]!.join()];
      case 'at':
        return [param, `${options[param]!.lat},${options[param]!.lng}`];
      default:
        return [param, options[param]!.toString()];
    }
  };

  const entries = Object.keys(options)
    .filter((param: AutosuggestParam) => options[param] !== undefined)
    .map(queryEntry);

  entries.push(['apiKey', API_KEY]);

  return entries
    .map(entry => entry.map(encodeURIComponent).join('='))
    .join('&');
}

export const NO_SUGGESTIONS: AutosuggestResult = Object.freeze({ items: [] });

export async function autosuggest(
  options: AutosuggestParams
): Promise<AutosuggestResult> {
  if (!options.query || /^\s*$/.test(options.query)) {
    return NO_SUGGESTIONS;
  }

  const query = autosuggestQuery(options);
  const url = 'https://autosuggest.search.hereapi.com/v1/autosuggest?' + query;

  const res = await fetch(url);

  assertOK(res);

  return (await res.json()) as AutosuggestResult;
}
