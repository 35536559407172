import { GeoCoordinate } from 'livemap-gl';

import { isNativeApp, notifyNativeApp } from '../native-app';
import { saveAcceptedGeolocation } from '../storage';
import { callLivemap } from './bindings';

export const enum Following {
  /** The camera should not follow anything. */
  NOTHING = 0,
  /** The camera should follow the selected vehicle. */
  SELECTED_VEHICLE = 1,
  /** The camera should follow the user's position */
  USER = 2
}

export const geolocate = (): Promise<GeoCoordinate> =>
  new Promise((resolve, reject) => {
    if (isNativeApp()) {
      notifyNativeApp({
        type: 'APP',
        name: 'permission',
        data: { type: 'location' }
      });
    }

    navigator.geolocation.getCurrentPosition(res => {
      callLivemap(lm => {
        lm.modules.geolocation.enabled = true;
        lm.sensors.start();
      });

      saveAcceptedGeolocation(true);

      resolve({
        longitude: res.coords.longitude,
        latitude: res.coords.latitude
      });
    }, reject);
  });
