import {
  bindMapbox,
  Core,
  createShadow,
  DebugModule,
  MapboxMapLike
} from 'livemap-gl';
import mapboxgl, { CameraOptions } from 'mapbox-gl';

import { AppConfig, getSelectedTheme } from '@core/app-config';
import { loadModels, loadModelSelector } from '@core/custom-models';
import { bindMaps } from '@core/map/bindings';
import { RouteLayerStyle } from '@core/map/layers';

interface Maps {
  livemap: Core;
  map: mapboxgl.Map;
}

export function createMaps(
  containerID: string,
  config: AppConfig,
  initialCamera: CameraOptions,
  layerStyle: RouteLayerStyle
): Maps {
  const theme = getSelectedTheme(config);

  const livemap = new Core({
    host: config.host,
    apiKey: config.apiKey || undefined
  });

  const styleURL =
    layerStyle === RouteLayerStyle.LINES
      ? theme.tiles.tileLinesURL || theme.tiles.tileURL
      : theme.tiles.tileURL;

  const map = new mapboxgl.Map({
    ...initialCamera,
    container: containerID,
    style: styleURL,
    maxBounds: config.components.map.bounds
  });

  bindMapbox(livemap, (map as unknown) as MapboxMapLike);
  bindMaps(livemap, map);

  if (!config.components.menu.perspectiveView) {
    map.dragRotate.disable();
  }

  // Disable zoom and panning if in kiosk mode
  if (!config.components.map.panning) {
    map.dragPan.disable();
    map.dragRotate.disable();
    map.scrollZoom.disable();
    map.boxZoom.disable();
    map.doubleClickZoom.disable();
    map.touchZoomRotate.disable();
  }

  // Set max zoom
  map.setMaxZoom(16.95);

  // tslint:disable: no-any
  (window as any).livemap = livemap;
  (window as any).map = map;
  // tslint:enable: no-any

  return { livemap, map };
}

export function configureLivemap(config: AppConfig, livemap: Core): void {
  const theme = getSelectedTheme(config);
  const { route, stopBadge, vehicleBadge, customModels } = theme.livemap;

  livemap.modules.route.configure({
    lineStyle: route.lineType,
    glowFactor: route.glow,
    segmentLength: route.segmentLength,
    color: route.color,
    colorPast: route.colorPast,
    lineWidth: route.lineWidth,
    useTripBgColor: route.useTripColor
  });

  livemap.modules.stopPin.configure({
    color: '#616161',
    selectedColor: theme.ui.detailColor,
    zoomEnabled: 14
  });

  livemap.modules.stopBadge.configureDefault();
  livemap.modules.stopBadge.configure(stopBadge);
  livemap.modules.vehicleBadge.configureDefault();
  livemap.modules.vehicleBadge.configure(vehicleBadge);

  livemap.modules.watermark.enabled = theme.livemap.watermark.visible;
  livemap.modules.watermark.configure({
    position: 'bottom-center',
    size: 'large',
    paddingY: window.innerWidth < 640 ? 24 : 12,
    color: theme.livemap.watermark.color
  });

  livemap.modules.attribution.enabled = false;

  livemap.modules.vehicle.configureDefault();
  livemap.modules.vehicle.configure(theme.livemap.vehicles);

  if (customModels) {
    const selectorURL = customModels.modelSelectorFunctionPath;
    const definitions = customModels.customModelLocations;

    loadModels(definitions)
      .then((models) => {
        models.forEach(({ id, mesh }) => {
          livemap.meshes.setModel(id, mesh);
          if (theme.livemap.autoCreateShadows) {
            livemap.meshes.setModel(id + 1, createShadow(mesh, -1));
          }
        });
      })
      .then(() => {
        loadModelSelector(selectorURL).then((selector) =>
          livemap.modules.vehicle.configure({
            modelSelector: selector,
            drawShadow: theme.livemap.showDropShadows
          })
        );
      });
  }

  if (isDebugEnabled() && !livemap.modules.hasModule('debug')) {
    livemap.modules.add('debug', new DebugModule());
  }
}

export function configureMapStyle(
  config: AppConfig,
  layerStyle: RouteLayerStyle,
  map: mapboxgl.Map
) {
  const theme = getSelectedTheme(config);

  const styleURL =
    layerStyle === RouteLayerStyle.LINES
      ? theme.tiles.tileLinesURL || theme.tiles.tileURL
      : theme.tiles.tileURL;

  map.setStyle(styleURL);
}

const isDebugEnabled = () => window.location.hash.indexOf('debug') > -1;
