import deepMerge from 'deepmerge';

import {
  HexColor,
  StopBadgeConfig,
  VehicleBadgeConfig,
  VehicleRenderModuleConfig,
  VehicleType
} from 'livemap-gl';
import { ThemeMode, UITheme } from 'livemap-ui';
import { CameraOptions, LngLatBoundsLike } from 'mapbox-gl';

import { DeviceType } from './device';
import { assertOK, getErrorDescription } from './error';
import { Keybinding } from './keyboard';

const replaceArray = (destinationArray: [], sourceArray: []) =>
  sourceArray || destinationArray;

export function mergeConfig<T>(left: Partial<T>, right: Partial<T>): T {
  return deepMerge<T>(left, right, { arrayMerge: replaceArray });
}

const vehicleTypeMap: { [key in VehicleType]: VehicleTypeNames } = {
  [VehicleType.AIRCRAFT]: 'aircraft',
  [VehicleType.ANIMAL]: 'animal',
  [VehicleType.AUTONOMOUS]: 'autonomous',
  [VehicleType.BUS]: 'bus',
  [VehicleType.CABLE_CAR]: 'cableCar',
  [VehicleType.FERRY]: 'ferry',
  [VehicleType.FUNICULAR]: 'funicular',
  [VehicleType.GONDOLA]: 'gondola',
  [VehicleType.MISCELLANEOUS]: 'miscellaneous',
  [VehicleType.RAIL]: 'rail',
  [VehicleType.SUBWAY]: 'subway',
  [VehicleType.TAXI]: 'taxi',
  [VehicleType.TRAM]: 'tram'
};

const vehicleTypeKeyMap = {
  aircraft: VehicleType.AIRCRAFT,
  animal: VehicleType.ANIMAL,
  autonomous: VehicleType.AUTONOMOUS,
  bus: VehicleType.BUS,
  cableCar: VehicleType.CABLE_CAR,
  ferry: VehicleType.FERRY,
  funicular: VehicleType.FUNICULAR,
  gondola: VehicleType.GONDOLA,
  miscellaneous: VehicleType.MISCELLANEOUS,
  rail: VehicleType.RAIL,
  subway: VehicleType.SUBWAY,
  taxi: VehicleType.TAXI,
  tram: VehicleType.TRAM
};

type VehicleTypeNames = keyof typeof vehicleTypeKeyMap;

export interface CustomLivemapModel {
  modelId: number;
  path: string;
}

export interface CustomModelConfig {
  customModelLocations: CustomLivemapModel[];
  modelSelectorFunctionPath: string;
}

export interface LivemapTheme {
  customModels?: CustomModelConfig;
  showDropShadows?: boolean;
  autoCreateShadows?: boolean;
  vehicles: Partial<VehicleRenderModuleConfig>;
  stopBadge: Partial<StopBadgeConfig>;
  vehicleBadge: Partial<VehicleBadgeConfig>;
  route: {
    color: HexColor;
    colorPast: HexColor;
    glow: number;
    segmentLength: number;
    useTripColor: boolean;
    lineType: number;
    lineWidth: number;
  };
  watermark: {
    visible: boolean;
    color: HexColor;
  };
}

export interface StartOptions extends CameraOptions {
  showMarker: boolean;
  markerColor: string;
}

export interface TrazeMapConfig {
  bounds?: LngLatBoundsLike;
  panning: boolean;
  oneClickFilter: { enabled: boolean };
  /**
   * If specified, this camera will be used to resolve the initial map position.
   * If set, the map will _always be initialized_ at the given position.
   */
  start: StartOptions | null;
  /**
   * If specified, this camera will be used to resolve the initial map position,
   * before attempting IP-geolocation and hard coded fallback values.
   */
  fallbackCamera: CameraOptions | null;
}

export interface LinkInfo {
  text: string;
  url: string;
  title?: string;
}

export interface LogoConfig {
  url: string;
  alt?: string;
}

export interface BrandingConfig {
  name?: string;
  website?: LinkInfo;
  logo?: LogoConfig;
  logoDark?: LogoConfig;
}

export interface ComponentsConfig {
  attribution: { enabled: boolean };
  notice: { url: string };
  layerSelector: { enabled: boolean };
  search: { enabled: boolean };
  lineGraphs: { enabled: boolean };
  tripInfo: {
    enabled: boolean;
    useRouteColor: boolean;
  };
  stopInfo: {
    /** Whether or not to show the "your timezone"-banner on the stop info */
    showTimeZoneBanner: boolean;
  };
  geolocation: {
    enabled: boolean;
    action: GeolocationAction;
  };
  map: TrazeMapConfig;
  vehicleStats: {
    enabled: boolean;
  };
  menu: {
    enabled: boolean;
    feedback: boolean;
    nightMode: boolean;
    perspectiveView: boolean;
    privacy: boolean;
    vehicleFilters: boolean;
    versionNumber: boolean;
    customLinks: CustomLink[];
  };
  mapControls: {
    /** Enable zoom controls on desktop */
    zoom: boolean;
    /** Enable the compass control */
    compass: boolean;
    /** Show a fullscreen button if the map is not occupying the entire screen. */
    fullscreen: boolean;
  };
}

export interface UserPositionTrackingConfig {
  enabled: boolean;
  endpoint?: string;
}

export interface ToxelConfig {
  depth: number;
  /** Number of minutes before a departure a stop should be visible */
  stopMinutes: number;
}

export interface CustomLink {
  text?: string;
  translationId?: string;
  url: string;
}

export interface ThemesConfig {
  day: ColorTheme;
  night: ColorTheme;
}

export interface VehiclesConfig {
  /**
   * How fast the vehicle should move between two points.
   * `1` means "instant teleportation".
   *
   * Usually set below `0.1`.
   */
  positionEasing: number;
  /**
   * How fast the vehicle should rotate between two angles.
   * `1` means "instant rotation".
   *
   * Usually set below `0.1`.
   */
  headingEasing: number;
  /** The number of vehicles visible in the side-menu, before expansion. */
  sideMenuVisibleCollapsed: number;
  /** The available vehicle types, also dictates the order in the side-menu. */
  available: string[];
  /** Whether or not vehicle's should be followed when selected. */
  followVehicle: boolean;
}

export interface DeviceAvailability {
  /** Redirect the user to the specified URL */
  redirectURL: string;
}

/** Allows for configuring the app availability for different devices. */
export type AvailabilityConfig = {
  [device in DeviceType]?: DeviceAvailability;
};

export interface AppConfig {
  /**
   * The Livemap host URL, where to request Livemap data from.
   * Defaults to `/v3`, but should be set in the client config.
   */
  host: string;
  /**
   * The livemap API key, used to validate the requesting client.
   * This property must be set in the client config.
   */
  apiKey: string | null;
  /**
   * The key to use for HERE APIs (i.e the autosuggest API)
   */
  hereApiKey: string | null;
  /**
   * Allows for configuring which devices are supported by the application,
   * e.g. if you want to redirect desktop users to a different site.
   *
   * The absence of a device simply means that the app is available for the device.
   * By default the app is available to all devices.
   */
  availability: AvailabilityConfig;
  /**
   * A Google analytics ID.
   * If not set in the client config, GA-tracking will not be performed.
   */
  gaId?: string;
  /** Whether or not route filtering functionality should be available via search. */
  enableRouteFilter: boolean;
  /** How the app should be branded. */
  brand: BrandingConfig;
  /** Whether the app is in `day`, or `night` mode. */
  themeMode: ThemeMode;
  /** The configuration for the day and night mode themes. */
  themes: ThemesConfig;
  /**  */
  vehicles: VehiclesConfig;
  /**
   * Configures which components are available,
   * and their settings
   */
  components: ComponentsConfig;
  /** Configures language settings. */
  language: LanguageConfig;

  userPositionTracking: UserPositionTrackingConfig;

  toxel: ToxelConfig;

  keybindings: Keybinding[] | null;
}

export interface LanguageConfig {
  code: 'en' | 'sv';
}

export const enum GeolocationAction {
  GEOLOCATE = 'GEOLOCATE',
  PAN_TO_START = 'PAN_TO_START'
}

export interface TileTheme {
  tileURL: string;
  tileLinesURL?: string;
  linesURL?: string;
  backgroundColor: string;
  attribution?: string;
}

export interface ColorTheme {
  tiles: TileTheme;
  ui: UITheme;
  vehicleColors: Record<VehicleTypeNames, string>;
  livemap: LivemapTheme;
}

export const getSelectedTheme = (config: AppConfig) => {
  const theme = config.themes[config.themeMode];

  if (theme) {
    return theme;
  }

  throw new Error(
    'Theme "' + config.themeMode + '" is not provided in the config.'
  );
};

export const getVehicleTypeKey = (type: VehicleType): VehicleTypeNames =>
  vehicleTypeMap[type] || 'miscellaneous';

export const getVehicleType = (
  vehicleTypeKey: keyof typeof vehicleTypeKeyMap
): VehicleType => vehicleTypeKeyMap[vehicleTypeKey];

export const getVehicleColorArray = (colorTheme: ColorTheme) => {
  const vehicles = colorTheme.vehicleColors;

  return [
    vehicles.tram,
    vehicles.subway,
    vehicles.rail,
    vehicles.bus,
    vehicles.ferry,
    vehicles.taxi,
    vehicles.cableCar,
    vehicles.gondola,
    vehicles.funicular,
    vehicles.aircraft,
    vehicles.autonomous,
    vehicles.animal,
    vehicles.miscellaneous
  ];
};

/**
 * Gets the brand logo from the provided theme.
 * If the app is in night mode and `logoDark` is available,
 * `logoDark` is returned otherwise the `logo` is being used.
 *
 * @param config The application configuration to retrieve the logo from
 */
export function getBrandLogo({ themeMode, brand }: AppConfig) {
  if (themeMode === 'night' && brand.logoDark) {
    return brand.logoDark;
  }

  if (brand.logo) {
    return brand.logo;
  }

  return null;
}

/**
 * Loads a partial traze configuration from the provided URL.
 *
 * This function returns `null` if, for instance:
 *
 * - The URL provided is _falsy_
 * - The server returns a non-success status code
 * - The server returns invalid JSON
 *
 * @param url The URL to load the configuration file from.
 */
export async function loadClientConfig(
  url: string
): Promise<null | Partial<AppConfig>> {
  try {
    if (!url) {
      throw new Error('The URL provided is empty.');
    }

    const res = await fetch(url);

    assertOK(res);

    return await res.json();
  } catch (error) {
    console.warn(
      `Failed to load the Traze configuration at '${url}'.\n\n` +
        getErrorDescription(error)
    );

    return null;
  }
}
