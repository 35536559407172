import { Core } from 'livemap-gl';
import { Map } from 'mapbox-gl';

let livemapMap: Core;
let mapboxMap: Map;

const actionQueue: EnqueuedMapAction[] = [];

/**
 * Binds the provided livemap and mapbox map to a global
 * context, so they can be called from anywhere.
 *
 * @param livemap The livemap map to use in the app
 * @param map The mapbox map to use in the app
 */
export function bindMaps(livemap: Core, map: Map) {
  livemapMap = livemap;
  mapboxMap = map;

  handleActionQueue();
}

/** Determines whether the mapbox and livemap map APIs are bound.  */
export function areMapAPIsBound(): boolean {
  return !!(livemapMap && mapboxMap);
}

function handleActionQueue() {
  for (const action of actionQueue) {
    if (action.map === 'livemap') {
      callLivemap(action.fn);
    } else {
      callMapbox(action.fn);
    }
  }

  actionQueue.length = 0;
}

interface EnqueuedMapAction {
  map: 'livemap' | 'mapbox';
  // tslint:disable-next-line:no-any
  fn: (...args: any[]) => void;
}

/**
 * Used to access and call functions on the livemap map API,
 * If the map is not yet available, the action will be queued.
 * @param action What to do with the livemap API.
 */
export function callLivemap<R>(action: (lm: Core) => R) {
  if (livemapMap) {
    action(livemapMap);
  } else {
    actionQueue.push({ map: 'livemap', fn: action });
  }
}

/**
 * Used to access and call functions on the mapbox map API,
 * If the map is not yet available, the action will be queued.
 * @param action What to do with the mapbox API.
 */
export function callMapbox<R>(action: (mb: Map) => R) {
  if (mapboxMap) {
    action(mapboxMap);
  } else {
    actionQueue.push({ map: 'mapbox', fn: action });
  }
}

/**
 * Allows for retrieving data from the mapbox map.
 * If the map is not (yet) available, the provided (optional) fallback value will be used.
 * If the fallback value is not provided an error will be thrown instead.
 * @param selector The function to use to select the data.
 * @param fallback A fallback value to use if the map is not (yet) available.
 */
export function selectFromMapbox<V, FV = never>(
  selector: (mb: Map) => V,
  fallback?: FV
): V | FV {
  if (mapboxMap) {
    return selector(mapboxMap);
  } else if (typeof fallback !== 'undefined') {
    return fallback;
  }

  throw new Error(
    'Attempted to call on the mapbox map prior to binding. ' +
      'Make sure `bindMaps` in `@core/map/bindings` has been called correctly.'
  );
}

/**
 * Allows for retrieving data from livemap.
 * If the map is not (yet) available, the provided (optional) fallback value will be used.
 * If the fallback value is not provided an error will be thrown instead.
 * @param selector The function to use to select the data.
 * @param fallback A fallback value to use if the map is not (yet) available.
 */
export function selectFromLivemap<V, FV = never>(
  selector: (map: Core) => V,
  fallback?: FV
): V | FV {
  if (livemapMap) {
    return selector(livemapMap);
  } else if (typeof fallback !== 'undefined') {
    return fallback;
  }

  throw new Error(
    'Attempted to call on livemap prior to binding, and no fallback has been provided. ' +
      'Make sure `bindMaps` in `@core/map/bindings` has been called correctly.'
  );
}
