import { AutosuggestItem, AutosuggestResult } from '@core/here';

import {
  createEmpty,
  createPayloaded,
  EmptyAction,
  PayloadedAction
} from './app-actions';

export const enum SearchActionType {
  SET_QUERY = 'SEARCH/SET_QUERY',
  SEND_AUTOSUGGEST = 'SEARCH/SEND_AUTOSUGGEST',
  AUTOSUGGEST_SUCCESS = 'SEARCH/AUTOSUGGEST_SUCCESS',
  AUTOSUGGEST_ERROR = 'SEARCH/AUTOSUGGEST_ERROR',
  SELECT_AUTOSUGGEST_ITEM = 'SEARCH/SELECT_AUTOSUGGEST_ITEM',
  CLEAR_QUERY = 'SEARCH/CLEAR_QUERY'
}

export type SetSearchQueryAction = PayloadedAction<
  SearchActionType.SET_QUERY,
  string
>;

export type SendAutosuggestAction = PayloadedAction<
  SearchActionType.SEND_AUTOSUGGEST,
  string
>;

export type AutosuggestErrorAction = EmptyAction<
  SearchActionType.AUTOSUGGEST_ERROR
>;

export type AutosuggestSuccessAction = PayloadedAction<
  SearchActionType.AUTOSUGGEST_SUCCESS,
  AutosuggestResult
>;

export type SelectAutosuggestItemAction = PayloadedAction<
  SearchActionType.AUTOSUGGEST_ERROR,
  AutosuggestItem
>;

export type ClearSearchQueryAction = EmptyAction<SearchActionType.CLEAR_QUERY>;

export type AnySearchAction =
  | SetSearchQueryAction
  | ClearSearchQueryAction
  | SendAutosuggestAction
  | AutosuggestSuccessAction
  | AutosuggestErrorAction
  | SelectAutosuggestItemAction;

/** Update the current search term. */
export const setSearchQuery = (query: string): SetSearchQueryAction =>
  createPayloaded(SearchActionType.SET_QUERY, query);

/** Clear previously fetched suggestions from store */
export const clearSearchQuery = (): ClearSearchQueryAction =>
  createEmpty(SearchActionType.CLEAR_QUERY);

/** Send the current search query to the API. */
export const sendAutosuggest = (query: string): SendAutosuggestAction =>
  createPayloaded(SearchActionType.SEND_AUTOSUGGEST, query);

/** Notifies the store that a search query was not sent correctly. */
export const autosuggestFailed = (): AutosuggestErrorAction =>
  createEmpty(SearchActionType.AUTOSUGGEST_ERROR);

/** Select a specified suggestion */
export const selectAutosuggestItem = (item: AutosuggestItem) =>
  createPayloaded(SearchActionType.SELECT_AUTOSUGGEST_ITEM, item);

/**
 * Sets store autocomplete suggestions
 * @param result New autocomplete suggestions
 */
export const autosuggestSuccess = (
  result: AutosuggestResult
): AutosuggestSuccessAction =>
  createPayloaded(SearchActionType.AUTOSUGGEST_SUCCESS, result);
