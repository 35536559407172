import {
  GeoCoordinate,
  ToxelStopLocation,
  Trip,
  Vehicle,
  VehicleType
} from 'livemap-gl';
import {
  AnyLayer,
  CameraOptions,
  FitBoundsOptions,
  FlyToOptions,
  LngLatBoundsLike
} from 'mapbox-gl';

import { RouteLayerStyle } from '@core/map/layers';
import { Following } from '@core/map/position';
import { TripIdParams } from '@core/map/traffic';

import {
  createEmpty,
  createPayloaded,
  EmptyAction,
  PayloadedAction
} from './app-actions';

export const enum MapActionType {
  /*
    Actions which invoke livemap via: [Dispatch] -> [Saga] -> [Livemap],
    or simply just sets a state in the store.
  */

  /** Select a vehicle in livemap. */
  SELECT_VEHICLE = 'MAP/SELECT_VEHICLE',
  /** Clear the selected vehicle in livemap */
  CLEAR_SELECTED_VEHICLE = 'MAP/CLEAR_SELECTED_VEHICLE',
  /** Clear the selected stop in livemap */
  CLEAR_SELECTED_STOP = 'MAP/CLEAR_SELECTED_STOP',
  /** Zooms in the map by one level */
  ZOOM_IN = 'MAP/ZOOM_IN',
  /** Zoom out the map by one level */
  ZOOM_OUT = 'MAP/ZOOM_OUT',
  /** Set a camera offset in the state */
  SET_CAMERA_OFFSET = 'MAP/SET_CAMERA_OFFSET',
  /** Fit the map to the given bounds. */
  FIT_BOUNDS = 'MAP/FIT_BOUNDS',
  /** Fly to the given position on the map. */
  FLY_TO = 'MAP/FLY_TO',
  /** Set which entity to follow on the map */
  FOLLOW = 'MAP/FOLLOW',
  /** Toggles whether or not perspective (3D) view is allowed in the map client */
  TOGGLE_PERSPECTIVE_VIEW_ALLOWED = 'MAP/TOGGLE_PERSPECTIVE_VIEW_ALLOWED',
  /** Set the time in the traffic simulation to another point in time */
  SET_MAP_TIME = 'MAP/SET_MAP_TIME',
  /** Set the timescale. A value of 2 is twice as fast. */
  SET_MAP_TIMESCALE = 'MAP/SET_MAP_TIMESCALE',
  /** Sets the simulation timescale to 1, and the simulation time to the current time */
  RESET_MAP_TIME = 'MAP/RESET_MAP_TIME',
  /** Toggle whether a vehicle of a given type is visible on the map */
  TOGGLE_DISPLAY_VEHICLE = 'MAP/TOGGLE_DISPLAY_VEHICLE',
  /** Set whether or not geolocation is enabled by the user */
  SET_GEOLOCATION_ENABLED = 'MAP/SET_GEOLOCATION_ENABLED',
  /** Set the provided route filters. */
  SET_ROUTE_FILTERS = 'MAP/SET_ROUTE_FILTERS',
  /** Set which line layer to display. */
  SET_MAP_LAYER = 'MAP/SET_MAP_LAYER',
  /** Remove the specified map layer */
  REMOVE_MAP_LAYER = 'MAP/REMOVE_MAP_LAYER',
  /** Toggles route layer on or off */
  TOGGLE_ROUTE_LAYER_STYLE = 'MAP/TOGGLE_ROUTE_LAYER_STYLE',
  /** Set the route layer to the given style */
  SET_ROUTE_LAYER_STYLE = 'MAP/SET_ROUTE_LAYER_STYLE',

  /*
    Actions triggered by livemap via: [Livemap] -> [Dispatch]
  */

  /** The map is ready to be used */
  IS_READY = 'MAP/IS_READY',
  /** A vehicle has been selected */
  VEHICLE_SELECTED = 'MAP/VEHICLE_SELECTED',
  /** A trip has been loaded */
  TRIP_LOADED = 'MAP/TRIP_LOADED',
  /** A previously selected trip has been cleared */
  VEHICLE_CLEARED = 'MAP/TRIP_CLEARED',
  /** A stop has been selected */
  STOP_SELECTED = 'MAP/STOP_SELECTED',
  /** A previously selected stop has been cleared */
  STOP_CLEARED = 'MAP/STOP_CLEARED',
  /** The user moved on the map */
  USER_MOVED = 'MAP/USER_MOVED',
  /** Save the camera position to persistent storage */
  CAMERA_MOVED = 'MAP/CAMERA_MOVED',
  /** Notifies that a collection of stops were found */
  FOUND_STOPS = 'MAP/FOUND_STOPS',
  /** Notifies that the time has changed */
  TIME_CHANGED = 'MAP/TIME_CHANGED'
}

export type FlyToAction = PayloadedAction<MapActionType.FLY_TO, FlyToOptions>;

export type SetCameraOffsetAction = PayloadedAction<
  MapActionType.SET_CAMERA_OFFSET,
  [number, number]
>;

export interface FitBoundsPayload {
  bounds: LngLatBoundsLike;
  options: FitBoundsOptions;
}

export type FitBoundsAction = PayloadedAction<
  MapActionType.FIT_BOUNDS,
  FitBoundsPayload
>;

export type CameraMovedAction = PayloadedAction<
  MapActionType.CAMERA_MOVED,
  CameraOptions
>;

export type VehicleSelectedAction = PayloadedAction<
  MapActionType.VEHICLE_SELECTED,
  Vehicle
>;

export type SetRouteFiltersAction = PayloadedAction<
  MapActionType.SET_ROUTE_FILTERS,
  string[]
>;

export type FollowAction = PayloadedAction<MapActionType.FOLLOW, Following>;

export type SetRouteLayerStyleAction = PayloadedAction<
  MapActionType.SET_ROUTE_LAYER_STYLE,
  RouteLayerStyle
>;

export type ToggleRouteLayerStyleAction =
  EmptyAction<MapActionType.TOGGLE_ROUTE_LAYER_STYLE>;

export type MapReadyAction = EmptyAction<MapActionType.IS_READY>;

export type SetMapTimeAction = PayloadedAction<
  MapActionType.SET_MAP_TIME,
  number
>;

export type TimeChangedAction = PayloadedAction<
  MapActionType.TIME_CHANGED,
  number
>;

export type SetGeolocationEnabledAction = PayloadedAction<
  MapActionType.SET_GEOLOCATION_ENABLED,
  boolean
>;

export type ResetMapTimeAction = EmptyAction<MapActionType.RESET_MAP_TIME>;

export type SetTimescaleAction = PayloadedAction<
  MapActionType.SET_MAP_TIMESCALE,
  number
>;

export type StopSelectedAction = PayloadedAction<
  MapActionType.STOP_SELECTED,
  ToxelStopLocation
>;

export type TripLoadedAction = PayloadedAction<MapActionType.TRIP_LOADED, Trip>;

export type UserMovedAction = PayloadedAction<
  MapActionType.USER_MOVED,
  GeoCoordinate | null
>;

export type FoundStopsAction = PayloadedAction<
  MapActionType.FOUND_STOPS,
  ToxelStopLocation[]
>;

export type ToggleDisplayVehicleAction = PayloadedAction<
  MapActionType.TOGGLE_DISPLAY_VEHICLE,
  VehicleType
>;

export type TogglePerspectiveViewAction =
  EmptyAction<MapActionType.TOGGLE_PERSPECTIVE_VIEW_ALLOWED>;

export type StopClearedAction = EmptyAction<MapActionType.STOP_CLEARED>;

export type SetMapLayerAction = PayloadedAction<
  MapActionType.SET_MAP_LAYER,
  AnyLayer
>;

export type VehicleClearedAction = EmptyAction<MapActionType.VEHICLE_CLEARED>;

export type RemoveMapLayerAction = PayloadedAction<
  MapActionType.REMOVE_MAP_LAYER,
  string
>;

export type SelectVehicleAction = PayloadedAction<
  MapActionType.SELECT_VEHICLE,
  TripIdParams
>;

export type ClearSelectedVehicleAction =
  EmptyAction<MapActionType.CLEAR_SELECTED_VEHICLE>;

export type ClearSelectedStopAction =
  EmptyAction<MapActionType.CLEAR_SELECTED_STOP>;

export type ZoomInAction = EmptyAction<MapActionType.ZOOM_IN>;

export type ZoomOutAction = EmptyAction<MapActionType.ZOOM_OUT>;

export type AnyMapAction =
  | CameraMovedAction
  | ClearSelectedStopAction
  | ClearSelectedVehicleAction
  | FitBoundsAction
  | FlyToAction
  | FoundStopsAction
  | MapReadyAction
  | RemoveMapLayerAction
  | ResetMapTimeAction
  | SelectVehicleAction
  | SetCameraOffsetAction
  | FollowAction
  | SetGeolocationEnabledAction
  | SetRouteLayerStyleAction
  | ToggleRouteLayerStyleAction
  | SetMapLayerAction
  | SetRouteFiltersAction
  | SetTimescaleAction
  | SetMapTimeAction
  | StopClearedAction
  | StopSelectedAction
  | ToggleDisplayVehicleAction
  | TogglePerspectiveViewAction
  | VehicleClearedAction
  | TripLoadedAction
  | UserMovedAction
  | VehicleSelectedAction
  | ZoomInAction
  | TimeChangedAction
  | ZoomOutAction;

/**
 * Fit the camera viewport within the provided bounds.
 * @param bounds The bounds to fit within the viewport
 */
export const fitBounds = (payload: FitBoundsPayload): FitBoundsAction =>
  createPayloaded(MapActionType.FIT_BOUNDS, payload);

/**
 * Moves the camera of map in a flying manner
 * @param options Center, zoom, bearing, and pitch etc
 */
export const flyTo = (options: FlyToOptions): FlyToAction =>
  createPayloaded(MapActionType.FLY_TO, options);

/**
 * Notifies that the camera has been moved recently
 * @param camera The camera options representing the current location of the camera
 */
export const notifyCameraMoved = (camera: CameraOptions): CameraMovedAction =>
  createPayloaded(MapActionType.CAMERA_MOVED, camera);

/**
 * Set an offset for the camera, which is honored by e.g `flyTo`
 * @param x The camera offset on the X axis
 * @param y The camera offset on the Y axis
 */
export const setCameraOffset = (x: number, y: number): SetCameraOffsetAction =>
  createPayloaded(MapActionType.SET_CAMERA_OFFSET, [x, y]);

/**
 * Use the provided filters as route filters.
 * @param filters A set of strings to use as route filters
 */
export const setRouteFilters = (filters: string[]): SetRouteFiltersAction =>
  createPayloaded(MapActionType.SET_ROUTE_FILTERS, filters);

/**
 * Set which entity the camera should be following.
 * @entity The entity to follow.
 */
export const follow = (entity: Following): FollowAction =>
  createPayloaded(MapActionType.FOLLOW, entity);

/**
 * Changer whether, and how the route layer should be displayed.
 * @param layerStyle How to display the route layer
 */
export const setRouteLayerStyle = (
  layerStyle: RouteLayerStyle
): SetRouteLayerStyleAction =>
  createPayloaded(MapActionType.SET_ROUTE_LAYER_STYLE, layerStyle);

/** Toggles whether the route layer is shown. */
export const toggleRouteLayerStyle = (): ToggleRouteLayerStyleAction =>
  createEmpty(MapActionType.TOGGLE_ROUTE_LAYER_STYLE);

/**
 * Set the time of the traffic simulation in the map
 * @param timestamp The time to set, milliseconds since 1970.
 */
export const setMapTime = (timestamp: number): SetMapTimeAction =>
  createPayloaded(MapActionType.SET_MAP_TIME, timestamp);

/**
 * Set the `locationEnabled` flag in `localStorage`,
 * signaling whether or not location services ar enabled
 * @param enabled Whether or not location services are enabled (and allowed).
 */
export const setGeolocationEnabled = (
  enabled: boolean
): SetGeolocationEnabledAction =>
  createPayloaded(MapActionType.SET_GEOLOCATION_ENABLED, enabled);

/** Sets the map ready flag to true */
export const notifyMapIsReady = (): MapReadyAction =>
  createEmpty(MapActionType.IS_READY);

/** Sets the map speed to 1 and resets to current time */
export const resetMapTime = (): ResetMapTimeAction =>
  createEmpty(MapActionType.RESET_MAP_TIME);

/**
 * Set the timescale of the traffic simulation, can be set negative.
 * @param timescale The timescale. 2 = time passes twice as fast
 */
export const setTimescale = (timescale: number): SetTimescaleAction =>
  createPayloaded(MapActionType.SET_MAP_TIMESCALE, timescale);

/** Notify that time has changed/progressed, usually done on a "clock" */
export const notifyMapTimeChanged = (timestamp: number): TimeChangedAction =>
  createPayloaded(MapActionType.TIME_CHANGED, timestamp);

/**
 * Sets current stop in redux store, used for map events
 * @param stop Stop location
 */
export const notifyStopSelected = (
  stop: ToxelStopLocation
): StopSelectedAction => createPayloaded(MapActionType.STOP_SELECTED, stop);

export const notifyUserMoved = (
  position: GeoCoordinate | null
): UserMovedAction => createPayloaded(MapActionType.USER_MOVED, position);

/**
 * Notify that a vehicle has been selected on the map
 * @param vehicle The vehicle which has been selected
 */
export const notifyVehicleSelected = (
  vehicle: Vehicle
): VehicleSelectedAction =>
  createPayloaded(MapActionType.VEHICLE_SELECTED, vehicle);

/**
 * Notify that a trip has been loaded
 * @param trip The trip information which has been loaded
 */
export const notifyTripLoaded = (trip: Trip): TripLoadedAction =>
  createPayloaded(MapActionType.TRIP_LOADED, trip);

/** Notify that a previously selected vehicle has been cleared */
export const notifyVehicleCleared = (): VehicleClearedAction =>
  createEmpty(MapActionType.VEHICLE_CLEARED);

/**
 * Notify that stops have been found from a stop search
 * @param stops The stops which have been found
 */
export const notifyFoundStops = (
  stops: ToxelStopLocation[]
): FoundStopsAction => createPayloaded(MapActionType.FOUND_STOPS, stops);

/**
 * Toggles whether or not to show the vehicle of the given type on the map
 * @param vehicleType Type of transportation
 */
export const toggleDisplayVehicle = (
  vehicleType: VehicleType
): ToggleDisplayVehicleAction =>
  createPayloaded(MapActionType.TOGGLE_DISPLAY_VEHICLE, vehicleType);

/** Toggles perspective 3D view of the map */
export const togglePerspective = (): TogglePerspectiveViewAction =>
  createEmpty(MapActionType.TOGGLE_PERSPECTIVE_VIEW_ALLOWED);

/** Notify that a previously selected stop has been cleared. */
export const notifyStopCleared = (): StopClearedAction =>
  createEmpty(MapActionType.STOP_CLEARED);

/**
 * Add or replace a map layer.
 * @param layer The layer you want to add or replace
 */
export const setMapLayer = (layer: AnyLayer): SetMapLayerAction =>
  createPayloaded(MapActionType.SET_MAP_LAYER, layer);

/**
 * Remove a layer from the map.
 * @param layerId The identifier of the layer to remove
 */
export const removeMapLayer = (layerId: string): RemoveMapLayerAction =>
  createPayloaded(MapActionType.REMOVE_MAP_LAYER, layerId);

/** Zoom the camera in 1 level */
export const zoomIn = (): ZoomInAction => createEmpty(MapActionType.ZOOM_IN);

/** Zoom the camera out 1 level */
export const zoomOut = (): ZoomOutAction => createEmpty(MapActionType.ZOOM_OUT);

/**
 * Select a vehicle from the traffic on the map
 * @param tripId The identifier (a.k.a trip index) of the trip to select
 * @param start A timestamp for when the vehicle was scheduled to start the trip
 */
export const selectVehicle = (
  tripId: number,
  start: number
): SelectVehicleAction =>
  createPayloaded(MapActionType.SELECT_VEHICLE, { id: tripId, start });

/** Clear a previously selected vehicle */
export const clearSelectedVehicle = (): ClearSelectedVehicleAction =>
  createEmpty(MapActionType.CLEAR_SELECTED_VEHICLE);

/** Clear a previously selected stop */
export const clearSelectedStop = (): ClearSelectedStopAction =>
  createEmpty(MapActionType.CLEAR_SELECTED_STOP);
