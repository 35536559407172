import React, { useEffect, useRef } from 'react';

import styled, { noop } from 'livemap-ui';
import { AnimationOptions, CameraOptions, LngLatLike } from 'mapbox-gl';

import {
  Core,
  GeolocationUpdateEvent,
  StopSelectedEvent,
  StopsFoundEvent,
  TimeChangedEvent,
  Trip,
  unproject,
  Vehicle
} from 'livemap-gl';

import { AppConfig, getSelectedTheme } from '@core/app-config';
import { RouteLayerStyle } from '@core/map/layers';
import { Following } from '@core/map/position';
import { generateShortId } from '@core/short-id';

import { notifyCameraUpdate } from '@core/map/camera';
import * as mapEvents from '@core/map/events';
import { getInitialCamera } from '@core/map/initial-camera';
import { cheapThrottle } from '@core/throttling';

import {
  configureLivemap,
  configureMapStyle,
  createMaps
} from './livemap-factory';

export interface LivemapProps {
  following: Following;
  config: AppConfig;
  layerStyle: RouteLayerStyle;
  path: string | null;
  cameraOffset: [number, number];
}

export interface LivemapDispatch {
  onMapReady(): void;
  stopFollowing(): void;
  togglePerspectiveView(): void;
  onGeolocationUpdate(geolocation: GeolocationUpdateEvent): void;
  onStopSelected(event: StopSelectedEvent): void;
  onStopDeselected(): void;
  onTimeChanged(event: TimeChangedEvent): void;
  onVehicleSelected(vehicle: Vehicle): void;
  onTripLoaded(trip: Trip): void;
  onTripCleared(): void;
  onStopsFound(event: StopsFoundEvent): void;
  onCameraMoved(cameraOptions: CameraOptions): void;
}

type Props = LivemapProps & LivemapDispatch;

export function Livemap({ config, layerStyle, ...props }: Props) {
  /*
   * Refs 101:
   *   - a ref is just a container for a mutable value
   *   - the value passed into `useRef` is the _initial value_,
   *   - the current value is called `ref.current`
   *   - the value of `ref.current` is persisted between re-renders
   *   - the value may (or may not) change later via `ref.current = someValue;`
   */
  const idRef = useRef(generateShortId());
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const cameraOffsetRef = useRef<[number, number]>(props.cameraOffset);
  const livemapRef = useRef<Core | null>(null);
  const cameraRef = useRef<CameraOptions | null>(null);
  const followingRef = useRef<Following>(props.following);

  useEffect(() => {
    const bindLivemapEvents = () => {
      const isLineFilterEnabled =
        config.components.map.oneClickFilter.enabled &&
        getSelectedTheme(config).tiles.tileURL;

      mapEvents.onGeoLocationUpdate(props.onGeolocationUpdate);
      mapEvents.onStopDeselected(props.onStopDeselected);
      mapEvents.onStopSelected(props.onStopSelected);
      mapEvents.onTimeChanged(props.onTimeChanged);
      mapEvents.onStopsFound(isLineFilterEnabled ? props.onStopsFound : noop);

      mapEvents.onTripSelectedData((event) => props.onTripLoaded(event.trip));
      mapEvents.onVehicleDeselected(props.onTripCleared);

      mapEvents.onVehicleSelected(() => {
        const livemap = livemapRef.current;
        const map = mapRef.current;

        if (map && livemap && livemap.selectedVehicle) {
          props.onVehicleSelected(livemap.selectedVehicle);

          panToSelectedVehicle(map, livemap, {
            offset: cameraOffsetRef.current,
            zoom: Math.max(15, map.getZoom()),
            duration: 400
          });
        }
      });

      mapEvents.onSelectedVehicleUpdated(
        cheapThrottle(() => {
          const livemap = livemapRef.current;
          const map = mapRef.current;

          if (!livemap || !map || map.isZooming()) {
            return;
          }

          const vehicle = livemap.selectedVehicle;

          if (vehicle && followingRef.current === Following.SELECTED_VEHICLE) {
            if (map.isEasing()) {
              return;
            }

            panToSelectedVehicle(map, livemap, {
              offset: cameraOffsetRef.current,
              duration: 1
            });
          }
        }, 40)
      );
    };

    const bindMapboxEvents = () => {
      const cameraStopFollow = () => {
        if (followingRef.current !== Following.NOTHING) {
          props.stopFollowing();
        }
      };

      const cameraMove = () => {
        const map = mapRef.current;
        const camera = cameraRef.current;

        if (map && camera) {
          camera.center = map.getCenter();
          camera.zoom = map.getZoom();
          camera.bearing = map.getBearing();
          camera.pitch = map.getPitch();

          notifyCameraUpdate(camera);
        }
      };

      const cameraMoveEnd = () =>
        cameraRef.current && props.onCameraMoved(cameraRef.current);

      mapEvents.on('move', cameraMove);
      mapEvents.on('dragstart', cameraStopFollow);

      mapEvents.on('dragend', cameraMoveEnd);
      mapEvents.on('zoomend', cameraMoveEnd);
      mapEvents.on('rotateend', cameraMoveEnd);
    };

    getInitialCamera(config.components.map, props.path).then((camera) => {
      const maps = createMaps(idRef.current, config, camera, layerStyle);

      configureLivemap(config, maps.livemap);
      bindLivemapEvents();
      bindMapboxEvents();

      mapRef.current = maps.map;
      livemapRef.current = maps.livemap;
      cameraRef.current = {};
    });
  }, []);

  useEffect(() => {
    if (livemapRef.current) {
      configureLivemap(config, livemapRef.current);
    }

    if (mapRef.current) {
      configureMapStyle(config, layerStyle, mapRef.current);
    }
  }, [config, layerStyle]);

  useEffect(() => {
    followingRef.current = props.following;

    if (!isSameOffset(cameraOffsetRef.current, props.cameraOffset)) {
      cameraOffsetRef.current = props.cameraOffset;

      if (props.following === Following.SELECTED_VEHICLE) {
        panToSelectedVehicle(mapRef.current, livemapRef.current, {
          duration: 1
        });
      }
    }
  });

  return <MapContainer id={idRef.current} />;
}

const MapContainer = styled.div`
  background-color: ${(p) => p.theme.altBackgroundColor};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

type FollowOptions = Omit<
  CameraOptions & AnimationOptions,
  'center' | 'around'
>;

const panToSelectedVehicle = (
  map: mapboxgl.Map | null,
  livemap: Core | null,
  options: FollowOptions
) => {
  if (!map || !livemap || !livemap.selectedVehicle) {
    return;
  }

  if (livemap.selectedVehicle.x < 1 || livemap.selectedVehicle.y < 1) {
    return;
  }

  const { latitude, longitude } = unproject(livemap.selectedVehicle);
  const coords: LngLatLike = [longitude, latitude];

  map.easeTo({
    ...options,
    center: coords
  });
};

const isSameOffset = (a: [number, number], b: [number, number]) =>
  a.length === b.length && a[0] === b[0] && a[1] === b[1];
