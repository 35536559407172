import React from 'react';

import { RouteLayerStyle } from '@core/map/layers';
import { IconButton } from '@shared/form';
import { disableLayersIcon, layersIcon } from '@shared/icons';

export interface LayerSelectorDispatch {
  onClick(): void;
}

export interface LayerSelectorProps {
  layerStyle: RouteLayerStyle;
}

type Props = LayerSelectorDispatch & LayerSelectorProps;

export function LayerSelector({ layerStyle, onClick }: Props) {
  return (
    <IconButton onClick={onClick}>
      {layerStyle === RouteLayerStyle.LINES ? disableLayersIcon : layersIcon}
    </IconButton>
  );
}
