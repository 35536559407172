import {
  ToxelFields,
  Vehicle,
  VehicleFilter,
  VehicleType,
  VehicleTypeStats
} from 'livemap-gl';
import { callLivemap, selectFromLivemap } from './bindings';

export interface TripIdParams {
  id: number;
  start: number;
}

export const getSelectedTrip = () => selectFromLivemap(lm => lm.selectedTrip);

export const getVehicleStatsForType = (
  vehicleType: VehicleType
): VehicleTypeStats | null =>
  selectFromLivemap(
    ({ modules }) => modules.viewportStats.getVehicleStatsForType(vehicleType),
    null
  );

export const getVehicleStats = (): VehicleTypeStats[] =>
  selectFromLivemap(
    ({ modules }) => modules.viewportStats.getVehicleStats(),
    []
  );

export const setVehicleFilter = (
  filter: VehicleFilter,
  toxelFields: ToxelFields
) => callLivemap(lm => lm.setVehicleFilter(filter, toxelFields));

/**
 * Gets vehicle data based of a trip- or vehicle-ID.
 * Returns `null` if the map is not yet available.
 * @param id The trip-ID/vehicle-ID
 */
export const getVehicle = (id: number): Vehicle | null =>
  selectFromLivemap(lm => lm.toxelVehicles.find(id), null);

export const clearTrip = () => {
  callLivemap(lm => lm.deselectTrip());
};

export const clearStop = () => {
  callLivemap(lm => lm.deselectStop());
};

/**
 * Selects a trip
 * @param tripId Id of trip to select
 * @param start Scheduled trip start time
 */
export const selectTrip = (tripId: number, start?: number, maxAge?: number) =>
  callLivemap(lm => lm.selectTrip(tripId, start, maxAge));

/** Sets simulation speed to 1 and resets time to current time */
export const setRealtime = () => callLivemap(lm => lm.setRealtime());

/**
 * Sets the time of the traffic simulation
 * @param timestamp A UNIX timestamp, milliseconds since 1970
 */
export const setSimulationTime = (timestamp: number) =>
  callLivemap(lm => lm.setTime(timestamp));

/** Gets the current date and time of the traffic simulation. */
export const getSimulationTime = () => selectFromLivemap(lm => lm.getTime(), 0);

/**
 * Sets the realtime factor
 * @param timescale Speed factor
 */
export const setSimulationTimescale = (timescale: number) =>
  callLivemap(lm => lm.setSpeed(timescale));
