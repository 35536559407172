import React from 'react';

import {
  BOTTOM_SHEET_EXPANDED_HEIGHT,
  BOTTOM_SHEET_SWIPE_DEADZONE
} from '@core/bottom-sheet';

import styled, {
  ALL_CORNERS,
  BottomSheet,
  BottomSheetSize,
  DECELERATE_EASING,
  FloatingPane
} from 'livemap-ui';

export interface InfoSheetOwnProps {
  size: BottomSheetSize;
  children: React.ReactNode;
}

export interface InfoSheetDispatch {
  /** Called when the bottom sheet changes height (mobile only) */
  onSizeChanged(height: number, size: BottomSheetSize): void;
}

export interface InfoSheetProps {
  /** Whether or not to display a swipeable bottom sheet (mobile), or a static one (desktop). */
  hasDesktopLayout: boolean;
}

type Props = InfoSheetOwnProps & InfoSheetDispatch & InfoSheetProps;

const DesktopSheet = styled(FloatingPane)`
  @keyframes fly-in {
    from {
      transform: translateX(-120%);
    }
    to {
      transform: translateX(0);
    }
  }

  position: absolute;
  display: flex;
  flex-direction: column;
  width: 340px;
  height: 400px;
  bottom: 0;
  left: 0;
  margin: 12px 12px 32px 12px;
  animation: 360ms fly-in ${DECELERATE_EASING};
`;

export default function({ hasDesktopLayout, children, onSizeChanged }: Props) {
  if (hasDesktopLayout) {
    return <DesktopSheet corners={ALL_CORNERS}>{children}</DesktopSheet>;
  }

  return (
    <BottomSheet
      deadzone={BOTTOM_SHEET_SWIPE_DEADZONE}
      expandedHeight={BOTTOM_SHEET_EXPANDED_HEIGHT}
      onSizeChanged={onSizeChanged}
    >
      {children}
    </BottomSheet>
  );
}
