export function debounce(func: () => void, timeout: number = 50) {
  let handle: number;

  return () => {
    clearTimeout(handle);

    handle = window.setTimeout(() => func(), timeout);
  };
}

/**
 * A cheap throttling function, which may not ensure the latest call is ever called,
 * but that _a call_ during the provided `interval` is.
 *
 * @param func A function which may only be executed once every `interval` milliseconds.
 * @param interval How often to execute `func`
 */
export function cheapThrottle<F extends (...args: unknown[]) => void>(
  func: F,
  interval: number
): F {
  let prev = 0;

  const throttled = (...args: unknown[]) => {
    const now = Date.now();
    const duration = now - prev;

    if (duration >= interval) {
      prev = now;
      func.call(undefined, args);
    }
  };

  return throttled as F;
}
