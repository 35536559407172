import { LngLat } from 'mapbox-gl';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import * as here from '@core/here';
import { fitBounds, flyTo, getCenter, getZoom } from '@core/map/camera';
import { Following } from '@core/map/position';

import { AppState } from '../app-state';

import { follow } from '../actions/map-actions';
import {
  autosuggestFailed,
  autosuggestSuccess,
  SearchActionType,
  SelectAutosuggestItemAction,
  SendAutosuggestAction
} from '../actions/search-actions';
import { LanguageConfig } from '@core/app-config';

const DESIRED_RESULTS: here.ResultType[] = [
  'addressBlock',
  'administrativeArea',
  'houseNumber',
  'locality',
  'street'
];

function* handleSelectItem({ payload: item }: SelectAutosuggestItemAction) {
  yield put(follow(Following.NOTHING));

  if (item.mapView) {
    yield call(
      fitBounds,
      [
        [item.mapView.west, item.mapView.south],
        [item.mapView.east, item.mapView.north]
      ],
      {
        duration: item.distance > 10000 ? 0 : 3000
      }
    );
  } else {
    yield call(flyTo, {
      center: (item.access && item.access[0]) ?? item.position,
      zoom: 16,
      duration: item.distance > 10000 ? 0 : 3000
    });
  }
}

function* handleFetchAutocomplete({
  payload: query
}: SendAutosuggestAction): Generator {
  const lang = (yield select(
    (state: AppState) => state.config.language.code
  )) as LanguageConfig['code'];

  try {
    const zoom = (yield call(getZoom)) as number;

    const at = (
      zoom > 8
        ? yield call(getCenter)
        : yield select((state: AppState) => state.map.userLocation)
    ) as LngLat;

    const response = (yield call(here.autosuggest, {
      query,
      at: at ?? getCenter(),
      lang,
      limit: 4,
      resultTypes: DESIRED_RESULTS
    })) as here.AutosuggestResult;

    yield put(autosuggestSuccess(response));
  } catch (e) {
    yield put(autosuggestFailed());
  }
}

export function* searchSaga() {
  yield takeLatest(SearchActionType.SEND_AUTOSUGGEST, handleFetchAutocomplete);
  yield takeLatest(SearchActionType.SELECT_AUTOSUGGEST_ITEM, handleSelectItem);
}
