import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { VehicleTypeStats } from 'livemap-gl';
import styled from 'livemap-ui';

import { ColorTheme, getVehicleColorArray } from '@core/app-config';
import { getSimulationTime, getVehicleStats } from '@core/map/traffic';

import { LineGraph } from './LineGraph';

export interface GraphPoint {
  value: number;
  count: number;
}

export interface ViewportDataProps {
  colorTheme: ColorTheme;
  contentColor: string;
  axesColor: string;
  title: ReactNode;
  maxPoints?: number;
  getData(stats: VehicleTypeStats[]): GraphPoint[];
  formatOverlay(value: number, average: number): string;
  formatAxis(value: number): string;
  formatTitle(value: number): string;
}

export function ViewportStats({
  title,
  contentColor,
  axesColor,
  colorTheme,
  getData,
  formatOverlay,
  formatAxis,
  formatTitle,
  maxPoints = 440
}: ViewportDataProps) {
  /**
   * Options for the graph, see documentation of LineGraph.
   *
   * The default color of the line will depend on vehicle type using the vehicleColor
   * array in VehicleRenderer. It will also add an alpha using LiveMap.Util.hexToRGB.
   */

  const lineGraphRef = useRef<LineGraph>();
  const ctxRef = useRef<CanvasRenderingContext2D>();
  const intervalHandle = useRef(-1);
  const animationHandle = useRef(-1);

  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
  const [width, setWidth] = useState(452);
  const [height, setHeight] = useState(212);
  const [isRunning, setIsRunning] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  const updateCanvas = () => {
    if (!ctxRef.current || !lineGraphRef.current) {
      console.warn('Tried to update canvas before component was mounted.');

      return;
    }

    window.requestAnimationFrame(updateCanvas);

    if (isRunning) {
      lineGraphRef.current.draw(ctxRef.current);
    }
  };

  const updateGraphData = () => {
    if (isRunning) {
      const stats = getVehicleStats();

      if (stats) {
        updateGraph(getData(stats), 1);
      }
    }
  };

  const updateGraph = (newPoints: GraphPoint[], step: number) => {
    const lineGraph = lineGraphRef.current;

    if (!lineGraph) {
      return;
    }

    const points = lineGraph.points;

    while (points.length >= maxPoints) {
      points.shift();
    }

    lineGraph.points.push(
      newPoints.map(p => ({
        value: p.value,
        time: getSimulationTime()
      }))
    );

    let count = 0;
    let tot = 0;

    newPoints.forEach(point => {
      count += point.count;
      tot += point.value * point.count;
    });

    if (count === 0 && !isHidden) {
      setIsHidden(true);
    } else if (count !== 0 && isHidden) {
      setIsHidden(false);
    }

    lineGraph.overlayText = formatOverlay(count, tot / count);
    lineGraph.adjustAxis(step);
  };

  useEffect(() => {
    if (!canvas || !lineGraphRef.current) {
      return;
    }

    setWidth(canvas.width);
    setHeight(canvas.height);

    ctxRef.current = canvas.getContext('2d') || undefined;

    if (!ctxRef.current) {
      throw new Error('Could not get 2D context from canvas in line graph');
    }

    lineGraphRef.current.resize(canvas.width, canvas.height);

    animationHandle.current = window.requestAnimationFrame(updateCanvas);
    intervalHandle.current = window.setInterval(updateGraphData, 50);

    setIsRunning(true);

    return () => {
      window.clearInterval(intervalHandle.current);
      window.cancelAnimationFrame(animationHandle.current);

      setIsRunning(false);
    };
  });

  useEffect(() => {
    // Always re-create the line graph on each render.
    lineGraphRef.current = new LineGraph({
      overlayText: formatOverlay(0, 0),
      titleText: formatTitle(0),
      formatValue: formatAxis,
      titleColor: contentColor,
      axesColor,
      vehicleColors: getVehicleColorArray(colorTheme)
    });
  });

  return (
    <Container>
      <h4>{title}</h4>
      <canvas width={width} height={height} ref={setCanvas} />
    </Container>
  );
}

const Container = styled.div`
  > h4 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    margin-left: 40px;
    margin-bottom: 8px;
  }
`;
