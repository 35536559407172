import { GeoCoordinate } from 'livemap-gl';
import { CameraOptions, LngLat } from 'mapbox-gl';

import { lookupIPAddress } from '../ip-lookup';

import { parseCameraParams } from '../../url';
import { TrazeMapConfig } from '../app-config';
import { loadAcceptedGeolocation, loadCamera, saveCamera } from '../storage';
import { geolocate } from './position';

const cameraFunctions = [
  getStartCameraFromConfig,
  getCameraFromURL,
  getGPSPosition,
  getStoredCamera,
  getFallbackCameraFromConfig,
  getIPPosition
];

const HARD_CAMERA_FALLBACKS: CameraOptions = {
  center: { lat: 13, lng: 37 },
  zoom: 1,
  pitch: 0,
  bearing: 0
};

const isValidCoordinates = (coords: GeoCoordinate) =>
  coords && Math.abs(coords.latitude) > 0.5 && Math.abs(coords.longitude) > 0.5;

export async function getInitialCamera(
  mapConfig: TrazeMapConfig,
  path: string | null
): Promise<CameraOptions> {
  for (const func of cameraFunctions) {
    try {
      return await func(mapConfig, path);
    } catch {
      continue;
    }
  }

  return getHardCodedFallback();
}

async function getGPSPosition(): Promise<CameraOptions> {
  if (!loadAcceptedGeolocation()) {
    throw new Error('Geolocation not accepted');
  }

  const { longitude, latitude } = await geolocate();

  return {
    ...HARD_CAMERA_FALLBACKS,
    center: [longitude, latitude],
    zoom: 13
  };
}

function getStartCameraFromConfig(map: TrazeMapConfig): Promise<CameraOptions> {
  return new Promise((resolve, reject) => {
    if (map.start) {
      resolve({ ...HARD_CAMERA_FALLBACKS, ...map.start });
    } else {
      reject(new Error('No fixed starting position found in config'));
    }
  });
}

function getFallbackCameraFromConfig(
  map: TrazeMapConfig
): Promise<CameraOptions> {
  return new Promise((resolve, reject) => {
    if (map.fallbackCamera) {
      resolve({ ...HARD_CAMERA_FALLBACKS, ...map.fallbackCamera });
    } else {
      reject(new Error('No fallback camera found in config'));
    }
  });
}

function getIPPosition(): Promise<CameraOptions> {
  return new Promise((resolve, reject) => {
    lookupIPAddress().then(coords => {
      if (!isValidCoordinates(coords)) {
        reject(new Error('The API returned invalid coordinates.'));
      } else {
        resolve({
          ...HARD_CAMERA_FALLBACKS,
          center: {
            lat: coords.latitude,
            lng: coords.longitude
          },
          zoom: 11
        });
      }
    }, reject);
  });
}

function getStoredCamera(): Promise<CameraOptions> {
  return new Promise((resolve, reject) => {
    const camera = loadCamera();

    if (!camera || !camera.center) {
      reject(new Error('No camera found'));

      return;
    }

    const { lat, lng } = LngLat.convert(camera.center);

    if (isNaN(lat) || isNaN(lng)) {
      // Clear the "broken camera" from localStorage.
      saveCamera(null);
      reject(new Error('Invalid lat/lng in camera'));
    } else {
      resolve(camera);
    }
  });
}

function getCameraFromURL(
  _map: TrazeMapConfig,
  path: string | null
): Promise<CameraOptions> {
  return new Promise((resolve, reject) => {
    const camera = parseCameraParams(path);

    if (camera) {
      resolve(camera);
    } else {
      reject();
    }
  });
}

function getHardCodedFallback(): Promise<CameraOptions> {
  const amsterdam = {
    ...HARD_CAMERA_FALLBACKS,
    center: { lat: 52.378284, lng: 4.90179 },
    zoom: 12
  };

  const brisbane = {
    ...HARD_CAMERA_FALLBACKS,
    center: { lat: -27.473905, lng: 153.030475 },
    zoom: 13
  };

  return new Promise(resolve => {
    const now = new Date();
    const isUTCNightTime = now.getUTCHours() < 7 || now.getUTCHours() > 22;

    resolve(isUTCNightTime ? brisbane : amsterdam);
  });
}
