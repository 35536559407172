import {
  GeolocationUpdateEvent,
  StopSelectedEvent,
  StopsFoundEvent,
  TimeChangedEvent,
  ToxelStopLocation,
  Trip,
  unproject,
  Vehicle
} from 'livemap-gl';
import { CameraOptions } from 'mapbox-gl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { Following } from '@core/map/position';

import {
  follow,
  notifyCameraMoved,
  notifyFoundStops,
  notifyMapIsReady,
  notifyMapTimeChanged,
  notifyStopCleared,
  notifyStopSelected,
  notifyTripLoaded,
  notifyUserMoved,
  notifyVehicleCleared,
  notifyVehicleSelected,
  setRouteFilters,
  togglePerspective
} from '@state/actions';

import { AppState } from '@state/app-state';

import { Livemap, LivemapDispatch, LivemapProps } from './Livemap';

const mapState = ({ config, map, router }: AppState): LivemapProps => ({
  config,
  layerStyle: map.routeLayerStyle,
  cameraOffset: map.cameraOffset,
  following: map.following,
  path: router.location ? router.location.pathname : null
});

let lastTimeUpdate = Date.now();

const mapDispatch = (dispatch: Dispatch): LivemapDispatch => ({
  onMapReady() {
    dispatch(notifyMapIsReady());
  },
  togglePerspectiveView() {
    dispatch(togglePerspective());
  },
  stopFollowing() {
    dispatch(follow(Following.NOTHING));
  },
  onGeolocationUpdate(geolocation: GeolocationUpdateEvent) {
    dispatch(notifyUserMoved(unproject(geolocation.position)));
  },
  onStopSelected({ stop }: StopSelectedEvent) {
    dispatch(notifyStopSelected(stop));
  },
  onStopDeselected() {
    dispatch(notifyStopCleared());
  },
  onTimeChanged(event: TimeChangedEvent) {
    const now = Date.now();

    if (now - lastTimeUpdate >= 1000) {
      lastTimeUpdate = now;
      dispatch(notifyMapTimeChanged(event.timestamp));
    }
  },
  onTripCleared() {
    dispatch(notifyVehicleCleared());
  },
  onVehicleSelected(vehicle: Vehicle) {
    dispatch(notifyVehicleSelected(vehicle));
  },
  onTripLoaded(trip: Trip) {
    dispatch(notifyTripLoaded(trip));
  },
  onStopsFound(event: StopsFoundEvent) {
    dispatch(setRouteFilters(getRouteFiltersFromStopLocations(event.stops)));
    dispatch(notifyFoundStops(event.stops));
  },
  onCameraMoved(camera: CameraOptions) {
    dispatch(notifyCameraMoved(camera));
  }
});

function getRouteFiltersFromStopLocations(stops?: ToxelStopLocation[]) {
  const filters: string[] = [];
  if (!stops) {
    return filters;
  }

  for (const stop of stops) {
    for (const departure of stop.departures) {
      if (filters.includes(departure.routeShortName)) {
        continue;
      }

      filters.push(departure.routeShortName);
    }
  }
  return filters;
}

export default connect(
  mapState,
  mapDispatch
)(Livemap);
