import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { TrazeMapConfig } from '@core/app-config';
import { Following } from '@core/map/position';

import { flyTo, follow } from '@state/actions/map-actions';

import { AppState } from '@state/app-state';

import { Geolocator, GeolocatorDispatch, GeolocatorProps } from './Geolocator';

const mapState = (state: AppState): GeolocatorProps => ({
  isVisible: true,
  action: state.config.components.geolocation.action,
  mapConfig: state.config.components.map,
  enabled: state.map.isGeolocationEnabled,
  following: state.map.following
});

const mapDispatch = (dispatch: Dispatch): GeolocatorDispatch => ({
  geolocate() {
    dispatch(follow(Following.USER));
  },
  setStartPosition({ start }: TrazeMapConfig) {
    if (!start) {
      return;
    }

    dispatch(
      flyTo({
        center: start.center,
        zoom: start.zoom || 15,
        bearing: start.bearing || 0,
        pitch: start.pitch || 0
      })
    );
  }
});

export default connect(
  mapState,
  mapDispatch
)(Geolocator);
