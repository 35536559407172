import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { clearSelectedStop, selectVehicle } from '@state/actions';
import { AppState } from '@state/app-state';

import { StopInfo, StopInfoDispatch, StopInfoProps } from './StopInfo';

const mapState = (state: AppState): StopInfoProps => ({
  time: state.map.time,
  stop: state.map.selectedStop,
  showTimeZoneBanner: state.config.components.stopInfo.showTimeZoneBanner
});

const mapDispatch = (dispatch: Dispatch): StopInfoDispatch => ({
  selectTrip(id: number, start: number) {
    dispatch(clearSelectedStop());
    dispatch(selectVehicle(id, start));
  }
});

/**
 * Container component for TripInfo
 */
export default connect(
  mapState,
  mapDispatch
)(StopInfo);
