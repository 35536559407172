import React from 'react';

import styled from 'livemap-ui';

import { FeedbackCategory } from '@core/feedback';

import { sendIcon, smileIcon, surprisedIcon } from '@shared/icons';
import { Translate } from 'react-localize-redux';

export interface FeedbackStatusProps {
  isSuccess: boolean;
  category: FeedbackCategory;
}

type Props = FeedbackStatusProps;

function getResponseTranslateKey(category: FeedbackCategory) {
  switch (category) {
    case 'like':
      return 'Feedback.responseLike';
    case 'problem':
      return 'Feedback.responseProblem';
    default:
    case 'suggestion':
      return 'Feedback.responseSuggestion';
  }
}

function getResponseIcon(category: FeedbackCategory) {
  switch (category) {
    case 'like':
      return smileIcon;
    default:
      return sendIcon;
  }
}

export function FeedbackStatus({ isSuccess, category }: Props) {
  const headingText = (
    <Translate id={isSuccess ? 'Form.sendSuccess' : 'Form.sendError'} />
  );

  const contentText = (
    <Translate
      id={isSuccess ? getResponseTranslateKey(category) : 'Feedback.sendFailed'}
    />
  );

  return (
    <StatusWrapper>
      <Icon>{isSuccess ? getResponseIcon(category) : surprisedIcon}</Icon>
      <Contents>
        <h1>{headingText}</h1>
        {contentText}
      </Contents>
    </StatusWrapper>
  );
}

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
`;

const Contents = styled.div`
  font-size: 20px;
  text-align: center;
  transform: translateY(-20px);

  > h1 {
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 6px;
    font-family: ${p => p.theme.headingFontFamily};
  }
`;

const Icon = styled.figure`
  @keyframes floating {
    0% {
      transform: translateY(3px);
    }
    50% {
      transform: translateY(-3px);
    }
    100% {
      transform: translateY(3px);
    }
  }

  background-image: radial-gradient(
    circle at center,
    ${p => p.theme.detailColor} -50%,
    transparent 50%
  );
  width: 180px;
  height: 180px;
  margin: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    margin: 0 auto;
    width: 82px;
    height: 82px;
    animation: floating 3s ease-in-out 0ms infinite;
    transform-origin: bottom;
  }
`;
