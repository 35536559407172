import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';

import { VehicleType } from 'livemap-gl';
import styled from 'livemap-ui';

import { ColorTheme, getVehicleTypeKey } from '@core/app-config';

import { getPluralizedVehicleTranslateKey } from '../../../../translations';

import { Checkbox } from '@shared/form';

export interface VehicleOptionsProps {
  visibleVehicleTypes: VehicleType[];
  availableVehicleTypes: VehicleType[];
  colorTheme: ColorTheme;
  collapsedCount: number;
}

export interface VehicleOptionsDispatch {
  toggleDisplay(type: VehicleType): void;
}

type Props = VehicleOptionsDispatch & VehicleOptionsProps;

/** Vehicle check-boxes */
export function VehicleOptions({ toggleDisplay, ...props }: Props) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  const renderExpandButton = () => {
    if (props.availableVehicleTypes.length <= props.collapsedCount) {
      return null;
    }

    const chevron = isExpanded ? (
      <svg viewBox="0 0 24 24">
        <path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z" />
      </svg>
    ) : (
      <svg viewBox="0 0 24 24">
        <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
      </svg>
    );

    return <ExpandButton onClick={toggleExpanded}>{chevron}</ExpandButton>;
  };

  const getVehicleColor = (type: VehicleType) => {
    if (props.colorTheme.livemap.vehicles.useTripBgColor) {
      return props.colorTheme.ui.contentColor;
    }

    const key = getVehicleTypeKey(type);

    return props.colorTheme.vehicleColors[key];
  };

  const isVisible = (type: VehicleType) =>
    props.visibleVehicleTypes.indexOf(type) > -1;

  const getVehicleList = () =>
    !isExpanded
      ? props.availableVehicleTypes.slice(0, props.collapsedCount)
      : props.availableVehicleTypes;

  return (
    <OptionsList>
      {getVehicleList().map((type, i) => (
        <Option key={i} onClick={() => toggleDisplay(type)}>
          <Checkbox
            checked={isVisible(type)}
            backgroundColor={getVehicleColor(type)}
          />
          <OptionText>
            <Translate id={getPluralizedVehicleTranslateKey(type)} />
          </OptionText>
        </Option>
      ))}

      {renderExpandButton()}
    </OptionsList>
  );
}

const OptionsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Option = styled.div`
  padding: 12px 24px;
  display: flex;
  flex-direction: row;
  font-family: ${(p) => p.theme.headingFontFamily};
  font-size: 16px;
`;

const OptionText = styled.div`
  margin-left: 12px;
`;

const ExpandButton = styled.div`
  width: 100%;
  padding: 2px 28px;

  svg {
    width: 16px;
    height: 16px;
    opacity: ${(p) => p.theme.dimOpacity};
  }
`;
