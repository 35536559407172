import styled from 'livemap-ui';
import React from 'react';

import { ComponentsConfig } from '@core/app-config';

import Compass from '../Compass/CompassContainer';
import FullscreenButton from '../FullscreenButton/FullscreenButtonContainer';
import LayerSelector from '../LayerStyleSelector/LayerSelectorContainer';
import ZoomButtons from '../ZoomButtons/ZoomButtonsContainer';

interface MapControlsProps {
  config: ComponentsConfig;
  showZoomButtons: boolean;
  showFullscreenButton: boolean;
}

export function MapControls({
  config,
  showZoomButtons,
  showFullscreenButton
}: MapControlsProps) {
  const showFullscreen = config.mapControls.fullscreen && showFullscreenButton;
  const showLayerSelector = config.layerSelector.enabled;
  const showZoom = config.mapControls.zoom && showZoomButtons;
  const showCompass = config.mapControls.compass;

  return (
    <ControlWrapper>
      {showFullscreen ? <FullscreenButton /> : null}
      {showLayerSelector ? <LayerSelector /> : null}
      {showZoom ? <ZoomButtons /> : null}
      {showCompass ? <Compass /> : null}
    </ControlWrapper>
  );
}

const ControlWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 12px;
  }
`;
