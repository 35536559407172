import React from 'react';

import { ToxelStopLocation, unproject } from 'livemap-gl';
import styled, {
  BottomSheetSize,
  hours,
  ScrollPane,
  StopDeparture,
  StopHeader,
  UTCOffset
} from 'livemap-ui';

import {
  PaddedSheetContent,
  SheetContent,
  SheetHeader
} from '@shared/bottom-sheet';

import { flyTo } from '@core/map/camera';

import InfoSheet from '../../components/InfoSheet/InfoSheetContainer';
import { TimeZoneBanner } from '../TripInfo/components/TimeZoneBanner';

export interface StopInfoProps {
  stop: ToxelStopLocation | null;
  time: number;
  showTimeZoneBanner: boolean;
}

export interface StopInfoDispatch {
  selectTrip(tripIndex: number, start: number): void;
}

type Props = StopInfoProps & StopInfoDispatch;

export function StopInfo({
  stop,
  time,
  selectTrip,
  showTimeZoneBanner
}: Props) {
  if (!stop) {
    return null;
  }

  const stopName = stop.names && stop.names.length > 0 ? stop.names[0] : null;
  const offsetHours = -UTCOffset / hours(1);

  const onClickHeader = () => {
    const { latitude, longitude } = unproject(stop);

    flyTo({ center: [longitude, latitude], zoom: 15 });
  };

  return (
    <InfoSheet size={BottomSheetSize.MINIMIZED}>
      <SheetHeader onClick={onClickHeader}>
        <SheetContent>
          <StopHeader name={stopName} />
        </SheetContent>
        {showTimeZoneBanner ? (
          <TimeZoneBanner utcTime={time} utcOffset={offsetHours} />
        ) : null}
      </SheetHeader>

      <ScrollPane>
        <PaddedSheetContent>
          {stop.departures.map((d, i) => (
            <Link
              key={i}
              onClick={() => selectTrip(d.tripIndex, d.tripScheduledStart)}
            >
              <StopDeparture
                towards={d.tripName}
                departsAt={d.departure - UTCOffset}
              />
            </Link>
          ))}
        </PaddedSheetContent>
      </ScrollPane>
    </InfoSheet>
  );
}

const Link = styled.a`
  cursor: pointer;
  text-decoration: underline;
`;
