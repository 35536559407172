// tslint:disable: no-any

/**
 * Given that errors in JavaScript can be of any type,
 * this function attempts to extract a description from common error types,
 * i.e `string` or `Error`.
 *
 * If the error is not of the `Error` or `string` type, `toString()`,
 * will be called on the object. If `toString()` is not available,
 * a placeholder string will be returned.
 *
 * @param error The error to attempt to attempt to extract a description from.
 */
export function getErrorDescription(error: any): string {
  if ('message' in error) {
    const name = 'name' in error ? `${error.name} - ` : 'Unnamed error - ';

    return name + error.message;
  } else if ('toString' in error) {
    return error.toString();
  } else {
    return 'Error description not available';
  }
}

// tslint:enable: no-any

export function assertOK(res: Response): void {
  if (!res.ok) {
    const { status, statusText } = res;

    const statusMessage = statusText
      ? `'${status}' (${statusText})`
      : `'${status}'`;

    throw new Error(
      `A non-success status-code of ${statusMessage} was returned`
    );
  }
}
