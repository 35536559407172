import styled from 'livemap-ui';
import React from 'react';

import { Translate } from 'react-localize-redux';

export interface FeedInfoProps {
  agencyName?: string;
  agencyURL?: string;
  publisherURL?: string;
  publisherName?: string;
}

type Props = FeedInfoProps;

function renderAgencyInfo(name?: string, url?: string) {
  if (url && name) {
    return (
      <>
        <Translate id="TripInfo.dataProvidedBy" />{' '}
        <a href={url} target="_blank" rel="noopener">
          {name}
        </a>
      </>
    );
  } else if (name) {
    return (
      <>
        <Translate id="TripInfo.dataProvidedBy" /> {name}
      </>
    );
  }

  return null;
}

function renderPublisherInfo(name?: string, url?: string) {
  if (url && name) {
    return (
      <>
        <br />
        <Translate id="TripInfo.dataProvidedVia" />{' '}
        <a href={url} target="_blank" rel="noopener">
          {name}
        </a>
      </>
    );
  } else if (name) {
    return (
      <>
        <br />
        <Translate id="TripInfo.dataProvidedVia" /> {name}
      </>
    );
  }

  return null;
}

export function FeedInfo({
  agencyName,
  agencyURL,
  publisherName,
  publisherURL
}: Props) {
  return (
    <Container>
      <Icon viewBox="0 0 24 24">
        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1 18h-2v-8h2v8zm-1-12.25c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25-1.25-.56-1.25-1.25.56-1.25 1.25-1.25z" />
      </Icon>
      <div>
        {renderAgencyInfo(agencyName, agencyURL)}
        {renderPublisherInfo(publisherName, publisherURL)}
      </div>
    </Container>
  );
}

const Container = styled.div`
  text-align: center;
  margin: 36px 0;
  font-size: 14px;
  opacity: ${p => p.theme.disabledOpacity};
  font-family: ${p => p.theme.contentFontFamily};
`;

const Icon = styled.svg`
  width: 24px;
  height: 24px;
  opacity: ${p => p.theme.disabledOpacity};
  margin-bottom: 12px;
`;
