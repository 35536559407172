import React from 'react';

import styled from 'livemap-ui';

import { AnyAppAction } from '@state/actions';
import { SnackbarNotice } from '@state/ui-state';

import { SnackCard } from './SnackCard';

export interface SnackbarProps {
  messages: SnackbarNotice[];
}

export interface SnackbarDispatch {
  dispatch(action: AnyAppAction): void;
}

type Props = SnackbarDispatch & SnackbarProps;

/** Composes a set of snackbar messages into cards. */
export function Snackbar({ messages, dispatch }: Props) {
  return (
    <SnackbarWrapper>
      {messages.map((m, i) => (
        <SnackCard {...m} dispatch={dispatch} key={i} />
      ))}
    </SnackbarWrapper>
  );
}

const SnackbarWrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  > * {
    margin: 6px 12px;
  }
`;
