export type FeedbackCategory = 'problem' | 'suggestion' | 'like';

export interface FeedbackFormData {
  tag: FeedbackCategory;
  message?: string;
  email?: string;
  rating?: number;
  experienceRatings?: {
    [experience: string]: number;
  };
}

export interface FeedbackData extends FeedbackFormData {
  clientName: string;
  version: string;
  url: string;
  userAgent: string;
  locationName?: string;
  longitude?: number;
  latitude?: number;
  dependencies?: string[];
}

export const sendFeedback = (feedback: FeedbackData) =>
  fetch('/feedback', { method: 'POST', body: JSON.stringify(feedback) });
