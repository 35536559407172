import { GeoCoordinate } from 'livemap-gl';

const IP_GEO_API = '/ip/geo';
const FETCH_TIMEOUT = 2500;

export function lookupIPAddress(): Promise<GeoCoordinate> {
  let didTimeOut = false;

  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      didTimeOut = true;
      reject(new Error('Request timed out'));
    }, FETCH_TIMEOUT);

    fetch(IP_GEO_API)
      .then(response => {
        clearTimeout(timeout);

        if (!didTimeOut) {
          const json = response.json() as Promise<GeoCoordinate>;

          resolve(json);
        }
      })
      .catch(error => {
        if (didTimeOut) {
          return;
        }

        reject(error);
      });
  });
}
