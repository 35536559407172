import { BottomSheetSize } from 'livemap-ui';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { setBottomSheetSize } from '@state/actions/ui-actions';
import { AppState } from '@state/app-state';

import BottomSheet, {
  InfoSheetDispatch,
  InfoSheetOwnProps,
  InfoSheetProps
} from './InfoSheet';

const mapState = (
  state: AppState,
  ownProps: InfoSheetOwnProps
): InfoSheetProps & InfoSheetOwnProps => ({
  size: ownProps.size || state.ui.bottomSheet.size || BottomSheetSize.MINIMIZED,
  hasDesktopLayout: state.ui.hasDesktopLayout,
  children: ownProps.children
});

const mapDispatch = (dispatch: Dispatch): InfoSheetDispatch => ({
  onSizeChanged(height: number, size: BottomSheetSize) {
    dispatch(setBottomSheetSize(height, size));
  }
});

export default connect(
  mapState,
  mapDispatch
)(BottomSheet);
