import React, { useEffect } from 'react';

import styled, { LivemapUI } from 'livemap-ui';

import { renderToStaticMarkup } from 'react-dom/server';
import { LocalizeContextProps } from 'react-localize-redux';
import { RouteComponentProps } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import { AppConfig } from '@core/app-config';

import Attribution from './components/Attribution/AttributionContainer';
import Feedback from './scenes/Feedback/FeedbackContainer';
import Geolocator from './scenes/Geolocator/GeolocatorContainer';
import LivemapContainer from './scenes/Livemap/LivemapContainer';
import MainMenu from './scenes/MainMenu/MainMenuContainer';
import NoticeContainer from './scenes/Notice/NoticeContainer';
import Snackbar from './scenes/Snackbar/SnackbarContainer';
import StopInfoContainer from './scenes/StopInfo/StopInfoContainer';
import TopControls from './scenes/TopControls/TopControlsContainer';
import TripInfoContainer from './scenes/TripInfo/TripInfoContainer';
import VehicleStatsContainer from './scenes/VehicleStats/VehicleStatsContainer';

import englishTranslations from './translations/en.json';
import swedishTranslations from './translations/sv.json';

export interface AppProps {
  config: AppConfig;
}

type Props = AppProps & RouteComponentProps & LocalizeContextProps;

export function App({ config, ...props }: Props): JSX.Element {
  const { language, components } = config;

  useEffect(() => {
    props.initialize({
      languages: [
        { name: 'English', code: 'en' },
        { name: 'Swedish', code: 'sv' }
      ],
      options: {
        renderToStaticMarkup,
        defaultLanguage: language.code
      }
    });

    props.addTranslationForLanguage(englishTranslations, 'en');
    props.addTranslationForLanguage(swedishTranslations, 'sv');
  }, []);

  const { day, night } = config.themes;

  return (
    <LivemapUI dayTheme={day.ui} nightTheme={night.ui} mode={config.themeMode}>
      <NoticeContainer />
      <LivemapContainer />
      {components.menu.enabled ? <MainMenu /> : null}
      <TopControls />
      {components.vehicleStats.enabled ? <VehicleStatsContainer /> : null}
      <BottomControls>
        <Snackbar />
        {components.geolocation.enabled ? <Geolocator /> : null}

        <Switch>
          <Route
            path="/trip/:id/:start"
            exact={false}
            component={TripInfoContainer}
          />
          <Route path="/stop" exact={false} component={StopInfoContainer} />
        </Switch>
      </BottomControls>
      {components.attribution.enabled ? <Attribution /> : null}
      <Switch>
        <Route path="/feedback" exact={false} component={Feedback} />
      </Switch>
    </LivemapUI>
  );
}

const BottomControls = styled.section`
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  /** Click-through wrapper */
  pointer-events: none;

  > * {
    /** Enable click for children */
    pointer-events: auto;
  }
`;
