import { call, put, takeLatest } from 'redux-saga/effects';

import { push } from 'connected-react-router';

import {
  FeedbackActionType,
  SendFeedbackAction,
  submitFeedbackError,
  submitFeedbackSuccess
} from '../actions';

import { FeedbackData, sendFeedback } from '@core/feedback';
import { getCenter } from '@core/map/camera';

import appPkg from '../../../package.json';

import livemapPkg from 'livemap-gl/package.json';
import mapboxPkg from 'mapbox-gl/package.json';
import { LngLat } from 'mapbox-gl';

function* handleSubmitFeedback(action: SendFeedbackAction): Generator {
  try {
    // Get telemetry data
    const mapCenter = (yield call(getCenter)) as LngLat;

    const feedback: FeedbackData = {
      ...action.payload,
      version: appPkg.version,
      url: window.location.href,
      userAgent: navigator.userAgent,
      clientName: appPkg.name,
      dependencies: [
        livemapPkg.name + ' ' + livemapPkg.version,
        mapboxPkg.name + ' ' + mapboxPkg.version
      ]
    };

    if (mapCenter) {
      feedback.longitude = mapCenter.lng;
      feedback.latitude = mapCenter.lat;
    }

    yield call(sendFeedback, feedback);

    yield put(submitFeedbackSuccess());
  } catch (e) {
    yield put(submitFeedbackError((e || 'Error sending feedback').toString()));
  }
}

function* handleOpenFeedback() {
  yield put(push('/feedback'));
}

function* handleCloseFeedback() {
  yield put(push('/'));
}

export function* feedbackSaga() {
  yield takeLatest(FeedbackActionType.OPEN, handleOpenFeedback);
  yield takeLatest(FeedbackActionType.CLOSE, handleCloseFeedback);
  yield takeLatest(FeedbackActionType.SUBMIT, handleSubmitFeedback);
}
