import { connect } from 'react-redux';
import { Attribution, AttributionProps } from './Attribution';

import { getSelectedTheme } from '@core/app-config';

import { AppState } from '@state/app-state';

const mapStateToProps = ({ config, ui }: AppState): AttributionProps => ({
  text: getSelectedTheme(config).tiles.attribution || 'Veridict (C)',
  hasDesktopLayout: ui.hasDesktopLayout
});

export default connect(mapStateToProps)(Attribution);
