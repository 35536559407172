import { connect } from 'react-redux';

import { AppState } from '@state/app-state';

import { TopControls, TopControlsProps } from './TopControls';

const mapState = ({ config, ui }: AppState): TopControlsProps => ({
  config: config.components,
  hasDesktopLayout: ui.hasDesktopLayout,
  isAppFullscreen: ui.isStandalone,
  isVehicleStatsVisible:
    config.components.vehicleStats.enabled && ui.isVehicleStatsVisible
});

export default connect(mapState)(TopControls);
