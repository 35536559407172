import { connect } from 'react-redux';

import { openMenu } from '@state/actions';

import { DispatchProps, MenuButton } from './MenuButton';

const mapDispatch: DispatchProps = {
  onClick: () => openMenu('main')
};

export default connect(
  null,
  mapDispatch
)(MenuButton);
