import React, { useState } from 'react';

import styled from 'livemap-ui';

import { FeedbackCategory, FeedbackFormData } from '@core/feedback';

import {
  Button,
  FormButtons,
  LinkButton,
  TextArea,
  TextInput
} from '@shared/form';
import { Translate } from 'react-localize-redux';

export interface FeedbackFormDispatch {
  onSubmit(feedback: FeedbackFormData): void;
  cancel(): void;
}

export interface FeedbackFormProps {
  category: FeedbackCategory;
}

type Props = FeedbackFormDispatch & FeedbackFormProps;

export function FeedbackForm({ category, onSubmit, cancel }: Props) {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const submitForm = (event: React.MouseEvent) => {
    event.preventDefault();

    onSubmit({
      tag: category,
      message,
      email
    });

    return false;
  };

  return (
    <Form>
      <TextArea
        label={<Translate id="Form.message" />}
        value={message}
        onTextChange={setMessage}
      />
      <TextInput
        type="email"
        label={<Translate id="Form.contactEmail" />}
        placeholder="(Optional)"
        value={email}
        onTextChange={setEmail}
      />
      <FormButtons>
        <Button onClick={submitForm}>
          <Translate id="Form.send" />
        </Button>
        <LinkButton onClick={cancel}>
          <Translate id="Form.cancel" />
        </LinkButton>
      </FormButtons>
    </Form>
  );
}

const Form = styled.form`
  margin-top: 6px;
  padding-top: 12px;
  border-top: 1px solid ${p => p.theme.borderColor};
`;
