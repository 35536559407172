import { Trip, unproject } from 'livemap-gl';
import {
  CameraOptions,
  FitBoundsOptions,
  FlyToOptions,
  LngLat,
  LngLatBounds,
  LngLatBoundsLike,
  PaddingOptions,
  PointLike
} from 'mapbox-gl';
import { callMapbox, selectFromMapbox } from './bindings';

export const isMoving = () => selectFromMapbox(mb => mb.isMoving(), false);

/**
 * Moves the camera in a flying manner to a position
 * @param options Center, zoom, bearing, and pitch
 */
export const flyTo = (options: FlyToOptions) =>
  callMapbox(mb => mb.flyTo(options));

/**
 * Moves the camera in a flying manner to fit boundaries
 * @param bounds Boundaries to fit
 */
export const fitBounds = (
  bounds: LngLatBoundsLike,
  options?: FitBoundsOptions
) => callMapbox(mb => mb.fitBounds(bounds, options));

export const fitTrip = (
  trip: Trip,
  options?: {
    linear?: boolean;
    easing?: (value: number) => number;
    padding?: number | PaddingOptions;
    offset?: PointLike;
    maxZoom?: number;
  }
) => {
  const bounds = new LngLatBounds();

  trip.polyline.forEach(point => {
    const { longitude, latitude } = unproject(point);

    bounds.extend(new LngLat(longitude, latitude));
  });

  callMapbox(mb => mb.fitBounds(bounds, options));
};

/** Returns the bearing of map (rotation) */
export const getBearing = () => selectFromMapbox(mb => mb.getBearing(), 0);

/** Gets coordinates of camera center */
export const getCenter = () => selectFromMapbox(mb => mb.getCenter());

export const setCenter = (lng: number, lat: number) =>
  callMapbox(mb => mb.setCenter([lng, lat]));

/** Gets the map pitch */
export const getPitch = () => selectFromMapbox(mb => mb.getPitch(), 0);

/** Gets camera zoom value */
export const getZoom = () => selectFromMapbox(mb => mb.getZoom(), 0);

export const zoomIn = () => callMapbox(mb => mb.zoomIn());

export const zoomOut = () => callMapbox(mb => mb.zoomOut());

/**
 * Moves the camera without animating
 * @param options Center, zoom, bearing, and pitch
 */
export const jumpTo = (options: CameraOptions) =>
  callMapbox(mb => mb.jumpTo(options));

type CameraCallback = (camera: mapboxgl.CameraOptions) => void;

const cameraSubscribers: CameraCallback[] = [];

export const onCameraUpdated = (callback: CameraCallback) => {
  cameraSubscribers.push(callback);

  return () => {
    const index = cameraSubscribers.indexOf(callback);

    if (index > -1) {
      cameraSubscribers.splice(index, 1);
    }
  };
};

export const notifyCameraUpdate = (options: CameraOptions) => {
  for (const subscriber of cameraSubscribers) {
    subscriber(options);
  }
};

/**
 * Toggles perspective (3D) view.
 * @param pitch The pitch to set the camera to
 */
export const togglePerspectiveView = (pitch: number = 60) => {
  if (getPitch()) {
    callMapbox(mb => {
      mb.setPitch(0);
      mb.setBearing(0);
    });
  } else {
    callMapbox(mb => mb.setPitch(pitch));
  }
};
