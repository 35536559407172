import React from 'react';

import styled, { formatTime, fromUnix, millis, UTCOffset } from 'livemap-ui';

import { Translate } from 'react-localize-redux';

export interface TimeZoneBannerProps {
  /** The current UTC time (milliseconds) */
  utcTime: number;
  /** Offset from UTC in hours */
  utcOffset: number;
}

const formatOffset = (offset: number) => {
  if (offset === 0) {
    return offset;
  }

  return offset > 0 ? '+' + offset : offset.toString();
};

export function TimeZoneBanner({ utcTime, utcOffset }: TimeZoneBannerProps) {
  const offsetMillis = millis(utcOffset, 0, 0);
  const localTime = utcTime + offsetMillis;
  const isBrowserOffset = offsetMillis === -UTCOffset;

  const clock = isBrowserOffset ? (
    <Translate id="TripInfo.yourTimeZone" />
  ) : (
    formatTime(fromUnix(localTime))
  );

  return (
    <Banner>
      <Translate id="TripInfo.timesAreShownIn" /> UTC{formatOffset(utcOffset)} (
      {clock})
    </Banner>
  );
}

const Banner = styled.div`
  background-color: #6d18dc;
  color: #fff;
  padding: 3px 12px;
  font-size: 14px;
  font-family: ${p => p.theme.headingFontFamily};
`;
