import styled, { SquareButton, STANDARD_EASING } from 'livemap-ui';

export const IconButton = styled(SquareButton)`
  > svg {
    width: 24px;
    height: 24px;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  font-family: ${p => p.theme.headingFontFamily};
  color: ${p => p.theme.detailColor};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 12px;
  padding: 6px 12px;
  background: transparent;
  border: 1px solid ${p => p.theme.detailColor};
  border-radius: 3px;
  outline: none;
  transition: all 140ms ${STANDARD_EASING};

  &:active {
    background-color: ${p => p.theme.detailColor};
    color: ${p => p.theme.backgroundColor};
  }
`;

export const LinkButton = styled.button`
  cursor: pointer;
  font-family: ${p => p.theme.headingFontFamily};
  color: ${p => p.theme.contentColor};
  text-transform: uppercase;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 12px;
  padding: 6px 12px;
  background: transparent;
  border: none;
  outline: none;
  transition: all 140ms ${STANDARD_EASING};

  &:active {
    color: ${p => p.theme.detailColor};
  }
`;

export const FormButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;
