import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';

import styled, { ALL_CORNERS, FloatingPane } from 'livemap-ui';

import { FeedbackCategory, FeedbackFormData } from '@core/feedback';

import { Spinner } from '@shared/loading';

import { CategorySelector } from './CategorySelector';
import { FeedbackForm } from './FeedbackForm';
import { FeedbackStatus } from './FeedbackStatus';

export interface FeedbackProps {
  isSubmitting: boolean;
  isSubmitted: boolean;
  error: string | null;
}

export interface FeedbackDispatch {
  submitFeedback(data: FeedbackFormData): void;
  closeFeedback(): void;
}

type Props = FeedbackProps & FeedbackDispatch;

export function Feedback({
  submitFeedback,
  closeFeedback,
  isSubmitted,
  isSubmitting,
  error
}: Props) {
  const [category, setCategory] = useState<FeedbackCategory | null>(null);

  useEffect(
    () => {
      if (category === 'like') {
        submitFeedback({ tag: 'like' });
      }
    },
    [category]
  );

  const renderContent = () => {
    if (isSubmitted && category) {
      return <FeedbackStatus category={category} isSuccess={!error} />;
    }

    if (category && category !== 'like') {
      return (
        <FeedbackForm
          category={category}
          onSubmit={submitFeedback}
          cancel={() => setCategory(null)}
        />
      );
    }

    return <CategorySelector onSelect={setCategory} />;
  };

  return (
    <FeedbackWrapper>
      <FeedbackOverlay onClick={closeFeedback} />

      <FeedbackPane corners={ALL_CORNERS}>
        {isSubmitting ? (
          <SpinnerOverlay>
            <Spinner />
            <Translate id="Form.sending" /> &hellip;
          </SpinnerOverlay>
        ) : null}

        <FeedbackHeading>
          <Translate id="Feedback.feedback" />
        </FeedbackHeading>

        {renderContent()}
      </FeedbackPane>
    </FeedbackWrapper>
  );
}

const SpinnerOverlay = styled.div`
  @keyframes fade-in-spinner-overlay {
    from {
      background-color: rgba(0, 0, 0, 0);
    }
    to {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  z-index: 10;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: ${p => p.theme.borderRadius}px;
  color: #fff;
  font-family: ${p => p.theme.headingFontFamily};
  font-weight: bold;
  font-size: 20px;
  animation: fade-in-spinner-overlay 200ms ease-out 0ms;
  animation-fill-mode: forwards;

  > * {
    min-width: 50px;
    min-height: 50px;
  }
`;

const FeedbackWrapper = styled.section`
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
`;

const FeedbackOverlay = styled.div`
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  width: 100%;
  height: 100%;
`;

const FeedbackPane = styled(FloatingPane)`
  width: 100%;
  max-width: 400px;
  margin-bottom: 10vh;
  padding: 12px 18px;
  overflow: visible;

  &::after {
    right: 12px;
    top: -52px;
    position: absolute;
    font-size: 28px;
    content: '×';
    pointer-events: none;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 50%;
    width: 29px;
    height: 29px;
    text-align: center;
    line-height: 0.9;
    padding-top: 1px;
    padding-left: 1px;
  }
`;

const FeedbackHeading = styled.h1`
  font-size: 16px;
  font-family: ${p => p.theme.headingFontFamily};
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 12px;
  opacity: ${p => p.theme.disabledOpacity};
`;
