import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { removeMapLayer, setRouteFilters } from '@state/actions';
import { AppState } from '@state/app-state';

import {
  RouteFilters,
  RouteFiltersDispatch,
  RouteFiltersProps
} from './RouteFilters';

const mapState = (state: AppState): RouteFiltersProps => ({
  components: state.config.components,
  routeFilterTerms: state.map.routeFilters
});

const mapDispatch = (dispatch: Dispatch): RouteFiltersDispatch => ({
  removeRouteFilter: (layerId: string) => dispatch(removeMapLayer(layerId)),
  vehicleRouteFilter: (text: string[]) => dispatch(setRouteFilters(text))
});

export default connect(
  mapState,
  mapDispatch
)(RouteFilters);
